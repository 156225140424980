<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 -->
      <div class="control_box">
        <Search-bar :timeRange="timeRange" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0 || loading">导出</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <!-- <a-table :loading="loading" :columns="columns" :data-source="tableData" bordered :pagination="pagination"
          @change="handleTableChange" ></a-table> -->
         <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            :scroll="{ x: 2000, y: 500 }"
            :pagination="pagination"
            @change="handleTableChange"
          />

      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getUserMealReport,exportUserMealReport } from "@/request/api";

//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data () {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "经营报表",
        },
        {
          key: 1,
          title: "人员就餐统计",
        },
      ],
      timeRange: {
        show: true,
        placeholderStart: "开始时间(默认上月)",
        placeholderEnd: "结束时间(默认上月)",
        rangeLimit: true
      },
      queryParam: {
        page: 1,
        begin_date: "",
        end_date: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      tableDataLength: 0,
      loading: false,
      columns: [], // 表格的列配置
      timeData: [],
      pagination: {
        total: 0,
        pageSize: 10, //接口返回10条数据  加上接口返回之后前端处理添加的一条合计数据总11条
        current: 1,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //列表下载
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },
    //数据写入excel
    export2Excel () {
      exportUserMealReport(this.queryParam).then((res) => {
        util.acceptFileStream(res.data, "人员就餐统计.xls");
      });
    },
    // 刷新
    search (val) {
      this.queryParam = {
        page: 1,
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      this.pagination.current = 1;
      this.getTableData();
    },
    //获取表格数据
    getTableData () {
      this.loading = true;
      //获取带分页的数据用于渲染
      getUserMealReport(this.queryParam).then((res) => {

        let data  = res.data.data;
        let headr = res.data.columns;
        let foot  = res.data.pagination;

        //配置表格列头
        this.columns = [
          {
            title: '姓名',
            dataIndex: 'realname',
            key: 'realname',
            width: 100,
            
          },
          {
            title: '部门',
            dataIndex: 'office_title',
            key: 'office',
            width: 100,
            
          },
          {
            title: '职务',
            dataIndex: 'post_title',
            key: 'post',
            width: 100,
            
          },
        ];

        for( let key in headr){
            
            let row = [];
            if( typeof(headr[key].menu) != 'undefined' ){
                for( let obj in headr[key].menu ){
                
                  let dataIndex = 'meal'+headr[key].id+'menu'+headr[key].menu[obj].id;
                  console.log({
                    title: headr[key].menu[obj].title,
                    dataIndex: dataIndex,
                    key: dataIndex,
                    width: 150,
                    
                  });
                  
                  row.push({
                    title: headr[key].menu[obj].title,
                    dataIndex: dataIndex,
                    key: dataIndex,
                    width: 150,
                    
                  });
              }
            }
            
            this.columns.push({
                title: headr[key].title,
                children: row
             });
        }

        //加载数据
        if (data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        
        this.loading = false;
        this.tableData = data;
        this.tableDataLength = data.length;

        //配置分页
        this.pagination.current  = foot.current;
        this.pagination.pageSize = foot.pageSize;
        this.pagination.total    = foot.total;

      });
    },
    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
      display: flex;
      justify-self: start;
      .info-tips{
        line-height: 32px;
        width: 100%;
        text-align: center;
      }
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>