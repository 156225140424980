<template>
    <div class="container">
        <div style="display: flex;justify-content: space-between;">
            <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
                <a-breadcrumb-item>优惠券</a-breadcrumb-item>
            </a-breadcrumb>
            <!-- <a-button class="ant-btn-primary" style="margin-top: 10px;" @click="goBack">返回</a-button> -->
        </div>
        <div class="mainBox">
  
            <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" :page-size-options="pageSizeOptions">
                
            </a-table>
       
        </div>
    </div>
  </template>
  
  <script>
    const columns = [
        {
            title: '名称',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '优惠金额',
            dataIndex: 'num',
            key: 'num',
        },
        {
            title: '使用范围',
            key: 'usetype',
            dataIndex: 'usetype',
        },
        {
            title: '金额限制',
            key: 'min',
            dataIndex: 'min',
        },
        {
            title: '失效时间',
            key: 'exptime',
            dataIndex: 'exptime',
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
        }
    ];

    const data = [
   
    ];
    import { getInstCoupon } from "@/request/api";
  
    export default {
        //import引入的组件需要注入到对象中才能使用
        components: {  },
        data () {
            return {
                data,
                columns,
                pagination: {
                    total: 0,
                    pageSize: 15,
                    current: 1
                },
            };
        },
        //监听属性 类似于data概念 
        computed: {},
        //监控data中的数据变化
        watch: {},
        //方法集合
        methods: {
            goBack(){
                
            },
            handleTableChange(pagination){
                this.pagination.current = pagination.current;
                this.pagination.pageSize = pagination.pageSize;
                this.getCoupon();
            },
            async getCoupon(){
                let parames = {
                    page:this.pagination.current
                };
                let that = this;
                getInstCoupon(parames).then((res) => {
                    that.data = res.data.data;
                    that.pagination.total = res.data.total;
                })
                .catch((err) => {
                    that.$message.error(err.data.message);
                });
            }
        },
        //生命周期 - 创建完成（可以访问当前this实例）
        created () {
            this.getCoupon();
        },
        //生命周期 - 挂载完成（可以访问DOM元素）
        mounted () { },
        beforeCreate () { }, //生命周期 - 创建之前
        beforeMount () { }, //生命周期 - 挂载之前
        beforeUpdate () { }, //生命周期 - 更新之前
        updated () { }, //生命周期 - 更新之后
        beforeDestroy () { }, //生命周期 - 销毁之前
        destroyed () { }, //生命周期 - 销毁完成
        activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
  //@import url(); 引入公共css类
.mainBox {

    .btn_use{
        color: rgba(16, 142, 233, 1);
    }
    .btn_unknow{
        color: #3d3d3d;
        text-align: center;
        cursor:default;
    }
}
</style>