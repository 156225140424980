<template>
  <div class="container">
    <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
      <a-breadcrumb-item>用餐管理</a-breadcrumb-item>
      <a-breadcrumb-item>报餐统计</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mainBox" style="padding: 24px">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="菜品统计">
          <div class="control_box">
            <Search-bar :selects="selects" :dateTime="dateTime" @search="search" @reset="search" />
          </div>
          <div class="text-center" v-if="detailArr.length == 0">
            <a-icon type="file" />
            <p>内容为空</p>
          </div>
          <div v-else>
            <a-tabs default-active-key="0">
              <a-tab-pane v-for="(item,index) in detailArr" :key="index" :tab="item.title">
                <a-table
                  :columns="columnsDetail"
                  :data-source="item.data"
                  :loading="loading"
                  :row-key="(r,i)=>{i.toString()}"
                  :pagination="pagination"
                />
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="人数统计">
          <div class="control_box">
            <Search-bar :selects="selects" :dateTime="dateTime" @search="search" @reset="search" />
          </div>
          <!-- 内容区域 -->
          <div class="text-center" v-if="usersArr.length == 0">
            <a-icon type="file" />
            <p>内容为空</p>
          </div>
          <div v-else>
            <a-table
              :columns="columnsUsers"
              :data-source="usersArr"
              :loading="loading"
              :rowKey="(r,i) => {i.toString()}"
              :pagination="pagination"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="订单统计">
          <div class="control_box">
            <Search-bar :selects="selects" :dateTime="dateTime" @search="search" @reset="search" />
          </div>
          <!-- 内容区域 -->
          <div class="text-center" v-if="personArr.length == 0">
            <a-icon type="file" />
            <p>内容为空</p>
          </div>
          <div v-else>
            <a-table
              :columns="columnsPerson"
              :data-source="personArr"
              :loading="loading"
              :rowKey="(r,i) => {i.toString()}"
              :pagination="pagination"
            />
          </div>
        </a-tab-pane>
        
      </a-tabs>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getStatisticsDetail,
  getStatisticsPerson,
  getStatisticsUsers,
  getOnline,
  officesWithout,
  postsWithout,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import moment from "moment";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
  },
  data() {
    return {
      pagination: false,

      loading: false, //table加载状态
      // 表格的列配置
      columnsDetail: [],
      columnsPerson: [],
      //传给searchBar公共搜索组件的数据
      selects: [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
      ],
      dateTime: {
        show: true,
      },
      //请求参数
      queryParam: {
        repast_date: "",
        post_id: "",
        office_id: "",
        is_old: "",
      },
      nowDate: moment(this.getCurrentData(), "YYYY-MM-DD"),
      onlineData: "",
      detailArr: [],
      personArr: [],//人次
      usersArr: [],//人数
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    getCurrentData() {
      return new Date().toLocaleDateString();
    },
    //刷新和搜索一样的方法
    search(val) {
      this.queryParam = {
        repast_date: val.currentDate,
        post_id: val.select.post_id ? val.select.post_id : "",
        office_id: val.select.office_id ? val.select.office_id : "",
      };
      if (new Date(this.queryParam.repast_date) < this.onlineData) {
        this.queryParam.is_old = 1;
      } else {
        this.queryParam.is_old = 0;
      }
      this.getDetailData();
      this.getPersonData();
      this.getUsersData();
    },

    getDetailData() {
      this.loading = true;
      getStatisticsDetail(this.queryParam).then((res) => {
        if (res.data.length == 0) {
          this.loading = false;
          this.detailArr = [];
          return;
        }
        this.detailArr = res.data;
        (this.columnsDetail = [
          {
            title: "菜品名",
            key: "name",
            dataIndex: "name",
          },
          {
            title: "已就餐份数",
            key: "had",
            dataIndex: "had",
          },
          {
            title: "已报餐份数",
            key: "total",
            dataIndex: "total",
          },
        ]),
          (this.loading = false);
      });
    },
    getPersonData() {
      this.loading = true;
      getStatisticsPerson(this.queryParam).then((res) => {
        if (res.data.length == 0) {
          this.loading = false;
          this.personArr = [];
          return;
        }
        this.personArr = res.data;
        this.columnsPerson = [
          {
            title: "用餐时段",
            key: "title",
            dataIndex: "title",
          },
          {
            title: "已用餐订单数",
            key: "had",
            dataIndex: "had",
          },
          {
            title: "总报餐订单数",
            key: "total",
            dataIndex: "total",
          },
        ];
        this.loading = false;
      });
    },
    getUsersData() {
      this.loading = true;
      getStatisticsUsers(this.queryParam).then((res) => {
        if (res.data.length == 0) {
          this.loading = false;
          this.usersArr = [];
          return;
        }
        this.usersArr = res.data;
        this.columnsUsers = [
          {
            title: "用餐时段",
            key: "title",
            dataIndex: "title",
          },
          {
            title: "已用餐人数",
            key: "had",
            dataIndex: "had",
          },
          {
            title: "总报餐人数",
            key: "total",
            dataIndex: "total",
          },
        ];
        this.loading = false;
      });
    },

    //获取上线时间
    getOnlineFn() {
      getOnline().then((res) => {
        this.onlineData = new Date(res.data.msg);
        if (new Date(this.nowDate) < this.onlineData) {
          this.queryParam.is_old = 1;
        } else {
          this.queryParam.is_old = 0;
        }
      });
    },
    //获取部门列表
    getOffices() {
      officesWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[0].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取职务列表
    getPosts() {
      postsWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[1].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    await this.getOnlineFn();
    this.getDetailData();
    this.getPersonData();
    this.getUsersData();
    this.getOffices();
    this.getPosts();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.controlBox {
  display: flex;
  .btn {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
.e-space {
  margin-left: 10px;
}
.text-center {
  margin-top: 50p x;
  text-align: center;
}
</style>