<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 -->
      <div class="control_box">
        <Search-bar :timeRange="timeRange" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0 || loading">导出</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :loading="loading" :columns="columns" :data-source="tableData" bordered :pagination="pagination"
          @change="handleTableChange" rowKey="office_id"></a-table>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { firstDiscountByOffice, userLog } from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data () {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "经营报表",
        },
        {
          key: 1,
          title: "首份优惠统计",
        },
      ],
      timeRange: {
        show: true,
        placeholderStart: "开始时间(默认昨天)",
        placeholderEnd: "结束时间(默认昨天)",
        rangeLimit: true
      },
      queryParam: {
        page: 1,
        begin_date: "",
        end_date: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      tableDataLength: 0,
      exportToExcelData: [],
      loading: false,
      columns: [], // 表格的列配置
      pagination: {
        total: 0,
        pageSize: 10, 
        current: 1,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //列表下载
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },
    //数据写入excel
    export2Excel () {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = [
                  "部门",
                  "总报餐数",
                  "总金额",
                  "含首份优惠报餐数",
                  "含首份优惠的报餐总金额",
                  "首份优惠总金额"
                ]; // 导出的表头名信息
        const filterVal = [
                "office_title",
                "order_nums",
                "order_money",
                "order_discount_num",
                "order_discount_total",
                "order_discount_money"
              ];// 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "首份优惠统计"); // 导出的表格名称，根据需要自己命名
        this.userLogFn()
      });
    },
    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    // 刷新
    search (val) {
      this.queryParam = {
        page: 1,
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      this.pagination.current = 1;
      this.getTableData();
    },
    //获取表格数据
    getTableData () {
      this.loading = true;
      //获取分页的数据用于展示
      firstDiscountByOffice(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }

        let formatData = resultData.data;

        this.loading = false;
        this.tableData = formatData;
        this.tableDataLength = formatData.length;

        //配置表格列头
        this.columns = [
          {
            title: "部门",
            ellipsis:true,
            width:175,//175就是限制10个字的长it度
            align:'center',
            dataIndex: 'office_title'
          },
          {
            title: "总报餐数",
            ellipsis:true,
            // width:175,//175就是限制10个字的长it度
            align:'center',
            dataIndex: 'order_nums'
          },
          {
            title: "总金额",
            ellipsis:true,
            // width:175,//175就是限制10个字的长it度
            align:'center',
            dataIndex: 'order_money'
          },
          {
            title: "含首份优惠报餐数",
            ellipsis:true,
            // width:175,//175就是限制10个字的长it度
            align:'center',
            dataIndex: 'order_discount_num'
          },
          {
            title: "含首份优惠的报餐总金额",
            ellipsis:true,
            // width:175,//175就是限制10个字的长it度
            align:'center',
            dataIndex: 'order_discount_total'
          },
          {
            title: "首份优惠总金额",
            ellipsis:true,
            // width:175,//175就是限制10个字的长it度
            align:'center',
            dataIndex: 'order_discount_money'
          }
        ];
      });
      //获取不带分页的数据用于导出
      firstDiscountByOffice({...this.queryParam, no_paging: 1}).then((res) => {
        let resultData = res.data;
        if (resultData.length == 0) {
          this.dataForExport = [];
          return;
        }
        this.dataForExport = resultData;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            begin_date:this.queryParam.begin_date,
            end_date:this.queryParam.end_date,
            total:this.exportToExcelData.length
          };
      userLog(moduleId,param).then(res => {}).catch(err => {
        console.log(err)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>