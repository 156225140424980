<!--
 * @Description: 404页面
 * @Author: Chen Bi Qi
 * @Date: 2020-05-08 18:27:30
 * @LastEditTime: 2020-09-15 15:39:31
 -->
<template>
  <div class="page404 noPadding">
    <p class="page404_p0">Oooops! Sorry The Page Not Found :(</p>
    <router-link to="/">
      <a-button>返回首页</a-button>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>
<style scoped lang="less">
.page404 {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #b5c794 url(../../assets/404img/img-404.png) no-repeat center
    center !important;
  .page404_p0 {
    font-size: 20px;
    color: #fff;
    text-align: center;
    padding: 80px 0 20px 0;
  }
}
</style>
