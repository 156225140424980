<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="plus" @click="showBatch()" style="margin-right: 10px">添加管理员</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="action" slot-scope="text, record">
            <a-button type="link" @click="() => edit(record.id)" >编辑</a-button>

            <a-button type="link" @click="() => remove(record.id)" class="e-space" >删除</a-button>
          </template>
          <template slot="is_valid" slot-scope="text, record">
            <a-switch :checked="record.is_valid == 1" :loading="disableLoading" @change="() => changeStatus(record)" />
          </template>
          <template slot="head_img" slot-scope="text, record">
            <img :src="record.head_img" class="head-img" />
          </template>
        </a-table>
      </div>
    </div>
    <!-- 新增/修改 -->
    <a-modal v-model="editModalVisible" :title="editModalTitle" cancelText="取消" :width="600" @ok="preSave()"
      okText="提交">
      <a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :model="tmp"
        :rules="formRules">
        <a-form-model-item label="账号" prop="username" :class="{'no-solt':tmp.id == 0}">
          <a-input v-model="tmp.username" placeholder="账号首位需以字母开头，位数不小于4位数" />
        </a-form-model-item>
        <span class="form-tips" v-if="tmp.id == 0">已根据食堂名称自动生成账号,您可以自行修改</span>
        
        <a-form-model-item label="密码" prop="password">
          <a-input v-model="tmp.password" placeholder="请输入密码" />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="repassword">
          <a-input v-model="tmp.repassword" placeholder="请再次输入密码" />
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="realname">
          <a-input v-model="tmp.realname" placeholder="请输入姓名" />
        </a-form-model-item>
        <a-form-model-item label="手机号码" prop="mobile">
          <a-input v-model="tmp.mobile" placeholder="请输入手机号码" />
        </a-form-model-item>

        <a-form-model-item label="权限" prop="groupid">
          <a-tree checkable :tree-data="authorityItems" :replaceFields="treeFields" :checkedKeys="tmp.groupid"
            @check="selectAutority" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  instAdmins,
  instGetName,
  disableInstAdmin,
  addInstAdmin,
  editInstAdmin,
  getEditInstAdmin,
  removeInstAdmin,
  authorities,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.tmp.repassword !== "") {
          this.$refs["batchForm"].validateField("repassword");
        }
        callback();
      }
    };
    let validateRepass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.tmp.password) {
        callback(new Error("两次密码不一致!"));
      } else {
        callback();
      }
    };
    let validateMobile = (rule, value, callback) => {
      let regexp = /^1[3456789]\d{9}$/;
      if (!regexp.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    let validateUsername = (rule, value, callback) => {
      let regexp = /^[a-zA-Z][a-zA-Z0-9-@_]{3,19}$/;
      if (!regexp.test(value)) {
        callback(new Error("用户名格式至少四位且以字母开头，不支持特殊符号"));
      } else {
        callback();
      }
    };
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "系统设置",
        },
        {
          key: 1,
          title: "子管理员",
        },
      ],

      //传给searchBar公共搜索组件的数据
      inputs: [
        {
          placeholder: "请输入账户名称",
          model: "username",
          width: 300,
        },
      ],
      //请求参数
      queryParam: {
        page: 1,
        title: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [], //不分页的数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "头像",
          key: "head_img",
          scopedSlots: { customRender: "head_img" },
        },
        {
          title: "账户",
          key: "username",
          dataIndex: "username",
        },
        {
          title: "姓名",
          key: "realname",
          dataIndex: "realname",
        },
        {
          title: "邮箱",
          key: "email",
          dataIndex: "email",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "状态",
          key: "is_valid",
          scopedSlots: { customRender: "is_valid" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },
      disableLoading: false,
      editModalVisible: false,
      editModalTitle: "新增管理员",
      nums:{},
      tmp: {
        id: "",
        username: "",
        realname: "",
        mobile: "",
        password: "",
        repassword: "",
        groupid: [],
      },
      authorityItems: [],
      treeFields: {
        title: "name",
        key: "id",
        children: "child",
      },
      formRules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { validator: validateUsername, trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        groupid: [{ required: true, message: "请勾选权限", trigger: "blur" }],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        username: val.input.username ? val.input.username : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      instAdmins(this.queryParam).then((res) => {
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
        if (res.data.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = res.data.data;
        this.loading = false;
      });
    },
    //
    getName(){
      let param = {};
      let that = this;
      instGetName(param).then((res) => {
       
        console.log(res.data.result.str);

        that.nums = res.data.result;
      });
    },
    edit (id) {
      getEditInstAdmin(id)
        .then((res) => {
          this.tmp.password = '';
          this.tmp.repassword = '';
          this.tmp.id = res.data.user.id;
          this.tmp.username = res.data.user.username;
          this.tmp.realname = res.data.user.realname;
          this.tmp.mobile = res.data.user.mobile;
          this.editModalVisible = true;
          this.editModalTitle = "修改管理员";
          let a = new Array();
          let t = res.data.user_resource_group;
          for (var i in t) {
            for (var j in t[i]["child"]) {
              if (t[i]["child"][j]["checked"]) {
                a.push(t[i]["child"][j]["id"]);
              }
            }
          }
          this.tmp.groupid = a;
        })
        .catch((err) => {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        });
    },
    changeStatus (row) {
      this.disableLoading = true;
      let p = {
        sub_admin_id: row.id,
        disable: row.is_valid == 1 ? 0 : 1,
      };
      disableInstAdmin(p)
        .then((res) => {})
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
            row.is_valid = p.disable;
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    changeTmpStatus () {
      this.tmp.is_valid = this.tmp.is_valid == 1 ? 0 : 1;
    },
    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //打开个性化批量充值导入模态框
    showBatch () {
      this.editModalVisible = true;
      this.editModalTitle = "添加管理员";
      this.resetTmp();
      this.tmp.username = (this.nums.str?this.nums.str:'')+''+(this.nums.num?this.nums.num:'');
    },
    resetTmp () {
      this.tmp.id = "";
      this.tmp.username = "";
      this.tmp.realname = "";
      this.tmp.mobile = "";
    },
    remove: function (id) {
      let th = this;
      this.$confirm({
        title: "操作提示",
        content: "删除之后不可恢复，您确认删除该数据？",
        onOk () {
          removeInstAdmin(id)
            .then((res) => {
              th.$message.success("操作成功");
              th.getTableData();
            })
            .catch((err) => {
              if (err.status == 204) {
                th.$message.success("操作成功");
                th.getTableData();
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            });
        },
        onCancel () { },
      });
    },
    preSave () {
      let th = this;
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          th.saveInstAdmin();
        }
      });
    },
    saveInstAdmin () {
      this.tmp.is_valid = 1;
      if (!this.tmp.id) {
        if (!this.tmp.password) {
          this.$message.info('请填写密码')
          return
        }
        if (!this.tmp.repassword) {
          this.$message.info('请填写确认密码')
          return
        }
        if (this.tmp.repassword != this.tmp.password) {
          this.$message.info('两次输入的密码不一致')
          return
        }
        addInstAdmin(this.tmp)
          .then((res) => {
              this.getName();
          })
          .catch((err) => {
            this.getName();
            if (err.status == 201) {
              this.$message.success("操作成功");
              this.getTableData();
              this.editModalVisible = false;
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      } else {
        editInstAdmin(this.tmp.id, this.tmp)
          .then((res) => {
            this.$message.success("操作成功");
            this.getTableData();
            this.editModalVisible = false;
          })
          .catch((err) => {
            if (err.status == 201) {
              this.$message.success("操作成功");
              this.getTableData();
              this.editModalVisible = false;
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      }
    },
    getAuthorityItems () {
      authorities()
        .then((res) => {
          let t = res.data;
          // for (var i in t) {
          //   for (var j in t[i]["child"]) {
          //     if (t[i]["child"][j]["name"] == "报餐统计") {
          //       //a.push(t[i]['child'][j]['id'])
          //       t[i]["child"].splice(j, 1);
          //     }
          //   }
          // }
          // t[1].child.map((item) => {
          //   if (item.id == 12) {
          //     item.name = "报餐明细与报餐统计";
          //   }
          //   return item;
          // });
          this.authorityItems = t;
        })
        .catch((err) => {
          if (err.status == 201) {
            this.$message.success("操作成功");
            this.getTableData();
            this.editModalVisible = false;
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    selectAutority (v) {
      this.tmp.groupid = v;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
    this.getAuthorityItems();
    this.getName();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
.e-space {
  margin-left: 10px;
}
.head-img {
  width: 50px;
}
.form-tips{
  color: #c0c0c0;
  margin-left: 65px;
}
.no-solt{
  margin-bottom: 0;
}
</style>