<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox"></div>
    <a-modal :width="800" :height="500" v-model="showModal" title="提示" @ok="handleSure" okText="确定">
      他人代报餐开通后由食堂报餐端用户自行进行绑定使用
    </a-modal>
  </div>
</template>

<script>
//引入封装的组件
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    MyBreadcrumb
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "拓展应用",
        },
        {
          key: 1,
          title: "他人代报餐",
        },
      ],
      showModal: false
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleSure () {
      this.showModal = false
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.showModal = true
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>
