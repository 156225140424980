<template>
  <div class="main" style="padding-bottom:15px;">
    <div style="display:flex;justify-content: space-between;">
      <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
        <a-breadcrumb-item>续费管理</a-breadcrumb-item>
      </a-breadcrumb>
      <a-button class="banner-btn" @click="goService">更多人工服务, 点此查看&nbsp;&lat;</a-button>
    </div>
    
    <div class="summary">
        <div style="display:flex">
            <div style="max-width:500px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;-o-text-overflow:ellipsis;">
              <a-tooltip>
                <template #title>当前开通功能：{{moduleTxt}}</template>
                当前开通功能：{{moduleTxt}}
              </a-tooltip>
            </div>
            <span>已加入人数：{{inst_num}}/{{version_num}}</span> 
            <span>到期时间：{{expiration}}</span>
        </div>
        <div>
          
          <a-button class="ant-btn-primary" style="margin-right:5px;" @click="goCoupon">优惠券</a-button>
          <a-button class="ant-btn-primary" @click="goOrderList">订单与发票管理</a-button>
        </div>
        
    </div>
    <a-row>
      <a-col :span="17">
        <div class="version-list-title" style="height:85px;margin-top: 5px;">
          <div style="width:80px;" class="label lh-10">组合推荐</div>
          <div style="width:calc(100% - 80px);display: flex;justify-self: start;">
            <span class="packet-items" v-for="item,idx in packetList" :key="'packet'+idx" :class="{'active':isActive === idx}" @click="checkSel(item.id)">
              
                <a-popover>
                  <template slot="content">
                    <p style="width:160px"> {{item.description}} </p>
                  </template>
                  {{item.title}}
                </a-popover>

            </span>
            <span class="packet-items" :class="{'active':isActive === ''}" @click="checkSel(0)">
               <a-popover>
                  <template slot="content">
                    <p style="width:160px"> 选择更多的功能来满足使用需求。 </p>
                  </template>
                  自定义模式
                </a-popover>
            </span>
          </div>
        </div>
        <div class="version-list-title">
          <div style="width:80px;" class="label lh-40">功能选择</div>
          <div style="width:calc(100% - 80px);">
            <a-table :columns="columns" :data-source="modules" :row-selection="rowSelection" :pagination="false" bordered :rowKey="(record) => {return record.key;}" :scroll="{ y: 390 }">
              <div slot="modulename" slot-scope="text,record" class="custom-cell">
                  <a-tooltip  v-if="record.disabled" class="check-tip">
                    <template slot="title">
                      {{record.repelStr}}
                    </template>
                      
                  </a-tooltip>
                  {{text}}
                </div>
            </a-table>
          </div>
        </div>
        <div class="num-level">
          <div class="slider-label lh-30">食堂人数</div>
          <div class="num-unlimit">
            <ul class="num-checkbox">
              <li @click="changeIsUnlimit(false)" :class="form.unlimit?'':'active'">自定义人数</li>
              <li @click="changeIsUnlimit(true)" :class="form.unlimit?'active':''" v-if="nolimit">不限人数</li>
            </ul>
          </div>
          <div class="slider-box" v-if="!form.unlimit">
            <a-row>
              <a-input-number id="inputNumber" v-model:value="inputNumberValue" :min="minNum" :max="10000" :step="20" :precision="0" @change="onChange" @blur="blurChange" />
              <!-- <a-col :span="16">
                {{ form.num }}
                <a-slider :value="form.num" :min="20" :step="20" :max="1000" @change="onChange" @afterChange="getVersionPriceInfo"/>
              </a-col>
              <a-col :span="4">
                <a-input-number disabled v-model:value="form.num" :min="20" :max="1000" style="marginLeft: 16px;" />
              </a-col> -->
            </a-row>
            <div class="slider-tip" style="width:100%;color:#aaa;">
              <div class="fs-12">
                人数价格为 20人/60元/年。
              </div>
              <div class="fs-12">
                输入人数需为20倍数且不小于当前已加入人数。
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="7">
        <div class="renew-form">
          <div style="font-size: 18px;font-weight: 800;">已选配置</div>
          <a-form-model :model="form" :layout="formLayout" :label-col="{ span: 7 }" :wrapper-col="{ span: 18 }" labelAlign="left">
            <a-form-model-item style="height: 210px;overflow-y: scroll;">
              <ul class="selected-modules">
                <li v-for="item,idx in selectedModules" :key="'module'+idx">
                  <span>{{item.title}}</span>
                  <span>{{item.price}}</span>
                </li>
              </ul>
            </a-form-model-item>
            <a-form-model-item label="购买时长">
              <ul class="duration">
                <li :class="form.duration==item.value?'active':''" v-for="item,idx in durationList" :key="'duration'+idx" @click="selectDuration(item.value)">
                  <span>{{item.title}}</span>
                  <span class="duration-tip" v-if="item.tip">{{item.tip}}</span>
                </li>
              </ul>
            </a-form-model-item>
            <div class="tips" v-if="showTips">
                不对已购买功能续费，仅进行未开通功能购买或人数扩容，购买成功后即时生效;补差价详细规则可<a href="javascript:;" @click="Illustrate = true">点击查看</a>
            </div>
            <a-form-model-item label="最新到期日">
              <span>{{exp_time}}</span>
            </a-form-model-item>

            <a-form-model-item label="优惠券">
              <a-select default-value="请选择优惠券" v-model="selectedValue" style="width: 220px" @change="changeVoute">
                <a-select-option :value="item.id" :disabled="item.disabled" v-for="item,idx in couponList" :key="'coupon'+idx">{{item.title}}</a-select-option>
              </a-select>
            </a-form-model-item>
           
            <div class="money-box">
              <div class="money-sale">
                <span class="money">合计：</span>
                <span class="money-total">¥{{realPrice}}</span>
              </div>
              <div class="money-sale">
                已优惠：¥{{sale}}
              </div>
            </div>

            <a-form-model-item label="服务协议"></a-form-model-item>
            
            <div class="protocol-box">
              <div class="protocol">
                <a-checkbox @change="changeProtocalChecked">
                  我已阅读并同意
                </a-checkbox>
                <a href="javascript:;" @click="showRule = true">服务条款</a>、
                <a href="javascript:;" @click="showPrivacy = true">隐私政策</a>
              </div>
              <a-button type="primary" @click="toPay" style="margin-left: 35px;">
                确认下单
              </a-button>
              
            </div>
            
          </a-form-model>
        </div>
      </a-col>
    </a-row>

    <!-- 选择支付方式弹窗 -->    
     <a-modal v-model="selectPayType" title="选择支付方式" :keyboard="false" :maskClosable="false" :footer="null"
      @cancel="noSurePayType">
      <div style="color:red;">购买系统版本或者增值功能完成后请重新登陆生效</div>
      <a-radio-group v-model="payType" @change="changePayType">
        <a-radio v-for="item in payTypes" :key="item.key" :value="item.key">
          {{item.value}}
        </a-radio>
      </a-radio-group>

      <div style="text-align: center" v-show="showWxScan">
        <!-- 装微信支付二维码的容器 -->
        <div style="display: flex; justify-content: center;marginTop:10px;" class="qrcode" ref="qrCodeUrl"></div>
        <p style="marginTop:10px;">
          <img src="https://vip.51baocan.com/static/home/pay/img/step.png" alt srcset />
        </p>
        <a-button icon="transaction" style="background-color: #00c800; color: white; margin: 15px auto"
          @click="wxPaySuccess">支付成功</a-button>
      </div>
      <div id="aliPay"></div>
      <div id="unionPay"></div>
      <div id="quickPay"></div>
    </a-modal>

    <!-- 对公汇款 -->
    <a-modal v-model="showUnderLine" title="对公汇款订单详情可在订单与发票管理中查看" :footer="null" :width="400">
      <div style="text-align: center">
        <div>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              开户名称:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              福建尚汇信息科技有限公司
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              开<i style="paddingLeft:7px;" />户<i style="paddingLeft:7px;" />行:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              中国建设银行股份有限公司漳州东城支行
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              账<i style="paddingLeft:26px;" />号:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              35050166810700001235
            </a-col>
          </a-row>
        </div>
        <p>
          <!-- <img src="https://vip.51baocan.com/static/home/images/kefu.png" alt srcset /> -->
          <img :src="kefuSrc" alt srcset style="width: 180px;margin-top: 20px;" />
          
        </p>
        <p>请及时添加客服微信或者联系客服电话:</p>
        <p>4009-180-190</p>
      </div>
    </a-modal>

    <!-- 服务协议 -->
    <a-modal v-model="showRule" title="“食堂有饭”服务条款" :width="1000" @ok="showRule = false">
      <div v-html="serviceContent" style="display:block;"></div>
    </a-modal>
    <!-- 隐私协议 -->
    <a-modal v-model="showPrivacy" title="“食堂有饭”隐私政策" :width="1000" @ok="showPrivacy = false">
      <div v-html="privacyContent"></div>
    </a-modal>
    <!-- 仅升配说明 -->
    <a-modal v-model="Illustrate" title="升降配规则说明" :width="1000" @ok="Illustrate = false">
      <div v-html="rulesContent"></div>
    </a-modal>

  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { getModules, getInstVersionInfo, getVersionPacket, getVersionPrice, payOrder, getPolicy, getCoupon } from "@/request/api";
const columns = [
  {
    title: '功能名称',
    dataIndex: 'title',
    key: 'title',
    width: 170,
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'modulename' },
  },
  {
    title: '应用场景',
    dataIndex: 'scene',
    key: 'scene',
  },
  {
    title: '价格(元/年)',
    key: 'price',
    dataIndex: 'price',
    width: 120
  }
];
const durationList = [
  {
    title: '升降配',
    value: 0
  },
  {
    title: '半年',
    value: 6
  },
  {
    title: '1年',
    value: 12
  },
  {
    title: '2年',
    value: 24
  },
  {
    title: '3年',
    value: 36,
    // tip: '8.5折'
  }
];
const modules = [
  // {
  //   key: '1',
  //   title: 'John Brown',
  //   scene: '应用场景描述',
  //   price: 100,
  // }
];
const payTypes = [
  {
    key: 1,
    value: '微信'
  },
  {
    key: 2,
    value: '支付宝'
  },
  {
    key: 3,
    value: '企业网银'
  },
  {
    key: 4,
    value: '对公汇款'
  },
  {
    key: 6,
    value: '快捷支付'
  }
];
export default {
  data() {
    return {
      formLayout: 'horizontal',
      form: {
        unlimit: false,
        duration:null
      },
      modules,
      columns,
      payTypes,
      durationList,
      numUnlimit:false,
      selectedModules: [],
      protocalChecked: false,
      moduleTxt:'',
      inst_num:0,
      version_num:0,
      expiration:'',
      packetList:[],
      exp_time:'',
      total:0,//优惠前
      sale:0,//优惠额度
      realPrice:'-',//实际需要支付
      selectedRowKeys:[],
      showTips:false,
      timer:-1,//续费时长
      selectPayType:false,
      showWxScan: false,
      payType:'',
      order_number: '',//订单号
      nolimit:false,
      showRule: false,
      showPrivacy:false,
      showUnderLine:false,
      Illustrate:false,
      serviceContent:'',
      privacyContent:'',
      rulesContent:'',
      instModules: [],
      couponList:[],
      cid:0,
      selectedValue:'请选择优惠券',
      kefuSrc:'https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/lanjie.png',
      inputNumberValue:20,//人数输入框的当前值
      minNum:20//人数输入框允许的最小值
    };
  },
  computed: {
    isActive(){
      const selectStr = this.selectedRowKeys.sort((a,b)=>a-b).toString()
      
      const _reduce = this.packetList.map(item=>item.modules).reduce((pre,cur,index)=>{
        const arr = cur.sort((a,b)=>a-b).toString()
        return selectStr === arr ? index : pre
      },"")
      return _reduce
      // const arr = this.packetList.map(item=>item.modules)
      // const arr1 = arr.reduce((pre,cur,index)=>{
      //   const selectLen = this.selectedRowKeys.length
      //   const curLen = cur.length
      //   const bol = selectLen !== curLen 
      //     ? false 
      //     : cur.filter(item=>!this.selectedRowKeys.includes(item)).length
      //     ? false
      //     : true
        
      //   return bol?[...pre,index] : pre
      // },[])
      // console.log(arr1)
      // return arr1[0]
    },
    rowSelection() {
      let th = this
      return {
        columnTitle: ' ',
        onSelect: (record,selected)=>{
          
          if(selected){
            //被选中，则关联项也要选中
            th.selectedRowKeys.push(record.key);
            let tmparr = th.selectedRowKeys.concat(record.joint);
            th.selectedRowKeys = Array.from(new Set(tmparr));
          }else{
            //取消选中，则关联该项的也要取消选中
            console.log('取消选中'+th.selectedRowKeys.indexOf(record.key))
            th.selectedRowKeys.splice(th.selectedRowKeys.indexOf(record.key),1);
            console.log(th.selectedRowKeys);
            th.unselectAttach(record.key)
          }
          console.log(th.selectedRowKeys)
          th.setModules(th.selectedRowKeys);
          this.$set(this.form,'duration',null);
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.disabled, // Column configuration not to be checked
            name: record.name,
            // defaultChecked:th.selectedKeys&&th.selectedKeys.length>0?th.selectedKeys.includes(record.key):false
          },
        }),
        selectedRowKeys:th.selectedRowKeys
        
      };
    },
  },
  components: {},
  created() {
    this.getVersionPacket();//获取配置包
    this.getSysModules();//获取所有可售模块
    this.getCoupon();//获取可用优惠券
    this.getPolicyFn();//获取服务协议, 隐私政策等
    this.setKefuSrc();
  },
  watch: {
    selectedRowKeys: {
      deep: true,
      handler: function (val) {
        this.refreshCheckboxStatus()
      }
    }
  },
  mounted() {},
  methods: {
    setKefuSrc(){
      if(localStorage.getItem("kefuSrc") != null){
        this.kefuSrc = localStorage.getItem("kefuSrc");
      }
    },
    onChange(e){
      if(e < 20){
        return;
      }

      let closestMultiple = 0;
      if( e % 20 !== 0){
        closestMultiple = Math.round(e / 20) * 20;
      } else {
        closestMultiple = this.inputNumberValue;
      }
      this.form = {...this.form,...{num:closestMultiple}}
      this.getVersionPriceInfo();
    },
    blurChange(){
      if(this.inputNumberValue < 20){
        this.inputNumberValue = 20;
        return;
      }

      if( this.inputNumberValue % 20 !== 0){
        let closestMultiple = Math.round(this.inputNumberValue / 20) * 20;
        this.inputNumberValue = closestMultiple;
        this.form = {...this.form,...{num:closestMultiple}};
        // this.getVersionPriceInfo();
      }
    },
    checkSel(idx){
        if (idx > 0) {
            Object.keys(this.packetList).forEach(key=>{
                if(this.packetList[key].id == idx ){
                  this.selectedRowKeys = this.packetList[key].modules;
                  this.setModules(this.packetList[key].modules);
                }
            });
        } else {
          this.selectedRowKeys = [];
          this.setModules([]);
        }

        this.timer     = -1;
        this.exp_time  = '';
        this.total     = 0;
        this.sale      = 0;
        this.realPrice = '-';
        this.$set(this.form,'duration',null);
    },
    setModules(arr){
        
        this.selectedModules = [];
        this.form.selectedModuleIds = arr;
        if (arr.length > 0) {
            arr.forEach(item=>{
                Object.keys(this.modules).forEach(key=>{
                    if(this.modules[key].key == item){
                        this.selectedModules.push(this.modules[key]);
                    }
                });
            });
        }
        
    },
    changeIsUnlimit(v) {
      
      this.$set(this.form,'unlimit',v)
      if(v){
        this.form.num = 0;
      } else {
        let multiple  = this.calcMultiple(this.inst_num);
        this.form.num = multiple;
        this.inputNumberValue = multiple;
      }
      this.getVersionPriceInfo();
    },
    //计算现有人数与人数包的最小公倍数
    calcMultiple(num){
      if(num <= 20){
        return 20;
      } else if(num % 20 == 0){
        return num;
      } else {
        return num + (20 - (num % 20))
      }
    },
    cancelCheck(){
      this.checkLogin = false;
    },
    selectDuration(value){
      if(value == 0){
        this.showTips = true;
      } else {
        this.showTips = false;
      }
      this.$set(this.form,'duration',value);
      this.timer = value;
      this.getVersionPriceInfo();
    },
    changeProtocalChecked(e){
      let r = e.target.checked
      this.protocalChecked = r
    },
    unselectAttach(id){
      let th = this
      th.modules.forEach(it=>{
        if(it.joint.indexOf(id)!==-1 && th.selectedRowKeys.indexOf(it.key)!==-1){
          th.selectedRowKeys.splice(th.selectedRowKeys.indexOf(it.key),1);
        }
      })
    },
    refreshCheckboxStatus(){
      let tmp = [];
      let th = this
      th.modules.forEach(it=>{
        tmp = it.repel.filter(item => th.selectedRowKeys.includes(item))
        it.disabled = tmp.length>0
      })
      this.modules = [...this.modules]
    },
    //切换优惠券
    changeVoute(e){
      this.cid = e;
      for(let o in this.couponList){
          if(this.couponList[o].id == e){
            if(this.couponList[o].type == 1){
                //折扣
                this.realPrice = parseInt(this.total * (this.couponList[o].num * 1 / 10));
                this.sale = this.total - this.realPrice;
            } else {
                //满减
                this.realPrice = this.total - this.couponList[o].num * 1;
                this.sale = this.couponList[o].num * 1;
            }
          }
      }
    },
    /**
     * 确认支付
     */
    toPay(){
      if(!this.form.selectedModuleIds || this.form.selectedModuleIds.length<=0){
        this.$message.error('请选择功能模块');
        return
      }

      if(this.form.duration == null || this.timer < 0){
        this.$message.error('请选择购买时长');
        return
      }
      if(!this.protocalChecked){
        this.$message.error('请阅读并勾选服务协议后进行支付');
        return
      }

      let that_ = this;
      let change_msg = that_.getModuleChangeMsg()
      if(change_msg){
        this.$confirm({
          title: '下单完成后，会立即关闭以下未勾选的已开通功能，是否继续？',
          content: (<p>{change_msg}<br /></p>),
          onOk() {
            that_.generateOrder()
          },
          onCancel() {
            console.log('Cancel');
          },
          class: 'test',
        });
      }else{
        that_.generateOrder()
      }
    },
    generateOrder(){
      let that_ = this
      let parames = {
          modules:that_.form.selectedModuleIds.join(','),
          num:that_.form.num,
          timer:that_.timer,
          goPay:1,
          cid:that_.cid
      };
      
      getVersionPrice(parames).then((res) => {
          
          if(res.data.pay == 1){
            that_.order_number = res.data.order_number
            //打开支付方式选择
            that_.selectPayType = true;
          } else {
              that_.$message.success('修改成功');
              this.$router.push({
                  path: "tips",
              });
          }
      })
      .catch((err) => {
        that_.$message.error(err.data.message);
      });
    },
    /**
     *获取可售模块
     */
    async getSysModules(){
      let that_ = this;
      //获取用户食堂模块列表
      getModules().then((res) => {
        that_.modules = res.data.data;

        that_.getInstVersion();//获取食堂信息

      });
    },
    /**
     *获取预置版本
     */
    async getVersionPacket(){
      let that_ = this;
      //获取预设版本包
      getVersionPacket().then((res) => {
        let info = res.data.data;
        that_.packetList   = info.module;
      });
    },
    /**
     *获取可用优惠券
     */
     async getCoupon(){
      let that_ = this;
      let parames = {
          type:1,
          price:that_.total
      };
      getCoupon(parames).then((res) => {
        let info = res.data.data;
        for(let i in info){
          info[i].disabled = true;
        }
        that_.couponList = info;
        that_.updateVoute();
      });
    },
    //更新优惠券可用状态
    updateVoute(){
      let obj = this.couponList;
      for(let i in obj){
        if(this.total >= obj[i].min){
          obj[i].disabled = false;
        } else {
          obj[i].disabled = true;
        }
      }
      this.couponList = obj;
    },
    /**
     *获取当前食堂信息
     */
    async getInstVersion(){
      let that_ = this;
      //获取用户食堂版本信息
      getInstVersionInfo().then((res) => {
        let info = res.data.data;
        that_.moduleTxt = info.moduleTxt;
        that_.inst_num = info.inst_num;
        that_.version_num = info.version_num == 0?'无限制': info.version_num;
        that_.expiration = info.version==4?'--':info.expiration;
        that_.exp_time = info.version==4?'--':info.expiration;
        that_.form.num = info.version_num;
        that_.inputNumberValue = info.version_num;
        if( info.version_num == 0 ){
            that_.nolimit = true;
            that_.changeIsUnlimit(true);
        }
        that_.selectedRowKeys = info.moduleids || [];
        that_.instModules = JSON.parse(JSON.stringify(info.moduleids));
        if( info.exp_state == 0 ){
          that_.durationList[0].title = '免费试用';
        }
        //设置人数最小值
        that_.minNum = this.calcMultiple(info.inst_num);

        this.setModules(that_.selectedRowKeys);
      });
    },
    /**
     *获取设置后的价格
     */
    async getVersionPriceInfo(){
      let that_ = this;

      if(that_.selectedModules.length <= 0){
        console.log('未选功能模块');
        return;
      }

      if(that_.timer < 0){
        console.log('未选时长');
        return;
      }

      let parames = {
        modules:that_.form.selectedModuleIds.join(','),
        num:that_.form.num,
        timer:that_.timer
      };
      getVersionPrice(parames).then((res) => {
          let info = res.data.data;
          that_.exp_time  = info.expDate;
          that_.total     = info.total;
          that_.realPrice = info.total;
          that_.sale      = info.sale;
          that_.cid       = 0;
          that_.selectedValue = '请选择优惠券';
          //刷新优惠券
          that_.updateVoute();
      })
      .catch((err) => {
        that_.$message.error(err.data.message);
      });
    },
    async changePayType (e) {
      let mode  = e.target.value
      let that_ = this;
      try {
            let { data } = await payOrder({
                mode,
                order_number: that_.order_number
            })

            if (mode == 1) {
              // 微信
              that_.showWxScan = true;
              that_.$refs.qrCodeUrl.innerHTML = ""; //先清除二维码
              var qrcode = new QRCode(that_.$refs.qrCodeUrl, {
                text: data.url,
                width: 150,
                height: 150,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
            if (mode == 2) {
              //支付宝
              document.getElementById("aliPay").innerHTML = data;
              const div = document.createElement("div"); // 创建div
              div.innerHTML = data; // 将返回的form 放入div
              document.getElementById("aliPay").appendChild(div);
              //document.forms[0].submit();
              let aliForm = document.querySelector("#aliPay form");
              aliForm.submit();
            }
            if (mode == 3) {
              //企业网银
              document.getElementById("unionPay").innerHTML = data;
              const div = document.createElement("div"); // 创建div
              div.innerHTML = data; // 将返回的form 放入div
              document.getElementById("unionPay").appendChild(div);
              // document.forms[0].submit();
              let unionForm = document.querySelector("#unionPay form");
              unionForm.submit();
            }
            if (mode == 4) {
              //线下
              that_.showUnderLine = true
              that_.selectPayType = false
            }
            if (mode == 6) {
              //快捷支付
              document.getElementById("quickPay").innerHTML = data;
              const div = document.createElement("div"); // 创建div
              div.innerHTML = data; // 将返回的form 放入div
              document.getElementById("quickPay").appendChild(div);
              // document.forms[0].submit();
              let quickForm = document.querySelector("#quickPay form");
              quickForm.submit();
            }
          } catch (err) {
            // let { errors } = err.data;
            // for (let i in errors) {
            //   this.$message.error(errors[i][0]);
            // }
          }

    },
    /*
     *@functionName: 选择支付方式-取消
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 16:36:03
    */
    noSurePayType () {
      this.$message.info('当前订单已创建,您可以到订单与发票管理中进行管理')
      this.payType = ''
      if (this.$refs.qrCodeUrl) {
        this.$refs.qrCodeUrl.innerHTML = ""; //清除微信付款二维码
      }
      this.showWxScan = false;
      this.selectPayType = false
    },

    /*
     *@functionName: 微信支付成功
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-07 10:12:57
    */
    async wxPaySuccess () {
      if(this.defaultActiveKey == '4'){
        await this.updateAuths()
      }
      this.payType = ''
      if (this.$refs.qrCodeUrl) {
        this.$refs.qrCodeUrl.innerHTML = ""; //清除微信付款二维码
      }
      this.showWxScan = false;
      this.selectPayType = false
      this.$router.push({
          path: "tips",
      });
    },
    goOrderList(){
      this.$router.push({
        path: "/OrderDetails",
      });
      this.$store.commit('UPDATE_SELECTEDKEYS',['/OrderDetails'])
    },
    goService(){
        this.$router.push({
          path: "/ServiceModules",
        });
        this.$store.commit('UPDATE_SELECTEDKEYS',['/ServiceOder'])
      },
    goCoupon(){
      this.$router.push({
          path: "/CouponManege",
        });
        this.$store.commit('UPDATE_SELECTEDKEYS',['/CouponManege'])
    },
    difference(arr1, arr2){
       return arr1.reduce(function(previous, i) {
             var found = arr2.findIndex(function(j) {
                 return j === i || (isNaN(i) && isNaN(j));
             });
             return (found < 0 && previous.push(i), previous);
         }, []);
    },
    getModuleChangeMsg(){
      let th = this
      // let newModules = th.selectedRowKeys.filter(it=>{
      //   return th.instModules.indexOf(it)===-1
      // })
      let removeModules = th.instModules.filter(it=>{
        return th.selectedRowKeys.indexOf(it)===-1
      })
      //let newModuleTitles = []
      let removeModuleTitles = []
      this.modules.forEach(it=>{
        // if(newModules.indexOf(it.key)!==-1){
        //   newModuleTitles.push(it.title)
        // }
        if(removeModules.indexOf(it.key)!==-1){
          removeModuleTitles.push(it.title)
        }
      })
      // let str = []
      // if(newModuleTitles.length>0){
      //   str.push("新增模块："+newModuleTitles.join("，"));
      // }
      let str = ''
      if(removeModuleTitles.length>0){
        str = "即将关闭功能："+removeModuleTitles.join("，");
      }
      return str;
    },
    async getPolicyFn(){
      let that = this;
      let parames = {}
      getPolicy(parames).then((res) => {
          let info = res.data.data;
          that.serviceContent = info.service.content;
          that.privacyContent = info.privacy.content;
          that.rulesContent   = info.UpgradeRules.content;
      })
      .catch((err) => {
        
      });
    }
  },
};
</script>
<style lang='less' scoped>
@import '~@/style/style.less';
.summary{
    padding: 30px 25px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        margin-right:15px;
    }
}
.packet-items{
  padding: 20px 20px;
  border: 1px solid #EEE;
  margin-left: 12px;
  border-radius: 4px;
  line-height: 1px;
  cursor:pointer;
}
.active{
  background-color:#FF833D;
  color:#fff;
}
.version-list-title{
  display: flex;
  margin-right:5px;
  background-color:#fff;
  padding: 20px;
}
.version-list{
  display: flex;
  margin-top: 5px;
  margin-right:5px;
  background-color:#fff;
  padding: 20px;
}
.renew-form{
  margin-top: 5px;
  background-color:#fff;
  padding: 20px 13px;
}
.num-level{
  margin-top: 15px;
  background-color:#fff;
  padding: 20px;
  margin-right: 5px;
  display: flex;
  align-items: top;
  .slider-label{width:80px;font-weight: bold;}
  .num-unlimit{width:200px;}
  .slider-box{width:calc(100% - 280px);}
}
.label{font-weight:bold;}
.selected-modules{
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  min-height: 303px;
  margin-top: 5px;
  li{
    display: flex;
    justify-content: space-between;
    height:30px;line-height: 30px;
  }
}
.ant-form-item{
  margin-bottom: 0px;
}
.money-box{
  color: #FF833D;
  margin-top: 20px;
  width: 90%;
  // display: flex;
  // justify-content: space-around;
  // align-content: center;

  .money{
    color: #000;
    font-weight: 400;
  }
  .money-total{
    font-size: 28px;
  }
  .money-sale{
    text-align: right;
    margin-right: 38px;
  }
}

.protocol-box{

  display: flex;
  justify-content: flex-start;

  .protocol{
    font-size: 14px;
    a{color:#FF833D;}
  }
}

ul.duration{
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
/*  display: flex;*/
  li:first-child{
    width:100%;
    span{
      display: inline-block;
      height:26px;line-height:26px;
      padding: 0px 12px;
      border:1px solid #EEE;
    }
    span:hover{
      background-color:#FF833D;
      color:#fff;
      cursor: pointer;
    }
    &.active{
      background:#fff;
      span{
        background-color:#FF833D;
        color:#fff;
        cursor: pointer;
      }
    }
  }
  li:not(:first-child){
    display: inline-block;
    padding: 0px 12px;
    margin-right: 5px;
    border:1px solid #EEE;
    height:26px;line-height:26px;
    margin-top: 7px;
    position:relative;
    cursor:pointer;
    &:hover,&.active{
      background-color:#FF833D;
      color:#fff;
    }
    .duration-tip{
      position: absolute;
      background-color:#FF833D;
      right:0px;
      top: -16px;
      font-size:12px;
      color:#666;
      height:16px;
      line-height:16px;
      padding: 0px 2px;

    }
  }
}
ul.num-checkbox{
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  display: flex;
  li{
    display: inline-block;
    padding: 0px 10px;
    margin-right: 5px;
    border:1px solid #EEE;
    height:32px;line-height:32px;
    margin-top: 0px;
    position:relative;
    cursor:pointer;
    &:hover,&.active{
      background-color:#FF833D;
      color:#fff;
    }
  }
}
.tips{
    padding: 0px 15px 10px;
    font-size: 14px;
}
.tips a{
  color: #FF833D;
  margin: 0 6px;
}
.ant-modal-body span{ text-wrap: wrap!important; }
.custom-cell{
  position: relative;
}
.check-tip{
  display: inline-block;
  position:absolute;
  left: 0px;
  top:0px;
  width:28px;
  height:21px;
  background:none;
  margin-left:-59px;
  z-index:1000;
}
.banner-btn{
  margin-right: 15px;
  margin-top: 13px;
  color: #fff;
  background:linear-gradient(to top, #FF833D, #ffb285);
}
</style>
