<template>
  <div class="page404 noPadding">
    <p class="page404_p0">您已进行了模块升级,请退出重新登录以刷新您的配置</p>
    <router-link to="/logout">
      <a-button @click="logOut">退出</a-button>
    </router-link>
  </div>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  name: "Tips",
  mounted() {
    
  },
  components: {
    
  },
  created() {
    
  },
  computed: {
   
  },
  methods: {
    logOut() {
        this.$store.commit("REMOVE_COUNT");
    },
  },
};
</script>
<style scoped lang="less">
.page404 {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #b5c794 url(../../assets/404img/img-404.png) no-repeat center
    center !important;
  .page404_p0 {
    font-size: 20px;
    color: #fff;
    text-align: center;
    padding: 80px 0 20px 0;
  }
}
</style>
