<template>
<!-- 此页为了给二级路由一个通用的出口 -->
  <div :style="{height: '100%' }">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='less' scoped>
</style>
