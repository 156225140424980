<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <!--        <Search-bar :inputs="inputs" :selects="selects" @search="search" @reset="search" />-->
        <div class="">
          <a-button type="primary" icon="plus" @click="showDetailModal('add')" style="margin-right: 10px">添加客餐类型
          </a-button>
        </div>
      </div>

      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
                 :pagination="pagination" @change="handleTableChange">

          <template slot="users" slot-scope="record">
            <span v-for="user in record.users" :key="user.id" class="ant-tag ant-tag-blue" name="tag">{{
                user.realname
              }}</span>
          </template>
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="showDetailModal(record)">编辑</a-button>
            <a-divider type="vertical"/>
            <a-button type="link" @click="deleteFn(record)">删除</a-button>
          </template>
        </a-table>
      </div>
    </div>


    <a-modal v-model="visible" :title="addGuestMeal ? '添加用餐时段' : '编辑用餐时段'" @ok="handleSure" okText="保存">

      <a-form-model :model="addParam" :rules="typeRules" ref="ruleForm" :label-col="{ span: 7 }"
                    :wrapper-col="{ span: 14 }">
        <a-form-model-item label="标题" ref="title" prop="title">
          <a-input v-model="addParam.title" placeholder="请输入客餐名称"  :maxLength="titleMaxLength" required/>
          <span>最多填写15个字符</span>
        </a-form-model-item>
        <a-form-model-item label="客餐餐标" ref="price" prop="price">
          <a-input v-model="addParam.price" placeholder="请输入客餐餐标" max="10000" addon-after="元"
                   aria-required="true"/>
          <span>仅作为客餐的用餐费用统计，无需访客支付</span>
        </a-form-model-item>
        <a-form-model-item label="开放范围" ref="selectedHuser" prop="selectedHuser">
          <a-select
              v-model="addParam.selectedHuser"
              mode="multiple"
              :default-value="addParam.selectedHuser"
              style="width: 100%"
              placeholder=""
              :filter-option="filterOption"
              @change="selectHuser"
          >
            <a-select-option
                v-for="item in huser"
                :key="item.id"
                :value="item.id">
              {{ item.realname }}
            </a-select-option>
          </a-select>
          <span>仅被选择的人员可以申请当前客餐</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  mealtimeList,
  postGuestMealCreate,
  postGuestMealUpdate,
  postGuestMealDelete,
  getGuestMeal,
  huserIndex,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
import util from "@/utils/util";

import moment from "moment";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data() {
    return {
      //面包屑
      breadcrumbs: [
        {
          key: 0,
          title: "客餐/接待餐",
        },
        {
          key: 1,
          title: "客餐管理",
        },
      ],
      titleMaxLength:15,

      typeRules: {
        title: [{required: true, message: "请输入客餐名称", trigger: "blur"}],
        price: [
          {required: true, message: "请输入餐标", trigger: "blur"},
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value === null || value === '') {
                callback(new Error('请输入餐标'));
              } else if (value < 0 || parseFloat(value) > 10000) {
                callback(new Error('餐标范围0-10000元之间'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        selectedHuser: [
          {
            required: true,
            message: '请选择至少一个用户',
            validator: (rule, value, callback) => {
              if (!value || value.length === 0) {
                callback(new Error('selectedHuser is required'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          },
        ],
      },
      //请求参数
      queryParam: {
        page: 1,
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "名称",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "餐标",
          key: "price",
          dataIndex: "price",
        },
        {
          title: "使用范围",
          key: "users",
          width:'500px',
          scopedSlots: {customRender: "users"},
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: {customRender: "action"},
        },
      ],
      huser: [],
      defaultHuser: [],
      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },

      visible: false,
      addGuestMeal: false,
      editId: "",
      //更新参数
      addParam: {
        title: "",
        price: 0, 
        selectedHuser: []
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    //刷新和搜索一样的方法
    search(val) {
      this.queryParam = {
        page: 1,
        is_valid: val.select.is_valid === "" ? "" : val.select.is_valid,
        title: val.input.title ? val.input.title : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },


    handleChange(value) {
      console.log(`selected ${value}`);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },




    //获取表格数据
    getTableData() {
      this.loading = true;
      this.queryParam.no_paging = 0;
      getGuestMeal(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = {...this.pagination};
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data;
        this.loading = false;
      });
    },

    selectHuser(value) {
      console.log('v',value);
      console.log('arr',Array);
      
      this.addParam.selectedHuser = Array.isArray(value) ? value : [value];
      console.log(this.addParam.selectedHuser);
    },
    //获取表格数据
    getHuser() {
      huserIndex({'no_paging': 1}).then((res) => {
        this.huser = res.data;
        console.log('this.huser', this.huser);
      });
    },

    //分页
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //显示添加模态框
    showDetailModal(record) {
      if (record == "add") {
        this.addGuestMeal = true;
        this.addParam = {
          title: "",//名称
          price: 0, //餐标
          selectedHuser: [], //开放范围
        };
      } else {
        this.addGuestMeal = false;
        this.editId = record.id;

        this.addParam = {
          title: record.title,
          price: record.price,
          selectedHuser: [],
        };
        record.users.forEach((user) => {
          this.addParam.selectedHuser.push(user.id);
        });
      }
      console.log(this.addParam);
      this.visible = true;
    },

    //提交表单
    handleSure: util.DebounceBy(function () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {

          if (this.addGuestMeal) {
            postGuestMealCreate(this.addParam)
                .then((res) => {

                })
                .catch((err) => {
                  if (err.status == 201) {
                    this.$message.success("添加成功");
                    this.visible = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  } else {
                    let {errors} = err.data;
                    for (let i in errors) {
                      this.$message.error(errors[i][0]);
                    }
                  }
                });
          } else {

            this.$confirm({
              title: `确认修改？`,
              content: "修改客餐，不会影响历史订单，包括【未就餐】订单，请您注意",
              onOk: () => {
                postGuestMealUpdate(this.editId, this.addParam)
                .then((res) => {
                  console.log(1, res);
                  // this.$message.success("修改成功");
                  // this.visible = false;
                  // // this.queryParam.page = 1;
                  // // this.pagination.current = 1;
                  // this.getTableData();
                })
                .catch((err) => {
                  if (err.status == 201) {
                    this.$message.success("修改成功");
                    this.visible = false;
                    this.queryParam.page = 1;
                    this.pagination.current = 1;
                    this.getTableData();
                  } else {
                    let {errors} = err.data;
                    for (let i in errors) {
                      this.$message.error(errors[i][0]);
                    }
                  }
                });
              },
            });
          }
        } else {
          console.log('error submit!!');
          return false;
          exit;
        }
      });


    }, 3000),

    //删除部门
    deleteFn(record) {
      this.$confirm({
        title: `确认删除 ${record.title}？`,
        content: `删除客餐不会删除已报餐订单，您仍需要服务未就餐订单，确认删除？`,
        onOk: () => {
          // postGuestMealDelete(record.id, {before_del: 1})
          //     .then((res) => {
                // if (res.data.msg == false) {
                  // this.$confirm({
                    // content: "该客餐类型有待就餐的订单，确定删除？",
                    // onOk: () => {
                      postGuestMealDelete(record.id)
                          .then((res) => {
                          })
                          .catch((err) => {
                            if (err.status == 204) {
                              this.$message.success("删除成功");
                              // this.queryParam.page = 1;
                              // this.pagination.current = 1;
                              this.getTableData();
                            } else {
                              this.$message.error("删除失败");
                            }
                          });
                    // },
                  // });
                // } else {
                //   postGuestMealDelete(record.id)
                //       .then((res) => {
                //       })
                //       .catch((err) => {
                //         if (err.status == 204) {
                //           this.$message.success("删除成功");
                //           this.queryParam.page = 1;
                //           this.pagination.current = 1;
                //           this.getTableData();
                //         } else {
                //           this.$message.error("删除失败");
                //         }
                //       });
                // }
              // })
              // .catch((err) => {
              //   if (err.status == 204) {
              //     this.$message.success("删除成功");
              //     this.queryParam.page = 1;
              //     this.pagination.current = 1;
              //     this.getTableData();
              //   } else {
              //     this.$message.error("删除失败");
              //   }
              //   console.log(err);
              // });
        },
      });
    },

    changeRepastBegin(val, str) {
      this.repast_begin = val;
      this.addParam.repast_begin = str;
    },
    changeRepastEnd(val, str) {
      this.repast_end = val;
      this.addParam.repast_end = str;
    },
    changeMsgSendTime(val, str) {
      this.msg_send_time = val;
      this.addParam.msg_send_time = str;
    },
    changeCloseTime(val, str) {
      this.close_time = val;
      this.addParam.close_time = str;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTableData();
    this.getHuser();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;

  .control_box {
    background-color: white;
    padding: 20px;

    .control_box_bottom {
      margin-top: 20px;
    }
  }

  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>