<template>
  <div class="container">
    <My-Breadcrumb style="position:absolute;top:145px;" :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 商品分类 -->
      <div class="show_left">
        <a-page-header style="padding-left:0;;padding-right:0" title="商品分类">
          <template slot="extra">
            <a-button type="primary" icon="plus" @click="showTypeModal('add')">新增类型</a-button>
          </template>
        </a-page-header>
        <a-table :columns="columnsLeft" :data-source="menuTypeData" :loading="loading"
          :rowKey="(menuTypeData) => menuTypeData.id" :pagination="false">
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="showTypeModal(record)" >编辑</a-button>
            <a-divider type="vertical" />
            <a-button type="link" @click="deleteType(record)" >删除</a-button>
            <a-divider type="vertical" />
            <a-button type="link" @click="searchByType(record)" >查询</a-button>
          </template>
        </a-table>
      </div>
      <!-- 商品列表 -->
      <div class="show_right">
        <a-page-header style="padding-left:0;padding-right:0;" title="商品列表">
          <template slot="extra">
            <a-input v-model="queryParam.title" style="width:200px;" placeholder="请输入商品名称"></a-input>
            <a-button type="primary"  @click="getTableData">查询</a-button>
            <a-button type="primary"  @click="showModal('add')">添加商品</a-button>
            <a-button @click="reset" style="margin-left:10px;">刷新</a-button>
          </template>
        </a-page-header>
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="link_url" slot-scope="record">
            <img style="width:50px;heigth:50px" :src="record.link_url" alt />
          </template>
          <template slot="action" slot-scope="record">
            <a-button type="link"  @click="showModal(record)" >编辑</a-button>
            <a-divider type="vertical" />
            <a-button type="link"  @click="deleteFn(record)" >删除</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <!-- 添加/编辑菜品 -->
    <a-modal v-model="visible" :title="addMenu ? '添加商品' : '编辑商品'" @ok="handleUpdate" :width="1000"
      :okText="addMenu ? '新建' : '修改'">
      <a-form-model :model="menuParam" :rules="rules" ref="menuForm" :label-col="{ span: 3 }"
        :wrapper-col="{ span: 21 }">
        <a-form-model-item label="商品名称" prop="title">
          <a-input v-model="menuParam.title" placeholder="商品名称" />
        </a-form-model-item>
        <a-form-model-item label="价格" prop="price">
          <a-input v-model="menuParam.price" placeholder="价格" />
        </a-form-model-item>
        <a-form-model-item label="商品类型" prop="style">
          <a-select v-model="menuParam.style">
            <a-select-option v-for="item in menuTypeData" :key="item.id" :value="item.id">{{item.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="简介" prop="content">
          <a-textarea v-model="menuParam.content" placeholder="简介" auto-size />
        </a-form-model-item>
        <a-form-model-item label="商品图片">
          <a-upload list-type="picture-card" @change="changeFile" :customRequest="customRequest" :file-list="fileList"
            :remove="removePic" @preview="handlePreview">
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 预览图 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <!-- 添加/编辑菜品类型 -->
    <a-modal v-model="typeModalVisible" :title="addType ? '添加商品类型' : '编辑商品类型'" @ok="handleUpdateType" okText="保存">
      <a-form-model :model="typeParam" :rules="typeRules" ref="TypeForm" :label-col="{ span: 7 }"
        :wrapper-col="{ span: 14 }">
        <a-form-model-item label="类型名称" prop="title">
          <a-input v-model="typeParam.title" placeholder="请输入类型名称" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  getGoodsList,
  addGoods,
  goodsUpload,
  getEditGoods,
  delGoods,
  editGoods,
  getTypeList,
  addType,
  editType,
  delType,
} from "@/request/api";
//引入封装的组件
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐管理",
        },
        {
          key: 1,
          title: "小卖部商品管理",
        },
      ],

      //****菜品列表相关参数
      queryParam: {
        page: 1,
        type: "",
        titie: ''
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态
      columns: [
        // 列配置
        {
          title: "商品图片",
          key: "link_url",
          scopedSlots: { customRender: "link_url" },
        },
        {
          title: "商品名称",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "价格",
          key: "price",
          dataIndex: "price",
        },
        {
          title: "类型",
          key: "typeTitle",
          dataIndex: "typeTitle",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        //页配置
        total: 0,
        pageSize: 15,
        current: 1,
      },
      visible: false,
      addMenu: false,
      menuParam: {
        title: "", //必填
        price: "", //必填
        style: "", //必填
        link_url: "",
        content: "",
      },
      fileList: [],
      previewVisible: false,
      previewImage: "",
      rules: {
        title: [{ required: true, message: "请输入菜品名", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        style: [{ required: true, message: '请选择类型', trigger: 'change' }],
      },
      fixedId: "",
      updateId: "",

      //*****菜单类型相关配置
      menuTypeData: [],
      columnsLeft: [
        {
          title: "名称",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "操作",
          key: "action",
          width: 170,
          scopedSlots: { customRender: "action" },
        },
      ],
      typeModalVisible: false,
      addType: false,
      typeParam: {
        title: "",
      },
      typeRules: {
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新
    reset () {
      this.queryParam = {
        page: 1,
        type: "",
        title: ""
      };
      this.pagination.current = 1;
      this.getTableData();
      this.getMenuType();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      getGoodsList(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data.map(item => {
          item.typeTitle = item.get_goods_type.title
          return item
        });
        this.loading = false;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //显示添加模态框
    showModal (record) {
      if (record == "add") {
        //添加
        this.addMenu = true;
        this.menuParam = {
          title: "", //必填
          price: "", //必填
          style: "", //必填
          link_url: "",
          content: "",
        };
        this.fileList = [];
      } else {
        //编辑
        this.addMenu = false;
        this.updateId = record.id;
        getEditGoods(record.id).then((res) => {
          let detail = res.data.goods;
          this.menuParam = {
            title: detail.title, //必填
            price: detail.price, //必填
            style: detail.style, //
            link_url: detail.link_url,
            content: detail.content,
          };

          this.fileList[0] = {
            uid: "1",
            name: "image.png",
            status: "done",
            url: detail.link_url,
          };
        });
      }
      this.visible = true;
    },


    //添加/编辑菜品
    handleUpdate () {
      this.$refs["menuForm"].validate((valid) => {
        if (valid) {
          if (this.addMenu) {
            //添加
            addGoods(this.menuParam)
              .then((res) => {
              })
              .catch((err) => {
                if (err.status == 201) {
                  this.$message.success("添加成功");
                  this.visible = false;
                  this.queryParam.page = 1;
                  this.pagination.current = 1;
                  this.getTableData();
                } else {
                  this.$message.error(err.data.errors.title[0]);
                }
              });
          } else {
            //编辑
            editGoods(this.updateId, this.menuParam)
              .then((res) => {

              })
              .catch((err) => {
                if (err.status == 201) {
                  this.$message.success("修改成功");
                  this.visible = false;
                  // this.queryParam.page = 1;
                  // this.pagination.current = 1;
                  this.getTableData();
                } else {
                  this.$message.error('修改失败，请联系客服');
                }

              });
          }
        }
      });
    },

    //获取部门列表
    getOffices () {
      officesWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.officesData.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取职务列表
    getPosts () {
      postsWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.postsData.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //上传文件
    changeFile (info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      if (fileList.length > 0) {
        if (fileList[0].size > (1024 * 300)) {
          this.$message.info('请上传300kb以下的图片')
          return
        }
      }
      this.fileList = fileList;
      info.file.status = "done";
    },

    //移除图片文件
    removePic (file) {
      this.menuParam.link_url = "";
      return true;
    },

    //上传组件自定义上传方式
    customRequest (data) {
      const formData = new FormData();
      formData.append("goods_img", data.file);
      goodsUpload(formData)
        .then((res) => {
        })
        .catch((err) => {
          if (err.status == 201) {
            this.menuParam.link_url = err.data.path;
          }
        });
    },

    //图片预览
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    //删除菜品
    deleteFn (record) {
      let id = record.id,
        title = record.title;
      this.$confirm({
        content: `确定删除菜品${title}？`,
        onOk: () => {
          delGoods(id)
            .then((res) => {
            })
            .catch((err) => {
              if (err.status == 201) {
                this.$message.success("删除成功");
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              } else {
                this.$message.error("删除失败，请联系客服");
              }
            });
        },
      });
    },

    //获取菜品分类
    getMenuType () {
      getTypeList().then((res) => {
        let data = res.data;
        this.menuTypeData = data;
      });
    },

    //菜品列表查询
    searchByType (record) {
      this.queryParam = {
        page: 1,
        type: record.id,
        title: ''
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //打开菜品类别模态框
    showTypeModal (record) {
      //添加
      if (record == "add") {
        this.addType = true;
        this.typeParam = {
          title: "",
        };
      } else {
        //编辑
        this.addType = false;
        this.typeParam = {
          title: record.title,
          id: record.id,
        };
      }
      this.typeModalVisible = true;
    },

    //添加/编辑菜品类型
    handleUpdateType () {
      this.$refs["TypeForm"].validate((valid) => {
        if (valid) {
          if (this.addType) {
            //添加
            addType(this.typeParam)
              .then((res) => { })
              .catch((err) => {
                if (err.status == 201) {
                  this.$message.success("添加成功");
                  this.typeModalVisible = false;
                  this.getMenuType();
                } else {
                  this.$message.error("分类名称已存在");
                }
              });
          } else {
            //编辑
            editType(this.typeParam.id, { title: this.typeParam.title })
              .then((res) => {})
              .catch((err) => {
                if (err.status == 201) {
                  this.$message.success("编辑成功");
                  this.typeModalVisible = false;
                  this.getMenuType();
                } else {
                  this.$message.error("编辑失败,请联系客服");
                }

              });
          }
        }
      });
    },

    //删除菜品类型
    deleteType (record) {
      let id = record.id,
        title = record.title;
      this.$confirm({
        content: `确定删除${title}类型？`,
        onOk: () => {
          delType(id)
            .then((res) => { })
            .catch((err) => {
              if (err.status == 201) {
                this.$message.success("删除成功");
                this.getMenuType();
              } else {
                this.$message.error(err.data.msg);
              }
            });
        },
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getMenuType();
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.container {
  padding-top: 53px;
  .mainBox {
    background-color: #f0f2f5;
    .show_left {
      background-color: white;
      width: 300px;
      padding: 0 10px;
      position: absolute;
      height: calc(92vh - 53px);
      overflow: auto;
    }
    .show_right {
      background-color: white;
      padding: 0 10px;
      width: calc(100% - 310px);
      float: right;
    }
  }
}
</style>