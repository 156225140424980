<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" :selects="selects" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="plus" @click="showAddOffice('add')" style="margin-right: 10px">添加部门</a-button>
        </div>
      </div>

      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="is_valid" slot-scope="record">
            {{ record.is_valid == 0 ? "禁用" : "开启" }}
          </template>
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="showAddOffice(record)" >编辑</a-button>
            <a-divider type="vertical" />
            <a-button type="link" @click="deleteFn(record)" >删除</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal v-model="visible" :title="addOffice ? '添加部门' : '编辑部门'" @ok="handleSure" okText="保存">
      <a-form-model :model="addParam" :rules="rules" ref="addForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="名称" prop="title">
          <a-input v-model="addParam.title" placeholder="请输入部门名称" />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="addParam.is_valid">
            <a-select-option :value="1">开启</a-select-option>
            <a-select-option :value="0">关闭</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="排序" prop="rank">
          <a-input v-model="addParam.rank" placeholder="请输入数字" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  hofficeIndex,
  maxRank,
  createOffice,
  deleteOffice,
  updateOffice,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐人员管理",
        },
        {
          key: 1,
          title: "部门设置",
        },
      ],
      inputs: [
        {
          placeholder: "请输入部门名称",
          model: "title",
          width: 200,
        },
      ],
      //传给搜索组件的参数
      selects: [
        {
          model: "is_valid",
          placeholder: "全部",
          width: 100,
          options: [
            {
              key: "",
              value: "全部",
            },
            {
              key: 0,
              value: "禁用",
            },
            {
              key: 1,
              value: "开启",
            },
          ],
        },
      ],

      //请求参数
      queryParam: {
        page: 1,
        is_valid: "",
        title: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "部门",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "状态",
          key: "is_valid",
          scopedSlots: { customRender: "is_valid" },
        },
        {
          title: "排序",
          key: "rank",
          dataIndex: "rank",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },

      maxRankLimit: "", //最大排序
      visible: false,
      addOffice: false,

      //更新参数
      addParam: {
        id: "",
        title: "",
        is_valid: 1,
        rank: "",
      },

      //新增表单校验规则
      rules: {
        title: [{ required: true, message: "请输入部门名", trigger: "blur" }],
        rank: [{ required: true, message: "请输入排序", trigger: "rank" }],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        is_valid: val.select.is_valid === "" ? "" : val.select.is_valid,
        title: val.input.title ? val.input.title : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      hofficeIndex(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data;
        this.loading = false;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //显示添加模态框
    showAddOffice (record) {
      if (record == "add") {
        this.addOffice = true;
        this.addParam = {
          title: "",
          is_valid: 1,
          rank: "",
        };
      } else {
        this.addOffice = false;
        this.addParam = {
          title: record.title,
          is_valid: record.is_valid,
          rank: record.rank,
          id: record.id,
        };
      }

      this.visible = true;

      this.getMaxRank();
    },

    //获取最大排序
    getMaxRank () {
      maxRank({ action: "office" }).then((res) => {
        this.maxRankLimit = res.data.max_rank;
        this.addParam.rank = this.maxRankLimit * 1 + 1;
      });
    },

    //提交表单
    handleSure: util.DebounceBy(function () {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          if (this.addOffice) {
            if (this.addParam.rank > this.maxRankLimit + 1) {
              //在每个输入排序的input的时候要比较输入的值跟接口返回的最大值1.增加时：输入的值要小于等于最大值+1  2.编辑时输入的值要小于等于最大值
              this.$message.info(`排序值必须小于等于${this.maxRankLimit + 1}`);
              return;
            }
            createOffice(this.addParam)
              .then((res) => {
                this.$message.success("添加成功");
                this.visible = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              })
              .catch((err) => {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              });
          } else {
            if (this.addParam.rank > this.maxRankLimit) {
              //在每个输入排序的input的时候要比较输入的值跟接口返回的最大值1.增加时：输入的值要小于等于最大值+1  2.编辑时输入的值要小于等于最大值
              this.$message.info(`排序值必须小于等于${this.maxRankLimit}`);
              return;
            }
            updateOffice(this.addParam.id, this.addParam)
              .then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              })
              .catch((err) => {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              });
          }
        }
      });
    }, 3000),

    //删除部门
    deleteFn (record) {
      this.$confirm({
        content: `是否删除${record.title}这个部门`,
        onOk: () => {
          deleteOffice(record.id)
            .then((res) => { })
            .catch((err) => {
              if (err.status == 204) {
                this.$message.success("删除成功");
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              } else {
                this.$message.error(err.data.msg);
              }
            });
        },
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
    // this.getMaxRank();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>