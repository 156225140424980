import {
  get,
  post,
  postBlob,
  postWithOutQS,
  getBlob,
  patch
} from './http'
//给首页幻灯片跟菜谱图片上传模块使用的上传图片的url
export const swiperUploadApi = `/home/ad/upload`;//首页幻灯片上传
export const menuUploadApi = `/home/menu/upload`;//菜谱图片上传


//通用
export const getBase64Img = p => get('/home/img/base64', p); //获取base64图片
export const getAllAuth = p => get('/home/huser/getAllAuth', p); //获取所有权限
export const getAudit = p => post('/home/audit/getAudit', p); //根据传输审核类型，返回是否有设置改类型的接口  {type:1(账户充值)/2(取消退餐)/3(小票打印))}
export const getOnline = p => get('/home/index/online', p); //上线时间接口
export const optionIsOpen = p => post('/home/external/isOpen', p); //检查某个选项是否开启
export const moduleStayTime = p => get('/home/external/recordUsedFunction', p); //记录模块停留时长
export const isRemind = () => post('/home/index/remind'); //是否需要即将过期的提醒-返回为空则不需要提醒
export const checkModulePower = () => get('/home/auth/checkModulePower'); //验证某个模块是否开启



export const mealtimeListWithout = p => get('/home/index/meal/list', p); //就餐时段-列表(不用权限的)
export const officesWithout = p => get('/home/index/office/list', p); //部门-列表(不用权限的)
export const postsWithout = p => get('/home/index/post/list', p); //职务-列表(不用权限的)
export const sensorsAnalytics = (id, p) => post(`/home/analytics/sensorsAnalyticsInst/${id}`, p); //埋点
export const userLog = (id, p) => post(`/home/huser/userLog/${id}`, p); //记录所有导出功能的搜索条件


//登录
export const getLoginCode = p => post('/home/external/getLoginCode', p); //获取登录验证码
export const getWebCode = p => post('/home/external/getWebCode', p); //获取短信验证码
export const resetInstPass = p => post('/home/external/resetInstPass', p); //修改密码
export const getCaptchaImage = p => get('/home/external/getCaptchaImage', p); //获取验证码图片
export const login = p => post('/home/auth/login', p); //登录
export const easyLogin = p => post('/home/auth/easyLogin', p); //免密登录
export const loginStatus = p => post('/home/auth/status', p); //对应C端用户状态
export const getAllUser = p => get('/home/auth/getAllUser', p); //获取用户所有账户
export const changeLogin = p => post('/home/auth/changeLogin', p); //免密切换账号


//首页
export const getMe = p => get('/home/auth/me', p); //获取个人信息
export const getInstInfo = p => get('/home/infomanage/centrum/getInstInfo', p); //获取食堂单位信息
//首页数据看板
export const getMealReport = p => post('/home/dashboard/mealReport', p); //获取报餐营收
export const getScanCode = p => post('/home/dashboard/scanCode', p); //获取扫码营收
export const getShopIncome = p => post('/home/dashboard/shopIncome', p); //获取小卖部营收

export const getPostOffice = p => post('/home/dashboard/postOffice', p); //获取部门/职务柱状图
export const getGoodPraise = p => post('/home/dashboard/goodPraise', p); //获取好评柱状图
export const getScanCase = p => post('/home/dashboard/scanCase', p); //获取扫码情况柱状图
export const getUseDinnerCase = p => post('/home/dashboard/useDinnerCase', p); //获取系统用餐情况
export const getSysInfo = () => post('/home/dashboard/sysInfo'); //获取系统使用情况
export const getShopCase = p => post('/home/dashboard/shopCase', p); //获取小卖部情况



// 经营报表
export const getInstReport = p => post('/home/report/getInstReport', p); //获取食堂经营报告
export const getExpenseReport = p => post('/home/report/getExpenseReport', p); //获取用户消费报告(带分页的用于页面展示的接口)
export const exportExpenseReport = p => postBlob('/home/report/exportExpenseReport', p); //获取用户消费报告(不带分页的用于导出的接口)
export const getPresentationReport = p => post('/home/report/getPresentationReport', p); //获取用户账户报告(带分页的用于页面展示的接口)
export const exportPresentationReport = p => post('/home/report/exportPresentationReport', p); //获取用户账户报告(不带分页的用于导出的接口)
export const mealTimeByOffice = p => post('/home/report/mealTimeByOffice', p); //获取各时段就餐情况
export const exportMealTimeByOffice = p => postBlob('/home/report/exportMealTimeByOffice', p); //导出各时段就餐情况
export const systemByUser = p => post('/home/report/systemByUser', p); //获取未实际就餐统计
export const exportSystemByUser = p => postBlob('/home/report/exportSystemByUser', p); //导出未实际就餐统计
export const firstDiscountByOffice = p => post('/home/report/firstDiscountByOffice', p); //获取首份优惠情况
export const getUserMealReport = p => post('/home/report/getUserMealReport', p); //获取人员就餐统计(带分页的用于页面展示的接口)
export const exportUserMealReport = p => postBlob('/home/report/exportUserMealReport', p); //获取人员就餐统计(不带分页的用于导出的接口)

//用餐人员管理
export const maxRank = p => get('/home/index/rank', p); //最大排序

export const hofficeIndex = p => get('/home/hoffice/index', p); //部门设置-列表
export const createOffice = p => post('/home/hoffice/create', p); //部门设置-添加部门
export const deleteOffice = (id, p) => post(`/home/hoffice/delete/${id}`, p); //部门设置-删除部门
export const updateOffice = (id, p) => post(`/home/hoffice/update/${id}`, p); //部门设置-编辑部门

export const hpostIndex = p => get('/home/hpost/index', p); //职务设置-列表
export const createPost = p => post('/home/hpost/create', p); //职务设置-添加职务
export const deletePost = (id, p) => post(`/home/hpost/delete/${id}`, p); //职务设置-删除职务
export const updatePost = (id, p) => post(`/home/hpost/update/${id}`, p); //职务设置-编辑职务


export const huserphoIndex = p => get('/home/huserpho/index', p); //免审核名单-列表
export const importWhiteList = p => postWithOutQS('/home/huserpho/importWhiteList', p); //免审核名单-批量添加白名单
export const WhiteListDownloadTemplate = p => postBlob('/home/huserpho/downloadTemplate', p); //免审核名单-白名单模板下载
export const huserphoCreate = p => post('/home/huserpho/create', p); //免审核名单-添加人员
export const huserphoUpdate = (id, p) => post(`/home/huserpho/update/${id}`, p); //免审核名单-编辑人员
export const huserphoDelete = (id, p) => post(`/home/huserpho/delete/${id}`, p); //免审核名单-删除人员
export const batchDeleteByIds = p => post(`/home/huserpho/batchDeleteByIds/`, p); //免审核名单-批量删除所选人员
export const batchDeleteAll = p => post(`/home/huserpho/batchDeleteAll`, p); //免审核名单-批量删除全部人员


export const huserIndex = p => get('/home/huser/index', p); //人员信息管理-列表
export const huserDownloadTemplate = p => getBlob('/home/huser/downloadTemplate', p); //人员信息管理-批量更新模板下载
export const huserUpdateBatch = p => postWithOutQS('/home/huser/updateBatch', p); //人员信息管理-批量更新用户
export const huserDownloadZip = p => get('/home/huser/downloadZip', p); //人员信息管理-批量更新头像模板下载
export const huserUploadUserPhotos = p => postWithOutQS('/home/huser/uploadUserPhotos', p); //人员信息管理-上传批量头像zip文件
export const huserUpdateUserPhotos = p => post('/home/huser/updateUserPhotos', p); //人员信息管理-批量更新用户头像
export const huserDelete = (id, p) => post(`/home/huser/delete/${id}`, p); //人员信息管理-删除用户
export const huserDetail = (id, p) => get(`/home/huser/update/${id}`, p); //人员信息管理-用户详情
export const huserUpdate = (id, p) => post(`/home/huser/update/${id}`, p); //人员信息管理-编辑用户
export const huserUpdatePic = p => postWithOutQS('/home/huser/upload', p); //人员信息管理-上传用户头像
export const happlyIndex = p => get('/home/happly/index', p); //人员信息管理-用户申请表
export const happlyUpdate = (id, p) => post(`/home/happly/update/${id}`, p); //人员信息管理-处理用户申请列表
export const happlyPassed = (id, p) => post(`/home/happly/happlyPassed`, p); //人员信息管理-一键通过全部待审核
export const happlyIndexAll = p => get('/home/index/users', p); //人员信息管理-用户列表（包含刚申请的用户）

export const getAddQrcode = () => post(`/home/qrcode/getInstitutionQrcode`); //人员信息管理-添加用餐人员之前要请求的接口
export const getInstitutionCode = () => post(`/home/qrcode/getInstitutionCode`); //人员信息管理-添加用餐人员-获取代理商食堂的加入食堂编码
export const getShareLink = () => get(`/home/qrcode/getShareLink`); //人员信息管理-添加用餐人员-通过微信邀请链接添加(获取链接)

//账户管理
export const accounts = p => post('/home/account/account', p); //账户充值-用户账户列表
export const manyRecharge = p => post('/home/account/manyRecharge', p); //账户充值-批量充值
export const manyAdjustment = p => post('/home/account/manyAdjustment', p); //账户充值-批量调整
export const accountDownloadTemplate = p => getBlob('/home/account/downloadTemplate', p); //账户充值-个性化充值模板下载
export const accountUploadPersonalized = p => postWithOutQS('/home/account/uploadPersonalized', p); //账户充值-上传个性化充值文件
export const updatePersonalized = p => post('/home/account/updatePersonalized', p); //账户充值-更新个性化批量充值
export const singleRecharge = p => post('/home/account/singleRecharge', p); //账户充值-单个充值
export const singleAdjustment = p => post(`/home/account/singleAdjustment`, p); //账户充值-单个调整

export const accountRecordList = p => post('/home/account/recordList', p); //账户收支记录列表

//用餐管理
export const suspension = p => get('/home/suspension/index', p); //不开餐日期-列表
export const suspensionCreate = p => post('/home/suspension/create', p); //不开餐日期-添加
export const suspensionDelete = id => post(`/home/suspension/delete/${id}`); //不开餐日期
export const weekplan = p => get('/home/index/inst/weekplan', p); //不开餐日期
export const suspensionUpdate = p => post('/home/suspension/update', p); //不开餐日期
export const getAheadDay = p => get('/home/index/inst/config', p); //不开餐日期
export const updateAheadDay = p => post('/home/index/maxMeal', p); //不开餐日期-添加


export const orderList = p => get('/home/order/list', p); //报餐明细-列表
export const orderCancel = (id, p) => post(`/home/order/cancel/${id}`, p); //报餐明细-取消报餐订单
export const getDetailReport = p => get('/home/order/getDetailReport', p); //报餐明细-导出九月份数据
export const getTempOrders = p => post(`/home/order/getTempOrders`, p); //报餐明细-用于有的用户需要导出旧区间数据
export const listOutsiderOrders = p => post(`/home/order/listOutsiderOrders`, p); //报餐明细-获取外部订单列表


export const dailyIndex = p => get('/home/daily/index', p); //每日菜谱-列表
export const dailyCreate = p => post('/home/daily/create', p); //每日菜谱-添加
export const dailyList = p => get('/home/daily/list', p); //每日菜谱-选择菜品页面
export const dailyDelete = (id, p) => post(`/home/daily/delete/${id}`, p); //每日菜谱-删除每日菜谱下的某个菜品
export const dailyUpdate = (id, p) => post(`/home/daily/update/${id}`, p); //每日菜谱-编辑每日菜谱下的某个菜品
export const dailyBatchUpdate = p => post(`/home/daily/multiple`, p); //每日菜谱-批量添加



export const menuTypesList = p => get('/home/index/menutype/list', p); //菜品库-菜品类别列表(用这个)
export const menuTypes = p => get('/home/menutype/index', p); //菜品库-菜品类别列表
export const menuTypeCreate = p => post('/home/menutype/create', p); //菜品库-添加菜品类别
export const menuTypeUpdate = (id, p) => post(`/home/menutype/update/${id}`, p); //菜品库-编辑菜品类别
export const menuTypeDelete = (id, p) => post(`/home/menutype/delete/${id}`, p); //菜品库-删除菜品类别
export const menuIndex = p => get('/home/menu/index', p); //菜品库-菜品列表
export const menuCreate = p => post('/home/menu/create', p); //菜品库-添加菜品
export const menuPicUpload = p => postWithOutQS('/home/menu/upload', p); //菜品库-菜品图片上传
export const menuDetail = id => get(`/home/menu/update/${id}`); //菜品库-单个菜品详情
export const menuUpdate = (id, p) => post(`/home/menu/update/${id}`, p); //菜品库-单个菜品编辑
export const menuDelete = id => post(`/home/menu/delete/${id}`); //菜品库-单个菜品删除

export const mealtimeList = p => get('/home/mealtime/index', p); //就餐时段-列表
export const batchMealtimeList = p => get('/home/mealtime/batch', p); //批量报餐时段-列表
export const mealtimeCreate = p => post('/home/mealtime/create', p); //就餐时段-添加
export const mealtimeUpdate = (id, p) => post(`/home/mealtime/update/${id}`, p); //就餐时段-编辑
export const mealtimeBatchUpdate = (id, p) => post(`/home/mealtime/updateBatch/${id}`, p); //就餐时段-编辑
export const mealtimeDelete = (id, p) => post(`/home/mealtime/delete/${id}`, p); //就餐时段-删除


export const getGoodsList = p => get('/home/goods/getGoodsList', p); //小卖部商品管理-列表
export const addGoods = p => post('/home/goods/addGoods', p); //小卖部商品管理-新增商品
export const getEditGoods = id => get(`/home/goods/editGoods/${id}`); //小卖部商品管理-编辑商品页面
export const editGoods = (id, p) => post(`/home/goods/editGoods/${id}`, p); //小卖部商品管理-编辑商品
export const delGoods = id => post(`/home/goods/delGoods/${id}`); //小卖部商品管理-删除商品
export const goodsUpload = p => postWithOutQS(`/home/goods/upload`, p); //小卖部商品管理-上传图片
export const getTypeList = p => get('/home/goods/getTypeList', p); //小卖部商品管理-商品分类列表
export const addType = p => post('/home/goods/addType', p); //小卖部商品管理-新增分类
export const editType = (id, p) => post(`/home/goods/editType/${id}`, p); //小卖部商品管理-编辑分类
export const delType = (id, p) => post(`/home/goods/delType/${id}`, p); //小卖部商品管理-删除分类

export const getGoodsOrder = p => post('/home/goods/getGoodsOrder', p); //小卖部订单管理-查询订单
export const cancelOrder = id => post(`/home/goods/cancelOrder/${id}`); //小卖部订单管理-取消订单
export const updateOrder = id => post(`/home/goods/updateOrder/${id}`); //小卖部订单管理-订单配送
export const getGoodsSales = p => post('/home/goods/getGoodsSales', p); //小卖部订单管理-统计商品销售量

export const getStatisticsDetail = p => get('/home/order/statistics/detail', p); //报餐统计-菜品
export const getStatisticsPerson = p => get('/home/order/statistics', p); //报餐统计-人次
export const getStatisticsUsers = p => get('/home/order/statistics/person', p); //报餐统计-人数

export const getConsumeQrcode = p => get('/home/qrcode/getConsumeQrcode', p); //消费二维码
export const addConsumeQrcode = p => post('/home/qrcode/addConsumeQrcode', p); //消费二维码
export const editConsumeQrcode = (id, p) => post(`/home/qrcode/editConsumeQrcode/${id}`, p); //消费二维码
export const delConsumeQrcode = id => post(`/home/qrcode/delConsumeQrcode/${id}`); //消费二维码
export const swichtQrcode = (id, p) => post(`/home/qrcode/switchQrcode/${id}`, p); //消费二维码

export const getEstimate = p => get('/home/estimate/index', p); //评价管理

export const getAntistop = p => get('/home/antistop/index', p); //关键词管理
export const addAntistop = p => post('/home/antistop/create', p); //关键词管理添加
export const updateAntistop = (id, p) => post(`/home/antistop/update/${id}`, p); //关键词管理修改
export const deleteAntistop = (id, p) => post(`/home/antistop/del/${id}`, p); //关键词管理删除

export const getSceneMealQrcode = p => post('/home/qrcode/getSceneMealQrcode', p); //现场点餐获取点餐码
export const getDeviceList = () => get('/home/qrcode/getPrinters'); //现场点餐-获取飞蛾列表
export const setSceneStatus = p => post('/home/infomanage/centrum/setScene', p); //设置现场点餐二维码开关
export const getSceneStatus = () => post('/home/infomanage/centrum/getScene'); //设置现场点餐二维码开关


//单位信息
export const getInstVersion = p => get('/home/infomanage/centrum/getInstVersion', p); //版本信息-获取使用的版本信息
export const getBuyVersion = p => post('/home/payment/getBuyVersion', p); //版本信息-购买/续费时版本选择页面
export const postAddInstitutionApply = p => post('/home/payment/payInstitutionVersion', p); //版本信息-购买/续费时生成订单
export const calcInstitutionVersion = p => post('/home/payment/calcInstitutionVersion', p); //版本信息-切换时版本选择页面
export const updateInstitutionVersion = p => post('/home/payment/updateInstitutionVersion', p); //版本信息-切换版本更新接口
/*
 *@date: 2021-02-04 10:02:07
*/
export const showProduction = p => post('/home/payment/showProduction', p); //版本信息-获取各版本列表
export const addShopCart = p => post('/home/payment/shopcart/addShopCart', p); //版本信息-添加到意向购买列表
export const showShopCart = p => post('/home/payment/shopcart/showShopCart', p); //版本信息-获取意向购买列表
export const removeShopCart = cart => post(`/home/payment/shopcart/removeShopCart/${cart}`); //版本信息-移除单个意向购买
export const generateOrder = p => post('/home/payment/order/generateOrder', p); //版本信息-从意向购买列表中生成待支付订单
export const payOrder = p => post('/home/payment/order/payOrder', p); //版本信息-选择支付方式
export const quickOrder = p => post('/home/payment/order/quickOrder', p); //版本信息-快速下单
export const getOrder = p => post('/home/payment/order/getOrder', p); //版本信息-订单列表
export const getOrderList = p => post('/home/payment/order/getOrderList', p); //版本信息-订单列表
export const removeOrder = id => post(`/home/payment/order/removeOrder/${id}`); //版本信息-移除待支付订单
export const getApply = id => get(`/home/invoice/apply/${id}`); //获取发票信息
export const updateApply = (id, p) => post(`/home/invoice/apply/${id}`, p); //提交发票信息
export const zeroPay = p => post(`/home/payment/order/zeroPay`, p); //零元购买选项



//系统设置
export const notices = p => get('/home/notice/getNoticeList', p); //公告管理
export const disableNotice = (id, p) => post(`/home/notice/disableNotice/${id}`, p); //公告启用禁用
export const noticePicUpload = p => postWithOutQS('/home/notice/upload', p); //菜品库-菜品图片上传
export const addNotice = p => post('/home/notice/addNotice', p); //公告管理
export const getEditNotice = (id, p) => get(`/home/notice/editNotice/${id}`, p); //公告管理
export const editNotice = (id, p) => post(`/home/notice/editNotice/${id}`, p); //公告管理
export const removeNotice = id => post(`/home/notice/delNotice/${id}`); //公告管理

//幻灯片管理
export const ads = p => get('/home/ad/getAdList', p); //幻灯片管理
export const disableAd = (id, p) => post(`/home/ad/disableAd/${id}`, p); //幻灯片启用禁用
export const addAd = p => post('/home/ad/addAd', p); //公告管理
export const getEditAd = (id, p) => get(`/home/ad/editAd/${id}`, p); //公告管理
export const editAd = (id, p) => post(`/home/ad/editAd/${id}`, p); //公告管理
export const removeAd = id => post(`/home/ad/delAd/${id}`); //公告管理

//菜品图片上传
export const menus = p => get('/home/menu/getMenuList', p); //幻灯片管理
export const getEditMenu = () => get(`/home/menu/editMenu`); //幻灯片启用禁用
export const editMenu = (p) => post(`/home/menu/editMenu`, p); //公告管理
export const removeMenu = () => post(`/home/menu/delMenu`); //公告管理

//单位管理
export const sendSmsCode = p => post(`/home/vercode/getVerificationCode`, p); //公告管理
export const checkSmsCode = p => post(`/home/vercode/checkVerificationCode`, p); //公告管理
export const updateInstMobile = p => post(`/home/infomanage/centrum/updateInstitutionMobile`, p); //公告管理
export const editInstInfo = p => post(`/home/infomanage/centrum/updateInstitutionInfo`, p); //公告管理

//管理员分配
export const instAdmins = p => get(`/home/haccount/index`, p); //公告管理
export const authorities = () => get(`/home/haccount/create`); //公告管理
export const addInstAdmin = p => post(`/home/haccount/create`, p); //公告管理
export const getEditInstAdmin = id => get(`/home/haccount/update/${id}`); //公告管理
export const editInstAdmin = (id, p) => post(`/home/haccount/update/${id}`, p); //公告管理
export const disableInstAdmin = (p) => post(`/home/haccount/disable`, p); //公告管理
export const removeInstAdmin = id => post(`/home/haccount/delete/${id}`); //公告管理
export const instGetName = p => post(`/home/haccount/getName`, p); 

//待报餐管理
export const autoMealRules = p => get(`/home/index/rule/list`, p); //公告管理
export const disableAutoMealRule = (id, p) => post(`/home/index/rule/update/${id}`, p); //公告管理
export const autoMealMenus = p => get(`/home/index/rule/menu`, p); //公告管理
export const autoMealUsers = p => get(`/home/index/rule/user`, p); //公告管理
export const addAutoMealRule = p => post(`/home/menu/multiple`, p); //公告管理
export const removeAutoMealRule = (id) => post(`/home/menu/multiple/del/${id}`); //公告管理

//审核账号管理
export const auditAdmins = p => get(`/home/audit/getAuditList`, p); //公告管理
export const editAuditAdmin = p => post(`/home/audit/editAudit`, p); //公告管理
export const addAuditAdmin = p => post(`/home/audit/addAudit`, p); //公告管理
export const removeAuditAdmin = (id) => post(`/home/audit/delAudit/${id}`); //公告管理

//模式切换
export const getInstProInfo = () => get(`/home/frog/getInstitutionPro`); //公告管理
export const setVoice = (p) => post(`/home/frog/setVoice`, p); //公告管理
export const instConfig = (p) => post(`/home/infomanage/centrum/setInstitution`, p); //公告管理
export const editRemindMoney = (p) => post(`/home/remind/edit`, p); //修改提醒金额
export const getRemindMoney = () => get(`/home/remind/index`); //获取提醒金额 
export const setJumpDetail = (p) => post(`/home/infomanage/centrum/setJumpDetail`, p); //青蛙核销后是否能跳转详情

// 模块管理
export const getModuleList = p => get(`/home/module/index`, p); //模块列表
export const switchModule = p=> post(`/home/module/close`, p); //开启/关闭模块
export const getOptionList = (id, p) => get(`/home/option/index/${id}`, p); //获取模块下的选项列表
export const sendVerificationCode = () => post(`/home/module/message`); //切换版本时发送验证码
export const switchVersionInfo = p => post(`/home/module/switchInfo`, p); //切换版本时获取版本差异
export const switchVersion = p => post(`/home/module/switch`, p); //切换版本
export const switchOption = id => post(`/home/option/switch/${id}`); //切换选项

// 问卷
export const addQuestionnaire = (p) => post(`/home/questionnaire/store`, p); //添加问卷
export const updateQuestionnaire = (id, p) => post(`/home/questionnaire/update/${id}`, p); //修改问卷
export const totalQuestionnaire = (id, p) => get(`/home/questionnaire/show/${id}`, p); //问卷统计
export const getQuestionnaires = p => get(`/home/questionnaire/index`, p); //问卷列表
export const pushQuestionnaire = (id, p) => post(`/home/questionnaire/push/${id}`, p); //发布问卷
export const deleteQuestionnaire = id => post(`/home/questionnaire/delete/${id}`); //删除问卷
export const copyQuestionnaire = id => post(`/home/questionnaire/copy/${id}`); //复制问卷
export const exportQuestionnaire = id => postBlob(`/home/questionnaire/export/${id}`);//导出问卷
export const getQuestions = id => get(`/home/questionnaire/topic/index/${id}`); //问卷下的问题列表
export const addQuestion = (p) => post(`/home/questionnaire/topic/store`, p); //添加问题
export const updateQuestion = (id, p) => post(`/home/questionnaire/topic/update/${id}`, p); //修改问题
export const getQuestion = id => get(`/home/questionnaire/topic/edit/${id}`); //获取所修改的问题详情
export const deleteQuestion = id => post(`/home/questionnaire/topic/delete/${id}`); //删除问题

//版本升降配
export const getModules = (p) => post(`/home/version/info/getModules`, p);//获取在售模块
export const getVersionPacket = (p) => post(`/home/version/info/getVersionPacket`, p);//获取预置版本(包)
export const getInstVersionInfo = (p) => post(`/home/version/info/getInstVersion`, p);//获取当前食堂配置信息
export const getVersionPrice = (p) => post(`/home/version/info/getVersionPrice`, p);//获取变更后的价格
export const getPolicy = (p) => post(`/home/version/info/getPolicy`, p);//获取隐私政策,用户协议等
export const getCoupon = (p) => post(`/home/version/info/getCoupon`, p);//获取可用优惠券

//服务订单
export const getServiceModule = (p) => post(`/home/service/getModules`, p);//获取可购服务
export const payServiceOrder = (p) => post(`/home/service/payOrder`, p);
export const getServiceOrder = (p) => post(`/home/service/getServiceOrder`, p);
export const useService = (p) => post(`/home/service/useService`, p);

//优惠券
export const getInstCoupon = (p) => post(`/home/coupon/getInstCoupon`, p);

//客服
export const getCustomerService = (p) => get(`/home/psupport/exclusive/getCustomerService`, p);


//客餐
export const getGuestMeal = (p) => get(`/home/guest_meal/index`, p);//客餐列表
export const postGuestMealCreate = (p) => post(`/home/guest_meal/create`, p);//新增客餐
export const postGuestMealUpdate = (id, p) => post(`/home/guest_meal/update/${id}`, p); //修改客餐
export const postGuestMealDelete = (id, p) => post(`/home/guest_meal/delete/${id}`, p); //删除客餐
export const getGuestMealOrder = (p) => get(`/home/guest_meal_order/list`, p);//客餐记录
export const getGuestMealOrderStat = (p) => get(`/home/guest_meal_order/stat`, p);//客餐统计