/*
 * @Description: 
 * @Author: Chen Bi Qi
 * @Date: 2020-09-15 15:03:49
 * @LastEditTime: 2020-09-20 05:09:48
 */
import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuex from 'vuex' //引入状态管理
import "@babel/polyfill";
import BigNumber from 'bignumber.js'




//按需引入ant及样式
import {
  Button,
  DatePicker,
  FormModel,
  Input,
  Icon,
  message,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  Layout,
  Breadcrumb,
  PageHeader,
  ConfigProvider,
  Table,
  Divider,
  Select,
  Upload,
  Avatar,
  notification,
  Checkbox,
  Radio,
  Card,
  TimePicker,
  Switch,
  Tree,
  Tabs,
  Tooltip,
  Spin,
  Slider,
  InputNumber,
  Popover
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import Vue2Editor from "vue2-editor";
import Distpicker from 'v-distpicker';
import ScrollLoader from 'vue-scroll-loader';

import VueClipboard  from 'vue-clipboard2';
Vue.use(VueClipboard)
Vue.component('v-distpicker', Distpicker)
Vue.component(VeIndex.VePie.name, VeIndex.VePie)
Vue.config.productionTip = false;

Vue.use(Button)
Vue.use(DatePicker);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Layout);
Vue.use(Breadcrumb);
Vue.use(PageHeader);
Vue.use(ConfigProvider);
Vue.use(Table);
Vue.use(Divider);
Vue.use(Select);
Vue.use(Upload);
Vue.use(Avatar);
Vue.use(Checkbox);
Vue.use(Tooltip)

Vue.use(Radio)
Vue.use(Switch)
Vue.use(Tree)
Vue.use(Tabs)
Vue.use(Radio);
Vue.use(Card);
Vue.use(TimePicker);
Vue.use(Spin);
Vue.use(ScrollLoader)
Vue.use(Slider)
Vue.use(InputNumber)
Vue.use(Popover)

Vue.use(Vuex);
Vue.use(Vue2Editor);
Vue.prototype.$message = message; //message不能使用use
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;

Vue.prototype.$notification = notification

Vue.prototype.$BigNumber = BigNumber
// Vue.prototype.realName = '';
Vue.prototype.global = {
  userInfo: {},
  offices: [],
  posts: []
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')