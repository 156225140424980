<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" :selects="selects" @search="search" @reset="search" />
      </div>

      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="ahead_day" slot-scope="record">
            {{ `${record.ahead_day}天前${record.close_time}`}}
          </template>
          <template slot="msg_send_time" slot-scope="record">
            {{ record.send_status == 0 ? "-" : record.msg_send_time }}
          </template>
          <template slot="send_status" slot-scope="record">
            {{ record.send_status == 0 ? "关闭" : "开启" }}
          </template>
          <template slot="is_valid" slot-scope="record">
            {{ record.is_valid == 0 ? "禁用" : "开启" }}
          </template>
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="showAddOffice(record)">编辑</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal v-model="visible" :title="addOffice ? '添加用餐时段' : '编辑用餐时段'" @ok="handleSure" okText="保存">
      <a-form-model :model="addParam" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="标题">
          <a-input v-model="addParam.title" placeholder="请输入时段名称" />
        </a-form-model-item>
        <a-form-model-item label="时段可报份数">
          <a-input v-model="addParam.upper_count" placeholder="请输入时段可报份数" />
          <span>每人每天该时段可报份数上限1</span>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select v-model="addParam.is_valid">
            <a-select-option :value="1">开启</a-select-option>
            <a-select-option :value="0">关闭</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="开始时间">
          <a-time-picker :allowClear="false" format="HH:mm" :value="repast_begin" @change="changeRepastBegin"
            placeholder="用餐开始时间" />
        </a-form-model-item>
        <a-form-model-item label="结束时间">
          <a-time-picker :allowClear="false" format="HH:mm" :value="repast_end" @change="changeRepastEnd"
            placeholder="用餐截止时间" />
        </a-form-model-item>

        <a-form-model-item label="价格" v-if="momey_status == 1">
          <a-input v-model="addParam.price" placeholder="请输入每份价格" />
        </a-form-model-item>

        <a-form-model-item label="报餐提醒">
          <a-select v-model="addParam.send_status">
            <a-select-option :value="1">开启</a-select-option>
            <a-select-option :value="0">关闭</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="addParam.send_status == 1" label="提醒时间">
          <a-time-picker :allowClear="false" format="HH:mm" :value="msg_send_time" @change="changeMsgSendTime"
            placeholder="提醒时间" :minute-step="10" />
        </a-form-model-item>
        <a-form-model-item label="报餐截止时间">
          <a-input style="width: 50px" v-model="addParam.ahead_day" />天前
          <a-time-picker :allowClear="false" format="HH:mm" :value="close_time" @change="changeCloseTime"
            placeholder="截止时间" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  batchMealtimeList,
  mealtimeCreate,
  mealtimeDelete,
  mealtimeBatchUpdate,
  checkModulePower
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
import util from "@/utils/util";

import moment from "moment";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐人员管理",
        },
        {
          key: 1,
          title: "用餐时段设置",
        },
      ],
      inputs: [
        {
          placeholder: "请输入时段名称",
          model: "title",
          width: 200,
        },
      ],
      //传给搜索组件的参数
      selects: [
        {
          model: "is_valid",
          placeholder: "全部",
          width: 100,
          options: [
            {
              key: "",
              value: "全部",
            },
            {
              key: 0,
              value: "禁用",
            },
            {
              key: 1,
              value: "开启",
            },
          ],
        },
      ],

      //请求参数
      queryParam: {
        page: 1,
        is_valid: "",
        title: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "标题",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "可报份数",
          key: "upper_count",
          dataIndex: "upper_count",
        },
        {
          title: "状态",
          key: "is_valid",
          scopedSlots: { customRender: "is_valid" },
        },
        {
          title: "开始时间",
          key: "repast_begin",
          dataIndex: "repast_begin",
        },
        {
          title: "结束时间",
          key: "repast_end",
          dataIndex: "repast_end",
        },
        {
          title: "报餐提醒",
          key: "send_status",
          scopedSlots: { customRender: "send_status" },
        },
        {
          title: "提醒时间",
          key: "msg_send_time",
          scopedSlots: { customRender: "msg_send_time" },
        },
        {
          title: "报餐截止时间",
          key: "ahead_day",
          scopedSlots: { customRender: "ahead_day" },
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },

      visible: false,
      addOffice: false,
      addId: "",
      repast_begin: undefined, //开始时间
      repast_end: undefined, //结束时间
      close_time: undefined, //报餐截止时间
      msg_send_time: undefined, //提醒时间
      //更新参数
      addParam: {
        title: "",
        price: "0.00",
        ahead_day: 0, //报餐截止时间提前天数
        is_valid: 1,
        upper_count: 2, //份数限制
        repast_begin: "00:00", //开始时间
        repast_end: "23:59", //结束时间
        close_time: "00:00", //报餐截止时间
        send_status: 0, //开餐提醒
        msg_send_time: "00:00", //提醒时间
      },
      openAccount:true,
      momey_status:JSON.parse(localStorage.getItem('inst')).money_status
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        is_valid: val.select.is_valid === "" ? "" : val.select.is_valid,
        title: val.input.title ? val.input.title : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      
      this.loading = true;
      this.queryParam.no_paging = 0;
      batchMealtimeList(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data;
        this.loading = false;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //显示添加模态框
    showAddOffice (record) {
      if (record == "add") {
        this.addOffice = true;
        this.addParam = {
          title: "",
          price: "",
          ahead_day: 0, //报餐截止时间提前天数
          is_valid: 1,
          upper_count: 999, //份数限制
          repast_begin: "00:00", //开始时间
          repast_end: "23:59", //结束时间
          close_time: "00:00", //报餐截止时间
          send_status: 0, //开餐提醒
          msg_send_time: "00:00", //提醒时间
        };
        this.repast_begin = undefined; //开始时间
        this.repast_end = undefined; //结束时间
        this.close_time = undefined; //报餐截止时间
        this.msg_send_time = undefined; //提醒时间
      } else {
        this.addOffice = false;
        this.addId = record.id;
        this.addParam = {
          title: record.title,
          price: record.price,
          ahead_day: record.ahead_day, //报餐截止时间提前天数
          is_valid: record.is_valid,
          upper_count: record.upper_count, //份数限制
          repast_begin: record.repast_begin, //开始时间
          repast_end: record.repast_end, //结束时间
          close_time: record.close_time, //报餐截止时间
          send_status: record.send_status, //开餐提醒
          msg_send_time: record.msg_send_time, //提醒时间
        };
        this.repast_begin = moment(record.repast_begin, "HH:mm"); //开始时间
        this.repast_end = moment(record.repast_end, "HH:mm"); //结束时间
        this.close_time = moment(record.close_time, "HH:mm"); //报餐截止时间
        this.msg_send_time = (record.msg_send_time == null || record.msg_send_time == 'Invalid date') ? moment("00:00", "HH:mm") : moment(record.msg_send_time, "HH:mm"); //提醒时间
      }

      this.visible = true;
    },

    //提交表单
    handleSure: util.DebounceBy(function () {
      if (this.addParam.title === "") {
        this.$message.info("请输入时段名称");
        return;
      }
      if (this.addOffice) {
        mealtimeCreate(this.addParam)
          .then((res) => { })
          .catch((err) => {
            if (err.status == 201) {
              this.$message.success("添加成功");
              this.visible = false;
              this.queryParam.page = 1;
              this.pagination.current = 1;
              this.getTableData();
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      } else {
        this.$confirm({
          content: "若该时段下有报餐订单，修改报餐时间系统将对当天待就餐的订单进行自动退餐，确认修改？",
          onOk: () => {
            mealtimeBatchUpdate(this.addId, this.addParam)
              .then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              })
              .catch((err) => {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              });
          },
        });
      }
    }, 3000),

    //删除部门
    deleteFn (record) {
      this.$confirm({
        title: `是否删除时段${record.title}？`,
        content: `若该时段下有报餐订单，系统将进行自动退餐，请仔细核对用餐时段内容是否删除`,
        onOk: () => {
          mealtimeDelete(record.id, { before_del: 1 })
            .then((res) => {
              if (res.data.msg == false) {
                this.$confirm({
                  content: "该时段下还有待就餐的订单，确定删除？",
                  onOk: () => {
                    mealtimeDelete(record.id)
                      .then((res) => { })
                      .catch((err) => {
                        if (err.status == 204) {
                          this.$message.success("删除成功");
                          // this.queryParam.page = 1;
                          // this.pagination.current = 1;
                          this.getTableData();
                        } else {
                          this.$message.error("删除失败");
                        }
                      });
                  },
                });
              } else {
                mealtimeDelete(record.id)
                  .then((res) => { })
                  .catch((err) => {
                    if (err.status == 204) {
                      this.$message.success("删除成功");
                      this.queryParam.page = 1;
                      this.pagination.current = 1;
                      this.getTableData();
                    } else {
                      this.$message.error("删除失败");
                    }
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },

    changeRepastBegin (val, str) {
      this.repast_begin = val;
      this.addParam.repast_begin = str;
    },
    changeRepastEnd (val, str) {
      this.repast_end = val;
      this.addParam.repast_end = str;
    },
    changeMsgSendTime (val, str) {
      this.msg_send_time = val;
      this.addParam.msg_send_time = str;
    },
    changeCloseTime (val, str) {
      this.close_time = val;
      this.addParam.close_time = str;
    },
    checkPower(){
      
      let parames = {module:'account'}

      checkModulePower(this.addParam)
      .then((res) => { })
      .catch((err) => {
        if (err.status == 201) {
          this.$message.success("添加成功");
          this.visible = false;
          this.queryParam.page = 1;
          this.pagination.current = 1;
          this.getTableData();
        } else {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        }
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
    console.log( JSON.parse(localStorage.getItem('inst')).money_status );
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>