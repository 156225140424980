<template>
    <div class="container">
        <div style="display: flex;justify-content: space-between;">
            <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
                <a-breadcrumb-item>已购买服务</a-breadcrumb-item>
            </a-breadcrumb>
            <a-button class="ant-btn-primary" style="margin-top: 10px;" @click="goOrderList">订单与发票</a-button>
        </div>
        <div class="mainBox">
  
            <a-table :columns="columns" :data-source="data">
                
                <div slot="description" slot-scope="text, record">
                    <span v-html="text"></span>
                    <a href="javascript:;" @click="showInfo(record.description)">...详细</a>
                </div>

                <span slot="action" slot-scope="text, record">
                    <a class="btn_use" @click="useService(record.key)" v-if="record.btn_status == 1">使用</a>
                    <a class="btn_unknow" href="javascript:;" v-else>--</a>
                </span>
            </a-table>
       
        </div>

        <a-modal v-model="showDetails" title="服务范围" :footer="null" :width="500">
            <div v-html="content"></div>
        </a-modal>

    </div>
  </template>
  
  <script>
    const columns = [
    {
        title: '编号',
        dataIndex: 'order_number',
        key: 'order_number',
    },
    {
        title: '服务类型',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: '有效期',
        key: 'exp_time',
        dataIndex: 'exp_time',
    },
    {
        title: '服务范围',
        key: 'content',
        dataIndex: 'content',
        scopedSlots: { customRender: 'description' },
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
    },
    ];

    const data = [
   
    ];
    import {getServiceOrder,useService} from "@/request/api";
  
    export default {
        //import引入的组件需要注入到对象中才能使用
        components: {  },
        data () {
            return {
                data,
                columns,
                showDetails:false,
                content:''
            };
        },
        //监听属性 类似于data概念 
        computed: {},
        //监控data中的数据变化
        watch: {},
        //方法集合
        methods: {
            goOrderList(){
                this.$router.push({
                    path: "/OrderDetails",
                });
                this.$store.commit('UPDATE_SELECTEDKEYS',['/OrderDetails'])
            },
            showInfo(e){
                this.content = e;
                this.showDetails = true;
            },
            async getServiceOrder(){
                let parames = {};
                let that = this;
                getServiceOrder(parames).then((res) => {
                    that.data = res.data.data;
                    
                })
                .catch((err) => {
                    that.$message.error(err.data.message);
                });
            },
            async useService(id){
                let parames = {
                    id:id
                };
                let that = this;
                useService(parames).then((res) => {
                    that.$message.success(res.data.message);
                    console.log(res)
                    that.getServiceOrder();
                })
                .catch((err) => {
                    that.$message.error(err.data.message);
                });
            }
        },
        //生命周期 - 创建完成（可以访问当前this实例）
        created () {
            this.getServiceOrder();
        },
        //生命周期 - 挂载完成（可以访问DOM元素）
        mounted () { },
        beforeCreate () { }, //生命周期 - 创建之前
        beforeMount () { }, //生命周期 - 挂载之前
        beforeUpdate () { }, //生命周期 - 更新之前
        updated () { }, //生命周期 - 更新之后
        beforeDestroy () { }, //生命周期 - 销毁之前
        destroyed () { }, //生命周期 - 销毁完成
        activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
  //@import url(); 引入公共css类
.mainBox {

    .btn_use{
        color: rgba(16, 142, 233, 1);
    }
    .btn_unknow{
        color: #3d3d3d;
        text-align: center;
        cursor:default;
    }
}
</style>