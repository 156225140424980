<template>
  <div class="calendar">
    <!-- 当前月份及切换按钮 -->
    <div class="header">
      <div :class="removeClick ? 'pe_none button_left' : 'button_left'" @click="preMonth"><</div>
      <div class="now_date">{{nowMonth}}</div>
      <div :class="removeClick ? 'pe_none button_right' : 'button_right'" @click="nextMonth">></div>
    </div>
    <!-- 星期 -->
    <div class="week_days">
      <div class="item" v-for="item in weekDays" :key="item.value">
        {{ item.label }}
      </div>
    </div>
    <!-- 日 -->
    <div class="month_days">
      <div :class="item.disabled ? 'item disabled' : item.choose ? (removeClick ? 'item choose pe_none' : 'item abled choose') : (removeClick ? 'item pe_none' : 'item abled')" v-for="(item,index) in monthDays" :key="index" @click="clickDay(index)">
        <div :class="item.mealArr && item.mealArr.length > 0 ? 'have_meal' : ''">
          {{item.value == 'kong' ? '' : item.value}}
        </div>
        <div v-if="item.mealArr && item.mealArr.length > 0" class="number_tip">
          {{item.mealArr.length}}
        </div>
        <div v-if="item.mealArr && item.mealArr.length > 0" class="check_detail" @click.stop="checkDetail(item)">
          查看
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      weekDays: [
        {
          value: 0,
          label: "日",
        },
        {
          value: 1,
          label: "一",
        },
        {
          value: 2,
          label: "二",
        },
        {
          value: 3,
          label: "三",
        },
        {
          value: 4,
          label: "四",
        },
        {
          value: 5,
          label: "五",
        },
        {
          value: 6,
          label: "六",
        },
      ],
      nowMonth:moment().format("YYYY-MM"),//当前月份
      monthDays:[],//当月数据

    };
  },
  watch: {
    calendarOption: {
      handler:function(newValue, oldValue) {
        this.initMonthDays(newValue)
      },
      deep: true
    },
    removeClick: {
      handler: function(newValue, oldValue) {
        this.removeClick = newValue
      },
    }
  },
  props: ["calendarOption", 'removeClick'],
  computed: {},
  components: {},
  created() {
    this.initMonthDays()
  },
  mounted() {},
  methods: {
    moment,
    initMonthDays(option){
      let daysInMonth = moment(this.nowMonth).daysInMonth(),//传入月份的总天数
          firstDayOfMonth = moment(this.nowMonth).date(1).day(),//传入月份的1号是星期几(0-6对应星期日~六)
          nowDate = moment().format('YYYY-MM-DD'),//当前日期
          monthDays = [];
      // 配置每个日期
      for(let i = 0; i < daysInMonth; i++){
        let day = {
          value: i + 1,
          dateStr: moment(this.nowMonth).date(i + 1).format('YYYY-MM-DD'),
          disabled: moment(this.nowMonth).date(i + 1).isBefore(nowDate) || moment(this.nowMonth).date(i + 1).isAfter(moment(nowDate).add(30, 'days')) ? true : false,
          choose:false,
          mealArr:[],
          month:this.nowMonth
        }
        monthDays.push(day)
      }

      // 如果1号不是周日，需要往数组前添加对应的空日期标记，根据标记排版
      if(firstDayOfMonth != 0){
        // 添加空日期的规则是：1号是周几就添加几个标记
        for(let i = 0; i < firstDayOfMonth; i++){
          monthDays.unshift({
            disabled:true,
            value:'kong'
          })
        }
      }

      if(option && option.calendarData && option.calendarData.length > 0){
        let optionMonthIndex = option.calendarData.findIndex(item => { return item.month == this.nowMonth });
        
        if(optionMonthIndex != -1){
          let cuOptionMonth = option.calendarData[optionMonthIndex],
              cuDaysInMonth = cuOptionMonth.daysInMonth;

          for (let index = 0; index < cuDaysInMonth.length; index++) {
            let day = cuDaysInMonth[index]; 
            for (let j = 0; j < monthDays.length; j++) {
              let calDay = monthDays[j];
              if(calDay.dateStr == day.dateStr){
                calDay.mealArr = day.mealArr
              }
            }
          }

        }
      } 
      this.monthDays = monthDays;
    },

    // 上个月
    preMonth(){
      this.nowMonth = moment(this.nowMonth).subtract(1,'months').format('YYYY-MM')
      if(this.calendarOption.calendarData && this.calendarOption.calendarData.length > 0){
        this.initMonthDays(this.calendarOption)
      }else{
        this.initMonthDays()
      }
      this.$emit('preMonth','上个月')
    },

    // 下个月
    nextMonth(){
      this.nowMonth = moment(this.nowMonth).add(1,'months').format('YYYY-MM')
      if(this.calendarOption.calendarData && this.calendarOption.calendarData.length > 0){
        this.initMonthDays(this.calendarOption)
      }else{
        this.initMonthDays()
      }
      this.$emit('nextMonth','下个月')
    },

    // 每天的点击事件
    clickDay(index){
      // if(this.monthDays[index].mealArr.length > 0) return //有菜品的日期不能选中
      this.monthDays[index].choose = !this.monthDays[index].choose
      
      let chooseDays = [];
      for(let i in this.monthDays){
        if(this.monthDays[i].choose){
          chooseDays.push(this.monthDays[i])
        }
      }

      this.$emit('myClickDay',chooseDays)
    },

    // 查看所选日期绑定的数据
    checkDetail(day){
      this.$emit('myCheckDetail',day)
    }
  },
};
</script>
<style lang='less' scoped>
.calendar {
  width: 500px;
  text-align: center;
  -o-user-select: none;
  -moz-user-select: none; /*火狐 firefox*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10+*/
  -khtml-user-select :none; /*早期的浏览器*/
  user-select: none; 
  .header {
    width: 240px;
    margin: 0 auto;
    display: flex;
    .button_left,
    .button_right {
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-weight: bold;
    }
    .button_left:hover,
    .button_right:hover {
      border: 1px solid lightskyblue;
      cursor: pointer;
    }
    .now_date {
      width: 120px;
      height: 60px;
      line-height: 60px;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .week_days {
    display: flex;
    justify-content: flex-start;
    .item {
      width: 70px;
      height: 70px;
      line-height: 70px;
      // cursor: pointer;
      font-weight: bold;
    }
    // .item:hover {
    //   border: 1px solid lightskyblue;
    // }
  }
  .month_days{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item {
      height: 60px;
      line-height: 40px;
      flex: 0 0 14%;
      position: relative;
      .have_meal{
        background-color: #ff833d;
        color: white;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        margin: 5px auto;
      }
      .number_tip{
        color: white;
        background-color: red;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 10px;
      }
      .check_detail{
        color: white;
        background-color: #23bfff;
        width: 40px;
        margin: 0 auto;
        height: 15px;
        line-height: 15px;
        text-align: center;
        border-radius: 20px;
        font-size: 12px;
      }
    }
    .item.abled{
      cursor: pointer;
    }
    .item.choose.pe_none{
      border: 1px solid lightskyblue;
    }
    .item.abled.choose{
      border: 1px solid lightskyblue;
    }
    .item.abled:hover {
      border: 1px solid lightskyblue;
    }
    .item.disabled {
      color:lightgray;
      pointer-events: none;
    }
  }
}
.pe_none{//禁止点击事件的class
  pointer-events: none;
}
</style>
