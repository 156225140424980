<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 :timeRange="timeRange"-->
      <div class="control_box">
        <Search-bar
          :timeRange="timeRange"
          :inputs="inputs"
          :selects="selects"
          :dateTime="dateTime"
          @search="search"
          @reset="search"
        />
        <div class="control_box_bottom">
          <a-button
            v-if="!reGetExportData"
            type="primary"
            :icon="ExportLoading ? 'loading' : 'printer'"
            @click="downloadExcel"
            :disabled="ExportLoading"
            >{{ ExportLoading ? "加载中.." : "导出" }}</a-button
          >
          <a-button v-else @click="getExportData">重新获取导出数据</a-button>
          <!-- <a-button type="primary" icon="printer" @click="DownloadDetailReport" style="margin-left: 10px">导出十月份报表
          </a-button> -->
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :loading="loading"
          :rowKey="(tableData) => tableData.id"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <span slot="mealType" slot-scope="record">
            {{
              record.data_from == "order"
                ? "点餐"
                : record.data_from == "meal"
                ? "套餐"
                : ""
            }}
          </span>
          <span slot="mealStatus" slot-scope="record">
            {{
              record.status == 1
                ? "待就餐"
                : record.status == 2
                ? "已就餐"
                : record.status == 3
                ? "已取消"
                : ""
            }}
          </span>
          <span slot="checkFn" slot-scope="record">
            {{
              record.auto_check == 1
                ? "系统验证"
                : record.auto_check == 2
                ? "用户验证"
                : record.auto_check == 3
                ? "食堂验证"
                : record.auto_check == 4
                ? "闸机扫码"
                : record.auto_check == 5
                ? "人脸验证"
                : ""
            }}
          </span>
          <span slot="updatedTime" slot-scope="record">
            {{ record.status == 2 ? record.updated_at : "未核销" }}
          </span>
          <template slot="action" slot-scope="record">
            <a-button
              :disabled="record.status == 3"
              type="link"
              @click="deleteType(record)"
            >退款</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal v-model="visible" title="取消报餐" @ok="handleCancleMeal">
      <a-form-model
        :model="cancleParam"
        :rules="cancleRules"
        ref="cancleForm"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="审核人账号" prop="auditor">
          <a-input v-model="cancleParam.auditor" placeholder="审核人账号" />
        </a-form-model-item>
        <a-form-model-item label="审核人密码" prop="password">
          <a-input
            type="password"
            v-model="cancleParam.password"
            placeholder="审核人密码"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  orderList,
  mealtimeListWithout,
  officesWithout,
  postsWithout,
  getOnline,
  getAudit,
  orderCancel,
  getDetailReport,
  getTempOrders, //用于导出旧区间的接口（自用，不开放到线上）
  userLog,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
import moment from "moment";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data() {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "用餐管理",
        },
        {
          key: 1,
          title: "报餐明细",
        },
      ],
      inputs: [
        {
          placeholder: "姓名",
          model: "name",
          width: 100,
        },
        {
          placeholder: "手机号码",
          model: "mobile",
          width: 100,
        },
      ],
      selects: [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
        {
          model: "meal_id",
          placeholder: "用餐时段",
          width: 100,
          options: [
            {
              key: "",
              value: "用餐时段",
            },
          ],
        },
        {
          model: "status",
          placeholder: "就餐状态",
          width: 100,
          options: [
            {
              key: "",
              value: "就餐状态",
            },
            {
              key: 1,
              value: "待就餐",
            },
            {
              key: 2,
              value: "已就餐",
            },
            {
              key: 3,
              value: "已取消",
            },
          ],
        },
      ],
      dateTime: {
        show: true,
      },
      timeRange: {
        show: true,
        placeholderStart: "开始时间",
        placeholderEnd: "结束时间",
        rangeLimit: true,
        disabledBeforeNov: true,
      },
      queryParam: {
        page: 1,
        repast_date: "",
        status: "",
        meal_id: "",
        post_id: "",
        office_id: "",
        name: "",
        mobile: "",
        is_old: "",
        begin_date: "",
        end_date: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [],
      loading: false,
      ExportLoading: false,
      reGetExportData: false,
      columns: [
        {
          title: "用餐时段",
          key: "meal",
          dataIndex: "meal",
        },
        {
          title: "部门",
          key: "office",
          dataIndex: "office",
        },
        {
          title: "职务",
          key: "post",
          dataIndex: "post",
        },
        {
          title: "用餐类型",
          key: "data_from",
          scopedSlots: { customRender: "mealType" },
        },
        {
          title: "订单明细",
          key: "orderDetails",
          dataIndex: "orderDetails",
        },

        {
          title: "金额",
          key: "money",
          dataIndex: "money",
        },
        {
          title: "姓名",
          key: "realname",
          dataIndex: "realname",
        },
        {
          title: "手机",
          key: "mobile",
          dataIndex: "mobile",
        },
        {
          title: "用餐日期",
          key: "repast_date",
          dataIndex: "repast_date",
        },
        //备注字段无返回
        {
          title: "用餐状态",
          key: "status",
          scopedSlots: { customRender: "mealStatus" },
        },
        {
          title: "验证方式",
          key: "auto_check",
          scopedSlots: { customRender: "checkFn" },
        },
        {
          title: "报餐时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "核销时间",
          key: "updated_at",
          scopedSlots: { customRender: "updatedTime" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ], // 表格的列配置
      pagination: {
        // total: 0,
        // pageSize: 15,
        // current: 1,
      },
      nowDate: moment(this.getCurrentData(), "YYYY-MM-DD"),
      onlineData: "",
      visible: false,
      cancleParam: {
        id: "",
        auditor: "",
        password: "",
      },
      cancleRules: {
        auditor: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      inst_id: JSON.parse(localStorage.getItem("inst")).id,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    getCurrentData() {
      return new Date().toLocaleDateString();
    },
    //导出十月份报表
    DownloadDetailReport() {
      this.$confirm({
        title: "确定导出十月份报表?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          getDetailReport()
            .then((res) => {
              let url = res.data.url;
              const a = document.createElement("a");
              a.setAttribute("href", url);
              a.click();
            })
            .catch((err) => {
              this.$message.error("获取失败");
            });
        },
        onCancel: () => {},
      });
    },
    //列表下载
    downloadExcel() {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => {},
      });
    },
    //数据写入excel
    export2Excel() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = [
          "订单号",
          "用餐时段",
          "部门",
          "职务",
          "姓名",
          "手机",
          "份数",
          "金额",
          "用餐日期",
          "用餐状态",
          "验证方式",
          "报餐时间",
          "核销时间",
          "订单明细",
          "工号",
          "备注"
        ]; // 导出的表头名信息
        const filterVal = [
          "order_number",
          "meal",
          "office",
          "post",
          "realname",
          "mobile",
          "count",
          "money",
          "repast_date",
          "status",
          "auto_check",
          "created_at",
          "updated_at",
          "orderDetails",
          "work_id",
          "remarks"
        ]; // 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "用餐数据"); // 导出的表格名称，根据需要自己命名
        this.userLogFn();
      });
    },
    //格式转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    // 刷新
    search(val) {
      this.queryParam = {
        page: 1,
        repast_date: val.currentDate,
        status: val.select.status === "" ? "" : val.select.status,
        meal_id: val.select.meal_id ? val.select.meal_id : "",
        post_id: val.select.post_id ? val.select.post_id : "",
        office_id: val.select.office_id ? val.select.office_id : "",
        name: val.input.name ? val.input.name : "",
        mobile: val.input.mobile ? val.input.mobile : "",
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      if (
        new Date(this.queryParam.repast_date) < this.onlineData ||
        new Date(this.queryParam.begin_date) < this.onlineData
      ) {
        this.queryParam.is_old = 1;
      } else {
        this.queryParam.is_old = 0;
      }
      this.pagination.current = 1;
      this.getTableData();
      this.getExportData();
    },
    // 刷新
    search2(val) {
      this.outSideParam = {
        page: 1,
        status: val.select.status === "" ? "" : val.select.status,
        meal_id: val.select.meal_id ? val.select.meal_id : "",
        mobile: val.input.mobile ? val.input.mobile : "",
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      this.pagination2.current = 1;
      this.getOutSideOrders();
    },
    //获取导出的数据
    getExportData() {
      //请求不分页的数据
      this.ExportLoading = true;
      this.reGetExportData = false;
      this.queryParam.no_paging = 1;
      orderList(this.queryParam)
        .then((res) => {
          this.dataForExport = res.data.map((item) => {
            if (item.info instanceof Array) {
              let arr = [];
              item.info.forEach((element) => {
                let str = "";
                str = `${element.title}*${element.num}份`;
                arr.push(str);
              });
              if (arr.length > 1) {
                arr.join(",");
              }
              item.orderDetails = arr.toString();
            } else {
              let obj = item.info;
              item.orderDetails = `${obj.title}*${item.count}份`;
            }
            item = {
              ...item,
              status:
                item.status == 1
                  ? "待就餐"
                  : item.status == 2
                  ? "已就餐"
                  : item.status == 3
                  ? "已取消"
                  : "",
              updated_at: item.status == 2 ? item.updated_at : "未核销",
              data_from:
                item.data_from == "order"
                  ? "点餐"
                  : item.data_from == "meal"
                  ? "套餐"
                  : "",
              auto_check:
                item.auto_check == 1
                  ? "系统验证"
                  : item.auto_check == 2
                  ? "用户验证"
                  : item.auto_check == 3
                  ? "食堂验证"
                  : item.auto_check == 4
                  ? "闸机扫码"
                  : item.auto_check == 5
                  ? "人脸验证"
                  : "",
              money: item.money * 1,
            };
            return item;
          });
          this.ExportLoading = false;
          this.reGetExportData = false;
        })
        .catch((err) => {
          if (err.status == 500) {
            this.$message.error(
              "加载导出的数据超时，请点击重新获取导出数据按钮获取数据"
            );
            this.reGetExportData = true;
          }
        });
    },
    //获取表格数据
    getTableData() {
      this.loading = true;
      //获取分页的数据用于展示
      this.queryParam.no_paging = 0;

      orderList(this.queryParam).then((res) => {
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;

        res.data.data.forEach((item) => {
          // 判断info信息是否数组
          if (item.info instanceof Array) {
            let arr = [];
            item.info.forEach((element) => {
              let str = "";
              str = `${element.title}*${element.num}份`;
              arr.push(str);
            });
            if (arr.length > 1) {
              arr.join(",");
            }
            item.orderDetails = arr.toString();
          } else {
            let obj = item.info;
            item.orderDetails = `${obj.title}*${item.count}份`;
          }
        });
        this.tableData = res.data.data;
        this.loading = false;
      });
    },

    //分页
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //获取部门列表
    getOffices() {
      officesWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[0].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取职务列表
    getPosts() {
      postsWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[1].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取就餐时段列表
    mealtimeListFn() {
      mealtimeListWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[2].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取上线时间
    getOnlineFn() {
      getOnline().then((res) => {
        this.onlineData = new Date(res.data.msg);
        if (new Date(this.nowDate) < this.onlineData) {
          this.queryParam.is_old = 1;
        } else {
          this.queryParam.is_old = 0;
        }
      });
    },

    //取消报餐
    deleteType(record) {
      this.cancleParam.id = record.id;
      getAudit({ type: 2 }).then((res) => {
        if (res.data == true) {
          this.visible = true;
        } else {
          this.$confirm({
            content: `确定取消该订单？`,
            onOk: () => {
              orderCancel(this.cancleParam.id)
                .then((res) => {
                  this.$message.success("取消成功");
                  // this.queryParam.page = 1;
                  // this.pagination.current = 1;
                  this.getTableData();
                  this.getExportData();
                })
                .catch((err) => {
                  if (err.status == 400) {
                    this.$message.error(err.data.msg);
                  } else {
                    let { errors } = err.data;
                    for (let i in errors) {
                      this.$message.error(errors[i][0]);
                    }
                  }
                });
            },
          });
        }
      });
    },

    handleCancleMeal() {
      this.$refs["cancleForm"].validate((valid) => {
        if (valid) {
          this.orderCancelFnWithParam();
        }
      });
    },

    orderCancelFnWithParam() {
      orderCancel(this.cancleParam.id, {
        auditor: this.cancleParam.auditor,
        password: this.cancleParam.password,
      })
        .then((res) => {
          this.visible = false;
          this.$message.success("取消成功");
          // this.queryParam.page = 1;
          // this.pagination.current = 1;
          this.getTableData();
          this.getExportData();
        })
        .catch((err) => {
          if (err.status == 400) {
            this.$message.error(err.data.msg);
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },

    userLogFn() {
      let moduleId = this.$route.meta.moduleId,
        param = {
          begin_date: this.queryParam.begin_date,
          end_date: this.queryParam.end_date,
          repast_date: this.queryParam.repast_date
            ? this.queryParam.repast_date
            : moment().format("YYYY-MM-DD"),
          name: this.queryParam.name,
          mobile: this.queryParam.mobile,
          meal: this.queryParam.meal_id
            ? this.selects[2].options.find(
                (item) => item.key === this.queryParam.meal_id
              ).value
            : "",
          office: this.queryParam.office_id
            ? this.selects[0].options.find(
                (item) => item.key === this.queryParam.office_id
              ).value
            : "",
          post: this.queryParam.post_id
            ? this.selects[1].options.find(
                (item) => item.key == this.queryParam.post_id
              ).value
            : "",
          status:
            this.queryParam.status === "" ||
            this.queryParam.status === undefined
              ? ""
              : this.selects[3].options.find(
                  (item) => item.key === this.queryParam.status
                ).value,
          total: this.dataForExport.length,
        };
      userLog(moduleId, param)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    // this.$confirm({
    //   content: "目前仅支持上月份报表导出,其他区间导出请联系客服",
    // });
    await this.getOnlineFn();

    this.getTableData();
    this.getExportData();
    this.getOffices();
    this.getPosts();
    this.mealtimeListFn();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>