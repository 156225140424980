<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="plus" @click="showBatch()" style="margin-right: 10px">添加规则</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="action" slot-scope="text, record">
            <a-button type="link" @click="() => remove(record.id)" class="e-space" >删除</a-button>
          </template>
          <template slot="status" slot-scope="text, record">
            <a-switch :checked="record.status == 0" :loading="disableLoading" @change="() => changeStatus(record)" />
          </template>
        </a-table>
      </div>
    </div>
    <!-- 新增/修改 -->
    <a-modal v-model="editModalVisible" :title="editModalTitle" cancelText="取消" :width="1000" @ok="preSave()"
      okText="创建规则">
      <a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :model="tmp"
        :rules="formRules">
        <a-form-model-item label="规则名称" prop="name">
          <a-input v-model="tmp.name" placeholder="请输入规则名称" />
        </a-form-model-item>
        <a-form-model-item label="报餐时段">
          <a-radio-group v-model="tmp.meal_id" @change="changeMealtime">
            <a-radio-button v-for="item in meals" :value="item.id" :key="item.id">
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="menus && menus.length > 0" label="请选择代报菜品">
          <a-radio-group v-model="tmp.menu_id" @change="changeMenu">
            <a-radio-button v-for="item in menus" :value="item.id" :key="item.id">
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item v-if="menus && menus.length > 0" label="菜品循环周期">
          <a-checkbox-group v-model="tmp.time_cycle" name="checkboxgroup" :options="weekOptions"
            @change="changeTimeCycle" />
        </a-form-model-item>

        <a-form-model-item v-if="users && users.length > 0">
          <template slot="label">
            <a-checkbox :indeterminate="indeterPerson" :checked="checkAllPerson" @change="onCheckAllPerson">报餐人员
            </a-checkbox>
          </template>
          <a-checkbox-group v-model="tmp.users" name="checkboxgroup" style="width:100%;">
            <a-row>
              <a-col style="margin:10px 0;" :span="3" v-for="item in users" :key="item.value">
                <a-checkbox :value="item.value"></a-checkbox>{{ item.label }}
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  autoMealRules,
  disableAutoMealRule,
  mealtimeList,
  autoMealMenus,
  autoMealUsers,
  addAutoMealRule,
  removeAutoMealRule,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "单位设置",
        },
        {
          key: 1,
          title: "自动代报餐",
        },
      ],

      //传给searchBar公共搜索组件的数据
      inputs: [
        {
          placeholder: "请输入规则名称",
          model: "name",
          width: 300,
        },
      ],
      //请求参数
      queryParam: {
        page: 1,
        name: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [], //不分页的数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "规则名称",
          key: "name",
          dataIndex: "name",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "状态",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },
      disableLoading: false,
      editModalVisible: false,
      editModalTitle: "新增代报餐规则",
      tmp: {
        id: "",
        name: "",
        status: 0,
        meal_id: "",
        menu_id: "",
        users: [],
        time_cycle: [],
      },
      weekOptions: [
        {
          label: "周一",
          value: "1",
          disabled: true,
        },
        {
          label: "周二",
          value: "2",
          disabled: true,
        },
        {
          label: "周三",
          value: "3",
          disabled: true,
        },
        {
          label: "周四",
          value: "4",
          disabled: true,
        },
        {
          label: "周五",
          value: "5",
          disabled: true,
        },
        {
          label: "周六",
          value: "6",
          disabled: true,
        },
        {
          label: "周日",
          value: "0",
          disabled: true,
        },
      ],
      meals: [],
      menus: [],
      users: [],
      formRules: {
        name: [{ required: true, message: "请输入规则名称", trigger: "blur" }],
      },
      indeterPerson: false,//控制报餐人员全选样式
      checkAllPerson: false,//报餐人员全选标识
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        name: val.input.name ? val.input.name : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      autoMealRules(this.queryParam).then((res) => {
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
        if (res.data.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = res.data.data;
        this.loading = false;
      });
    },

    initMeals () {
      let p = {
        no_paging: 1,
        is_valid: 1,
      };
      mealtimeList(p).then((res) => {
        if (res.data.length == 0) {
          this.meals = [];
          return;
        }
        this.meals = res.data;
      });
    },
    initMenus (meal_id) {
      let p = {
        no_paging: 1,
        is_valid: 1,
        meal_id: meal_id,
      };
      autoMealMenus(p).then((res) => {
        if (res.data.length == 0) {
          this.menus = [];
          return;
        }
        this.menus = res.data;
      });
    },
    initUsers (menu_id) {
      let p = {
        no_paging: 1,
        is_valid: 1,
        menu_id: menu_id,
      };
      autoMealUsers(p).then((res) => {
        if (res.data.length == 0) {
          this.users = [];
          return;
        }
        let a = new Array();
        let d = res.data;
        for (var i in d) {
          a.push({
            label: d[i]["realname"],
            value: d[i]["id"],
          });
        }
        this.users = a;
      });
    },
    changeStatus (row) {
      this.disableLoading = true;
      let id = row.id;
      let p = {
        status: row.status == 1 ? 0 : 1,
      };
      disableAutoMealRule(id, p)
        .then((res) => {
          this.disableLoading = false;
          this.$message.success("操作成功");
          row.status = p.status;
        })
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
            row.status = p.status;
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    changeTmpStatus () {
      this.tmp.status = this.tmp.status == 1 ? 0 : 1;
    },
    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //打开个性化批量充值导入模态框
    showBatch () {
      this.editModalVisible = true;
      this.editModalTitle = "添加代报餐规则";
      this.resetTmp();
      this.menus = [];
      this.users = [];
    },
    remove: function (id) {
      let th = this;
      this.$confirm({
        title: "操作提示",
        content: "删除之后不可恢复，您确认删除该数据？",
        onOk () {
          removeAutoMealRule(id)
            .then((res) => {})
            .catch((err) => {
              if (err.status == 204) {
                th.$message.success("操作成功");
                th.getTableData();
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  th.$message.error(errors[i][0]);
                }
              }
            });
        },
        onCancel () { },
      });
    },
    preSave () {
      let th = this;
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          th.saveAutoMealRule();
        }
      });
    },
    saveAutoMealRule () {
      this.tmp.menu_id = [this.tmp.menu_id];
      addAutoMealRule(this.tmp)
        .then((res) => {
          this.$message.success("操作成功");
          this.getTableData();
          this.editModalVisible = false;
        })
        .catch((err) => {
          if (err.status == 201) {
            this.$message.success("操作成功");
            this.getTableData();
            this.editModalVisible = false;
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    changeMealtime (v) {
      this.initMenus(this.tmp.meal_id);
    },
    changeMenu (v) {
      this.initUsers(this.tmp.menu_id);
      this.resetWeekOptions();
      this.freshCycleDays();
    },
    freshCycleDays () {
      let th = this;
      let menus = th.menus.filter((item) => item.id == th.tmp.menu_id);
      if (menus.length == 1) {
        let cycle_days = menus[0]["cycle_days"];
        let chks = new Array();
        th.weekOptions.forEach(function (val, index, arr) {
          if (cycle_days.includes(String(val.value))) {
            val.disabled = false;
            chks.push(String(val.value));
          }
        });
        this.tmp.time_cycle = chks;
      }
    },
    changeTimeCycle () { },
    resetWeekOptions () {
      this.weekOptions.forEach(function (val, index, arr) {
        val.disabled = true;
      });
    },
    resetTmp () {
      this.tmp.id = "";
      this.tmp.name = "";
      this.tmp.status = 0;
      this.tmp.meal_id = "";
      this.tmp.menu_id = "";
      this.tmp.users = [];
      this.tmp.time_cycle = [];
    },
    onCheckAllPerson (e) {
      this.tmp.users = e.target.checked
        ? this.users.map((item) => {
          return (item = item.value);
        })
        : [];
      this.indeterPerson = false;
      this.checkAllPerson = e.target.checked;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
    this.initMeals();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
.e-space {
  margin-left: 10px;
}
</style>