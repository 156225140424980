/*
 * @Description: 
 * @Author: Chen Bi Qi
 * @Date: 2021-02-05 15:13:06
 * @LastEditTime: 2021-02-05 17:19:30
 */
// 导出页面为PDF格式

// 接收接口返回的文件流并下载
function acceptFileStream (data, fileNameParam) {
  const blob = new Blob([data]);
  const fileName = fileNameParam;
  const elink = document.createElement("a");
  elink.download = fileName;
  elink.style.display = "none";
  elink.href = URL.createObjectURL(blob);
  document.body.appendChild(elink);
  elink.click();
  URL.revokeObjectURL(elink.href); // 释放URL 对象
  document.body.removeChild(elink);
}


function findIndex (id, data) {
  let find = data.findIndex((item) => {

    return item.name == id
  })
  return find
  // console.log(JSON.parse(localStorage.getItem('allAuth')))
}

function findIndexByTitle (id, data) {

  let find = data.findIndex((item) => {
    if (item.title == id) {
      return item.own == 1
    }
  })

  return find
}

/***
 * 获取当前浏览器类型
 */
function myBrowser () {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf("Opera") > -1;
  if (isOpera) { //判断是否Opera浏览器
    return "Opera"
  };
  if (userAgent.indexOf("Firefox") > -1) { //判断是否Firefox浏览器
    return "FF";
  };
  if (userAgent.indexOf("Chrome") > -1) {
    return "Chrome";
  };
  if (userAgent.indexOf("Safari") > -1) { //判断是否Safari浏览器
    return "Safari";
  };
  if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) { //判断是否IE浏览器
    return "IE";
  };
}

// 防抖
const DebounceBy = (fn, t) => {
  let delay = t || 500
  let timer
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer)
    }

    let callNow = !timer

    timer = setTimeout(() => {
      timer = null
    }, delay)

    if (callNow) fn.apply(this, args)
  }

}
// 百分比转小数
function PercentToPoint (percent) {
  var str = percent.replace("%", "");
  str = str / 100;
  return str;
}

//页面导出pdf
function Page2pdf (dom, title) {
  const { jsPDF } = window.jspdf;
  html2canvas(dom, {
    allowTaint: true
  }).then(function (canvas) {
    let contentWidth = canvas.width
    let contentHeight = canvas.height
    let pageHeight = contentWidth / 592.28 * 841.89
    let leftHeight = contentHeight
    let position = 0
    let imgWidth = 595.28
    let imgHeight = 592.28 / contentWidth * contentHeight
    let pageData = canvas.toDataURL('image/jpeg', 1.0)
    let PDF = new jsPDF('', 'pt', 'a4') //('landscape', 'pt', 'a4') 横向    ('', 'pt', 'a4') 纵向
    if (leftHeight < pageHeight) {
      PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      while (leftHeight > 0) {
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        leftHeight -= pageHeight
        position -= 841.89
        if (leftHeight > 0) {
          PDF.addPage()
        }
      }
    }
    PDF.save(title + '.pdf')
  }
  )
}

function GetPercent (num, total) {
  /// <summary>
  /// 求百分比
  /// </summary>
  /// <param name="num">当前数</param>
  /// <param name="total">总数</param>
  num = parseFloat(num);
  total = parseFloat(total);
  if (isNaN(num) || isNaN(total)) {
    return "-";
  }
  return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00) + "%";
}

function updateUseRouter (oldRouter) {
  console.log('util.js中整理前的权限数组:')
  console.log(oldRouter)
  try {
    let inst = JSON.parse(localStorage.getItem('inst')),
      isAdmin = JSON.parse(localStorage.getItem("isAdmin")),
      useAuth = JSON.parse(localStorage.getItem("useAuth")),
      agent_inst = JSON.parse(localStorage.getItem("agent_inst")),
      expandAuth = JSON.parse(localStorage.getItem("expandAuth"));
      
    oldRouter.forEach(parent => {
      if(parent.name == '更多应用'){
        parent.meta.show = true
      } else if (parent.name == '人员管理' ||
        parent.name == '用餐/消费管理' ||
        parent.name == '账户管理' ||
        parent.name == '客餐/接待餐' ||
        parent.name == '拓展功能' ||
        parent.name == '经营报表') {
        // 根据getme接口返回的权限动态变更的权限
        parent.children.forEach(son => {
          let find = findIndex(son.name, useAuth);
          if (find == -1) {
            son.meta.requireAuth = false
          } else {
            son.meta.requireAuth = true
          }
        })
        // console.log(parent.name + '权限下整理后的子权限：')
        // console.log(parent.children)
        // 如果子权限全是隐藏的，那么父权限也隐藏
        parent.meta.show = !parent.children.every(son => {
          return son.meta.requireAuth == false
        })
        // console.log('权限显隐：')
        // console.log(parent.meta.show)
      } else {
        // 前端定义的权限
        // 判断单位管理及下级权限的显隐
        if (parent.name == "单位管理") {
          // 判断是否有查看单位管理
          if (isAdmin) {
            // 判断用户是否有对应权限
            parent.children.forEach((item) => {
              if (item.name == "审核账号管理") {
                if (inst.version.version != 0) {
                  item.meta.requireAuth = true;
                } else {
                  item.meta.requireAuth = false;
                }
              } else if (item.name == "模式切换") {
                if (inst.version.version != 0) {
                  item.meta.requireAuth = true;
                } else {
                  item.meta.requireAuth = false;
                }
              } 
              // else if (item.name == '版本信息') {
              //   if (agent_inst) {
              //     item.meta.requireAuth = false;
              //   } else {
              //     item.meta.requireAuth = true;
              //   }
              // } 
              // else if (item.name == "模块管理") {
              //   // 模块管理对代理食堂暂不开放
              //   if (agent_inst) {
              //     item.meta.requireAuth = false;
              //   } else {
              //     item.meta.requireAuth = true;
              //   }
              // }

            });
            parent.meta.show = true;
          } else {
            parent.meta.show = false;
          }
        }
      }
    })
    let newRouter = oldRouter;
    // console.log('util.js中整理后的权限数组:')
    // console.log(newRouter)
    return newRouter
  } catch (err) {
    console.log('util.js中的updateUseRouter方法报错：')
    console.log(err)
  }

}

// 记录模块停留时间
function createModuleStayTime (currentId = null){
  // 初始化返回值
  let res = {
    inst_id: localStorage.getItem('inst') ? JSON.parse(localStorage.getItem('inst')).id : null,
    user_id: JSON.parse(localStorage.getItem('MEID'))
  };
  // 如果有上个模块的id，需要返回
  let pre_module_id = localStorage.getItem('pre_module_id');
  if(pre_module_id){
    res.last_id = pre_module_id
  }
  // 如果当前停留的模块有id，缓存该id做为上个模块的id且需要返回
  if(currentId){
    res.id = currentId
  }else{
    localStorage.removeItem('pre_module_id')
  }
  
  return res
};

export default {
  acceptFileStream,
  findIndex,
  myBrowser,
  DebounceBy,
  PercentToPoint,
  findIndexByTitle,
  Page2pdf,
  GetPercent,
  updateUseRouter,
  createModuleStayTime
}