<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar
          :timeRange="timeRange"
          :dateTime="dateTime"
          @search="search"
          @reset="search"
        />
        <div class="control_box_bottom">
          <a-button
            v-if="!reGetExportData"
            type="primary"
            :icon="ExportLoading ? 'loading' : 'printer'"
            @click="downloadExcel"
            :disabled="ExportLoading"
            >{{ ExportLoading ? "加载中.." : "导出" }}</a-button
          >
          <a-button v-else @click="getExportData">重新获取导出数据</a-button>
        </div>
      </div>
      <div style="background: #FFF; padding: 20px;margin-top: 20px">
        <a-row :gutter="16">
          <a-col :span="8">
            <a-card>
              <a-card-meta title="合计次数" :description="statData.record_count" style="text-align: center;"></a-card-meta>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card>
              <a-card-meta title="合计份数" :description="statData.total_count" style="text-align: center;"></a-card-meta>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card>
              <a-card-meta title="合计费用(元)" :description="statData.total_money" style="text-align: center;"></a-card-meta>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" 
                 :pagination="pagination" @change="handleTableChange">
            <span slot="annex_image" slot-scope="record">
              {{ record.price * record.count }}
            </span>
            <template slot="annex_image" slot-scope="record" >
              <img :src="record.annex_image" mode="heightFix" style="height: 20px;" @click="showImgModal(record.annex_image)"/>
            
            </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
//引入接口api
import {
  getGuestMealOrderStat
} from "@/request/api";

//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
import util from "@/utils/util";

import moment from "moment";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },

  setup() {
    const visible = ref(false);
    const imageSrc = ref('https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp');

    const handleImageClick = () => {
      visible.value = true;
    };

    return {
      visible,
      imageSrc,
      handleImageClick
    };
  },
  data() {
    return {
      //面包屑
      breadcrumbs: [
        {
          key: 0,
          title: "客餐/接待餐",
        },
        {
          key: 1,
          title: "客餐统计",
        },
      ],
      dateTime: {
        show: true,
      },
      timeRange: {
        show: true,
        placeholderStart: "开始时间",
        placeholderEnd: "结束时间",
        rangeLimit: true
      },
      timeData:['2024-10-24','2024-10-24'],
      //请求参数
      queryParam: {
        page: 1,
        repast_date:  moment(new Date().toLocaleDateString()).format("YYYY-MM-DD"),
        begin_date: "",
        end_date: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      statData: {
        total_count:0,
        total_copies:0,
        total_money:0
      },
      dataForExport: [],
      ExportLoading: false,
      reGetExportData: false,
      loading: false, //table加载状态
      imagePath: "", //图片路径
      // 表格的列配置
      columns: [
        {
          title: "客餐类型",
          key: "meal_title",
          dataIndex: "meal_title",
        },
        {
          title: "客餐次数",
          key: "record_count",
          dataIndex: "record_count",
        },
        {
          title: "用餐份数",
          key: "total_count",
          dataIndex: "total_count",
        },
        {
          title: "合计费用(元)",
          key: "total_money",
          dataIndex: "total_money",
        },
        // {
        //   title: "操作",
        //   key: "action",
        //   scopedSlots: {customRender: "action"},
        // },
      ],
      //页配置
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
      },

      visible: false,
      addGuestMeal: false,
      editId: "",
      //更新参数
      addParam: {
        title: "",
        price: 0, //报餐截止时间提前天数
        selectedHuser: []
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    //刷新和搜索一样的方法
    search(val) {
      this.queryParam = {
        page: 1,
        repast_date: val.currentDate,
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      this.pagination.current = 1;
      this.getTableData();
      this.getExportData();
    },

    //获取表格数据
    getTableData() {
      this.loading = true;
      this.queryParam.no_paging = 0;
      this.queryParam.pageSize = this.pageSize;
      getGuestMealOrderStat(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = {...this.pagination};
        pagination.total = resultData.total;
        this.pagination = pagination;
        //列表数据处理
        console.log('resultStat',resultData.stat);
        this.tableData = resultData.data;
        console.log('stat',resultData.stat);
        this.statData = resultData.stat;
        this.loading = false;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
      });
    },

    //分页
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //获取导出的数据
    getExportData() {
      //请求不分页的数据
      this.ExportLoading = true;
      this.reGetExportData = false;
      this.queryParam.no_paging = 1;
      getGuestMealOrderStat(this.queryParam)
        .then((res) => {
          console.log(res);
          this.dataForExport = res.data.map((item) => {
            return item;
          });
          this.ExportLoading = false;
          this.reGetExportData = false;
        })
        .catch((err) => {
          if (err.status == 500) {
            this.$message.error(
              "加载导出的数据超时，请点击重新获取导出数据按钮获取数据"
            );
            this.reGetExportData = true;
          }
        });
    },
    //列表下载
    downloadExcel() {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => {},
      });
    },
    //数据写入excel
    export2Excel() {
      var that = this;
          console.log('downloadExcel');
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = [
          "客餐类型",
          "客餐次数",
          "用餐份数",
          "合计金额(元)"
        ]; // 导出的表头名信息
        const filterVal = [
          "meal_title",
          "record_count",
          "total_count",
          "total_money"
        ]; // 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "客餐统计"); // 导出的表格名称，根据需要自己命名
        // this.userLogFn();
      });
    
    },

    //格式转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTableData();
    this.getExportData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;

  .control_box {
    background-color: white;
    padding: 20px;

    .control_box_bottom {
      margin-top: 20px;
    }
  }

  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>