<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="detail-wrapper">
      <div v-if="modules.length == 0 && hardwares.length == 0" class="no-data">暂无数据...</div>
      <div class="options">

        <div class="option-card" v-for="item in modules" :key="item.key">
          <div class="body">
            <div class="header">
              <div class="header-info">
                <div class="header-info-title">{{ item.title }}</div>
                <div class="header-info-price">￥{{ item.price }}/年</div>
              </div>
              <div class="header-rigth" @click="showExampleFn(item)">
                查看详情
              </div>
            </div>
            <div class="body-content">
              <div>{{ item.scene }}</div>
            </div>
            <div style="width: 100%;background-color: #eee;height: 120px;margin-top: 10px;">
                <img :src="item.image" style="width: 100%;height: 120px;object-fit: unset;">
            </div>
          </div>
          
          <div class="bottom" v-if="item.selected == true">
            <div class="button">
              已开通
            </div>
          </div>

          <div class="bottom-un" v-else="item.selected">
            
              <router-link target="_blank" :to="{path:'/VersionInformation',query:{id:item.id}}" style="color:#000;">立即开通</router-link>
            
          </div>
        </div>

        <!-- <div style="font-size: 18px;font-weight: 800;">硬件及调试服务</div> -->
      </div>
    </div>

    <div class="detail-wrapper show_box">
      
      <div class="flex_wrap">
        <a-card hoverable class="module_card" v-for="item in hardwares" :key="item.id"
          :title="item.model? item.title + '(' + item.model + ')' : item.title" style="width: 300px">
          <img v-if="item.image" alt="example" :src="item.image" />
          <img v-else src="../../assets/defaultVImg.jpeg" alt="">
          <p class="introduce">
            {{item.describe ? item.describe : '暂无描述...'}}</p>
          <p>单价：￥{{item.price}}</p>
          <p>
            <span v-if="item.wanna > 0">￥{{item.totalPrice}}</span>
            <a-button v-if="item.wanna > 0" @click="reduceWanna(item.id)" icon="minus" size="small"
              style="margin-left:10px;"></a-button>
            <a-input v-if="item.wanna > 0" disabled size="small" style="width:50px;margin:0 10px;"
              :value="item.wanna">
            </a-input>
            <a-button @click="addWanna(item.id)" type="primary" icon="plus" size="small"></a-button>
          </p>
        </a-card>
      </div>

      <!--硬件支付-->
      <div>
        <h3 style="margin-top:10px;">
          合计:<span style="color:#ff833d;font-size:30px;">￥{{hardwaresPrice}}</span>
        </h3>
        <a-checkbox :checked="readCheck" @change="changeCheck">
            已阅读并同意
            <a href="javascript:;" @click="showRule = true">服务条款</a>、
            <a href="javascript:;" @click="showPrivacy = true">隐私政策</a>
        </a-checkbox>
        <div style="margin-top:10px;">
          <a-button :disabled="readCheck == false ? true : false" type="primary" @click="payRightNow()">立即支付</a-button>
      </div>
    </div>

    <!-- 选择支付方式弹窗 -->    
     <a-modal v-model="selectPayType" title="选择支付方式" :keyboard="false" :maskClosable="false" :footer="null"
      @cancel="noSurePayType">
      <div style="color:red;">购买系统版本或者增值功能完成后请重新登陆生效</div>
      <a-radio-group v-model="payType" @change="changePayType">
        <a-radio v-for="item in payTypes" :key="item.key" :value="item.key">
          {{item.value}}
        </a-radio>
      </a-radio-group>

      <div style="text-align: center" v-show="showWxScan">
        <!-- 装微信支付二维码的容器 -->
        <div style="display: flex; justify-content: center;marginTop:10px;" class="qrcode" ref="qrCodeUrl"></div>
        <p style="marginTop:10px;">
          <img src="https://vip.51baocan.com/static/home/pay/img/step.png" alt srcset />
        </p>
        <a-button icon="transaction" style="background-color: #00c800; color: white; margin: 15px auto"
          @click="wxPaySuccess">支付成功</a-button>
      </div>
      <div id="aliPay"></div>
      <div id="unionPay"></div>
      <div id="quickPay"></div>
    </a-modal>

    <!-- 对公汇款 -->
    <a-modal v-model="showUnderLine" title="对公汇款订单详情可在订单与发票管理中查看" :footer="null" :width="400">
      <div style="text-align: center">
        <div>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              开户名称:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              福建尚汇信息科技有限公司
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              开<i style="paddingLeft:7px;" />户<i style="paddingLeft:7px;" />行:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              中国建设银行股份有限公司漳州东城支行
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              账<i style="paddingLeft:26px;" />号:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              35050166810700001235
            </a-col>
          </a-row>
        </div>
        <p>
          <img :src="kefuSrc" alt srcset style="width: 180px;margin-top: 20px;" />
        </p>
        <p>请及时添加客服微信或者联系客服电话:</p>
        <p>4009-180-190</p>
      </div>
    </a-modal>

    <!-- 服务协议 -->
    <a-modal v-model="showRule" title="“食堂有饭”服务条款" :width="1000" @ok="showRule = false">
      <div v-html="serviceContent" style="display:block;"></div>
    </a-modal>
    <!-- 隐私协议 -->
    <a-modal v-model="showPrivacy" title="“食堂有饭”隐私政策" :width="1000" @ok="showPrivacy = false">
      <div v-html="privacyContent"></div>
    </a-modal>

  </div>
     

    <!-- 配置示例 -->
    <a-modal
      v-model="showExample"
      :title="exampleTitle"
      :width="600"
      :footer="null"
    >
      <div style="width:100%;height:100%;overflow:auto;" v-html="example"></div>
      <!-- <vue-editor v-model="example" useCustomImageHandler /> -->
    </a-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { getModules, showProduction,quickOrder,payOrder, getPolicy } from "@/request/api";
import util from "@/utils/util";

import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
const payTypes = [
  {
    key: 1,
    value: '微信'
  },
  {
    key: 2,
    value: '支付宝'
  },
  {
    key: 3,
    value: '企业网银'
  },
  {
    key: 4,
    value: '对公汇款'
  },
  {
    key: 6,
    value: '快捷支付'
  }
];
export default {
  data() {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "单位管理",
        },
        {
          key: 1,
          title: "模块管理",
        },
        {
          key: 2,
          title: "选项管理",
        },
      ],
      query: {},
      options: [],
      payTypes,
      showExample: false,
      example: "",
      exampleTitle: "",
      modules:{},
      hardwares:[],
      addedHardWares:[],//已勾选(欲购买)的硬件商品数组
      hardwaresPrice:0,//硬件商品选择后的总价
      readCheck: false,
      selectPayType:false,
      showWxScan: false,
      payType:'',
      order_number: '',//订单号
      showUnderLine:false,
      showRule: false,
      showPrivacy:false,
      serviceContent:'',
      privacyContent:'',
      kefuSrc:'https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/lanjie.png'
    };
  },
  computed: {},
  components: {
    MyBreadcrumb,
  },
  async created() {
    // this.query = this.$route.query;
    // console.log('上个页面传进的参数')
    // console.log(this.query)
    // this.getOptions(this.query.id, this.query.version);
    // console.log(this.options);
    this.getModules();
    this.getHardware();
    this.getPolicyFn();//获取服务协议, 隐私政策等
    this.setKefuSrc();
  },
  mounted() {},
  methods: {
    goBack:util.DebounceBy(function(){
      this.$router.back()
    },3000),
    setKefuSrc(){
      if(localStorage.getItem("kefuSrc") != null){
        this.kefuSrc = localStorage.getItem("kefuSrc");
      }
    },
    // 获取选项列表
    async getOptions(id, version) {
      console.log('传入的模块id：')
      console.log(id)
      try {
        let { data } = await getOptionList(id, {version});
        this.options = data.data;
      } catch (error) {
        this.$message.error(error.data.msg)
      }
    },
    async getModules(){
      let that_ = this;
      //获取用户食堂模块列表
      getModules().then((res) => {
        that_.modules = res.data.data;
      });
    },
    async getHardware(){
      let that_ = this;
      let param = {
        type: 3
      }
      try {
        let { data } = await showProduction(param)
        if (data.msg == '获取成功') {
          that_.hardwares = data.data.map(item => {
            item.price = item.price * 1
            item.wanna = 0
            item.totalPrice = 0
            return item
          })
          that_.hardwaresPrice = 0
        }
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          that_.$message.error(errors[i][0]);
        }
      }
    },
    // 展示示例
    showExampleFn(item) {
      // console.log(item)
      let { title, scene, description } = item;
      Object.assign(this, {
        example: description ? description : '暂无...',
        exampleTitle: title,
        showExample: true,
      });
    },
    // 更新食堂信息，权限数组
    async updateAuths() {
      await this.$store.dispatch("GET_INST_INFO");
      await this.$store.dispatch("GET_ME");
      await this.$store.dispatch("GET_ALL_AUTH");
    },
    // 选用选项
    checkOption(item) {
      this.$confirm({
        content: `确定选用该选项？`,
        onOk: async () => {
          try {
            let { data } = await switchOption(item.had_id);
            this.options = data.data;
            await this.updateAuths()
            this.$message.success("操作成功");
            await this.getOptions(this.query.id, this.query.version);
          } catch (error) {
            let { data } = error,
              msg = "";
            for (let i in data.errors) {
              msg = data.errors[i][0];
            }
            this.$message.error(msg);
          }
        },
      });
    },

    /*
     *@functionName: 购买硬件-单品数量减
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 10:55:59
    */
    async reduceWanna (id) {
      await this.hardwares.map(item => {
        if (item.id == id) {
          item.wanna = item.wanna - 1
          item.totalPrice = new this.$BigNumber(item.wanna).times(item.price)

        }
      })
      await this.getHardwaresPrice()
    },

    /*
      *@functionName: 购买硬件-单品数量增
      *@description:
      *@author: zhangchenhui
      *@date: 2021-02-05 10:55:59
     */
    async addWanna (id) {
      await this.hardwares.map(item => {
        if (item.id == id) {
          item.wanna = item.wanna + 1
          item.totalPrice = new this.$BigNumber(item.wanna).times(item.price)
        }
      })
      await this.getHardwaresPrice()
    },

    /*
     *@functionName: 计算硬件商品合计价格
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 10:41:03
    */
    getHardwaresPrice () {
      let total = 0
      this.hardwares.map(item => {
        total = new this.$BigNumber(total).plus(item.totalPrice).toNumber()
      })
      this.hardwaresPrice = total
    },

    /*
     *@functionName: 已阅读并勾选协议
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 17:26:50
    */
    changeCheck (e) {
      this.readCheck = e.target.checked;
    },

    // 购买选项 
    async buyOption(item) {
      // this.$router.push({
      //     path: "/VersionInformation",
      //     query: item,
      //   });
      this.$router.resolve({
        path: "/VersionInformation",
        query: {id:item.id}
      });
        this.$store.commit('UPDATE_SELECTEDKEYS',['/VersionInformation'])
    },
    
    /*
     *@functionName: 立即购买
     *@author: zhangchenhui
     *@date: 2021-02-07 11:38:11
    */
    async payRightNow () {

        if (this.hardwaresPrice == 0) {
          this.$message.info('请先选择硬件商品')
          return
        }
        let param = []
        await this.hardwares.map(item => {
          if (item.wanna > 0) {
            param.push({
              id: item.id,
              num: item.wanna
            })
          }
        })
        try {
          let { data } = await quickOrder({
            data: param,
            total: this.hardwaresPrice
          })
          this.$message.success('创建订单成功,请选择支付方式')
          this.order_number = data.order_number
          this.payType = ''
          this.selectPayType = true
          this.reLogin = false
        } catch (err) {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        }
      
    },
    async changePayType (e) {
      let mode  = e.target.value
      let that_ = this;
      try {
            let { data } = await payOrder({
                mode,
                order_number: that_.order_number
            })

            console.log(mode);

            if (mode == 1) {
              // 微信
              that_.showWxScan = true;
              that_.$refs.qrCodeUrl.innerHTML = ""; //先清除二维码
              var qrcode = new QRCode(that_.$refs.qrCodeUrl, {
                text: data.url,
                width: 150,
                height: 150,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
            if (mode == 2) {
              //支付宝
              document.getElementById("aliPay").innerHTML = data;
              const div = document.createElement("div"); // 创建div
              div.innerHTML = data; // 将返回的form 放入div
              document.getElementById("aliPay").appendChild(div);
              //document.forms[0].submit();
              let aliForm = document.querySelector("#aliPay form");
              aliForm.submit();
            }
            if (mode == 3) {
              //企业网银
              document.getElementById("unionPay").innerHTML = data;
              const div = document.createElement("div"); // 创建div
              div.innerHTML = data; // 将返回的form 放入div
              document.getElementById("unionPay").appendChild(div);
              document.forms[0].submit();
            }
            if (mode == 4) {
              //线下
              that_.showUnderLine = true
              that_.selectPayType = false
            }
            if (mode == 6) {
              //快捷支付

              document.getElementById("quickPay").innerHTML = data;
              const div = document.createElement("div"); // 创建div
              div.innerHTML = data; // 将返回的form 放入div
              document.getElementById("quickPay").appendChild(div);
              document.forms[0].submit();
            }
          } catch (err) {
            // let { errors } = err.data;
            // for (let i in errors) {
            //   this.$message.error(errors[i][0]);
            // }
          }

    },
    /*
     *@functionName: 选择支付方式-取消
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 16:36:03
    */
    noSurePayType () {
      this.$message.info('当前订单已创建,您可以到订单与发票管理中进行管理')
      this.payType = ''
      if (this.$refs.qrCodeUrl) {
        this.$refs.qrCodeUrl.innerHTML = ""; //清除微信付款二维码
      }
      this.showWxScan = false;
      this.selectPayType = false
    },

    /*
     *@functionName: 微信支付成功
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-07 10:12:57
    */
    async wxPaySuccess () {
      if(this.defaultActiveKey == '4'){
        await this.updateAuths()
      }
      this.payType = ''
      if (this.$refs.qrCodeUrl) {
        this.$refs.qrCodeUrl.innerHTML = ""; //清除微信付款二维码
      }
      this.showWxScan = false;
      this.selectPayType = false
    },
    async getPolicyFn(){
      let that = this;
      let parames = {}
      getPolicy(parames).then((res) => {
          let info = res.data.data;
          that.serviceContent = info.service.content;
          that.privacyContent = info.privacy.content;
          that.rulesContent   = info.UpgradeRules.content;
      })
      .catch((err) => {
        
      });
    }

  },
};
</script>
<style lang='less' scoped>
.container {
  .detail-wrapper {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        font-size: 20px;
        color: #ff833d;
      }
      .title {
        font-weight: bold;
        font-size: 20px;
        width: 100%;
        text-align: center;
      }
    }
  }
  .no-data {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: gray;
  }
  .options {
    display: flex;
    flex-wrap: wrap;
    .option-card {
      margin: 20px;
      border-radius: 5px;
      overflow: hidden;
      min-width: 300px;
      max-width: 300px;
      width: 300px;
      border: 1px solid #f0f2f5;
      .top {
        background-color: #f0f2f5;
        height: 50px;
        flex-direction: column;
        .title {
          font-size: 15px;
        }
      }
      .body {
        height: 220px;
/*        padding: 20px;*/
      }
      .example-wrapper {
        padding: 5px;
        display: flex;
        justify-content: flex-end;
        .example-text {
          font-size: 15px;
          font-weight: bold;
          color: #ff833d;
          cursor: pointer;
        }
      }
      .bottom {
        background-color: #f0f2f5;
        height: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .button {
          width: 100%;
          height: 100%;
          line-height: 40px;
        }
        .try-option,
        .buy-option {
          width: 50%;
          height: 100%;
          line-height: 40px;
          position: relative;
        }
        .try-option::after {
          content: "";
          width: 1px;
          height: 40px;
          background: lightgray;
          position: absolute;
          right: 0;
        }
      }
      .bottom-un {
        background-color: #ff833d;
        height: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .button {
          width: 100%;
          height: 100%;
          line-height: 40px;
        }
        .try-option,
        .buy-option {
          width: 50%;
          height: 100%;
          line-height: 40px;
          position: relative;
        }
        .try-option::after {
          content: "";
          width: 1px;
          height: 40px;
          background: lightgray;
          position: absolute;
          right: 0;
        }
      }
    }
    .active {
      border: 1px solid #ff833d;
    }
  }
}
.header{
  display: flex;
  justify-content: space-between;
  padding:20px 20px 0 20px;
  height: 40px;
  overflow: hidden;
}
.header-info{
  display: flex;
  justify-content: flex-start;
  line-height: 18px;
}
.header-info-title{
  font-weight: 800;
  font-size: 16px;
}
.header-info-price{
  color: #ff833d;
  margin-left: 10px;
}
.header-rigth{
  
}
.body-content{
  margin-top: 8px;
  padding: 0 20px;
  height: 42px;
  overflow: hidden;
}
.show_box {
    font-size: 20px;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-top: 10px;
    background-color: white;
    min-width: 875px;
    .expand_modules {
      display: flex;
      flex-wrap: wrap;
    }
    .flex_wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .module_card {
      margin: 10px;
    }
  }
</style>
