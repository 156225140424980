<template>
  <div class='header-right_wrap'>
    
    <div class="right-wrap">
      <div class="right-line mt-5">
        <!-- 食堂名称和ID可能会超出，title属性也加一下 -->
        <span class="styf-title mr-5" title="">{{inst_title}}</span>
        <span class="styf-id mr-5" title="">(ID:{{inst_id}})</span>
        <a href="javascript:void(0);" class="styf-mini-btn" @click="changeInst">切换</a>
      </div>
      <div class="right-line mb-5">
        <span class="valid-time mr-10">{{exp_time}} 到期</span>
        <a href="javascript:void(0);" class="styf-mini-btn mr-10" @click="reNew">{{btnName}}</a>
        <a-icon type="customer-service" class="mr-10" style="font-size:20px;" @click="showKeFuCode"/>
        <a href="javascript:void(0);" class="styf-mini-btn" @click="showKeFuCode">专属客服</a>
      </div>
      <!--
      <div :class="agent_inst ? 'top-wrap_100' : 'top-wrap_50'">
        <div class="name">{{linkman}}</div>
        <div class="logout" @click="logOut">退出</div>
      </div>
      <div class="bottom-wrap" v-if="!agent_inst">
        <div class="expiration-time">
          {{exp_time}} 到期
        </div>
        <div class="btn" @click="reNew">{{btnName}}</div>
        <div class="btn" @click="showKeFuCode">联系客服</div>
      </div>-->
    </div>
    <div class="avatar-wrap">
      <a-avatar :size="48" icon="user" style="backgroundColor:#ff833d;margin:10px;" :src="head_img"/>
      <a-dropdown>
        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
          {{linkman}} <a-icon type="down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a href="javascript:;" @click="logOut">退出</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    agent_inst: {
      default: true
    },
  },
  data(){
    return {
      head_img: localStorage.getItem('head_img'),
      inst_id: JSON.parse(localStorage.getItem('inst')).id,
      inst_title: JSON.parse(localStorage.getItem('inst')).title,
      linkman: JSON.parse(localStorage.getItem('inst')).linkman,
      exp_time: JSON.parse(localStorage.getItem('inst')).version.exp_time,
      btnName:JSON.parse(localStorage.getItem('inst')).version.version == 1?'续费':'购买'
    }
  },
  computed:{},
  components: {},
  created(){},
  mounted(){},
  methods: {
    logOut() {
      this.$confirm({
        content: "是否退出当前账户",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$store.commit("REMOVE_COUNT");
        },
      });
    },
    showKeFuCode(){
      this.$emit('showKeFu',{ showCode: true })
    },
    reNew(){
      this.$router.push({
        path: "/VersionInformation",
      });
      this.$store.commit('UPDATE_SELECTEDKEYS',['/VersionInformation'])
    },
    changeInst(){
      this.$emit('showChangeInst',{ showInst: true })
    }
  }
}
</script>
<style lang='less' scoped>
.header-right_wrap{
  display: flex;
  flex-direction: row;
  color: white;
  -o-user-select: none;
  -moz-user-select: none; /*火狐 firefox*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10+*/
  -khtml-user-select :none; /*早期的浏览器*/
  user-select: none; 
  .avatar-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    a{
      color:#fff;
    }
  }
  .top-wrap_100{
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 20px;
  }
  .top-wrap_50{
    display: flex;
    align-items: center;
    height: 50%;
    padding-left: 20px;
  }
  .bottom-wrap{
    display: flex;
    align-items: center;
    height: 50%;
    padding-left: 20px;
  }
  .name{
    font-weight: bold;
    font-size: 15px;
    height: 100%;
    display: flex;
    align-items: center;  
  }
  .logout{
    height: 100%;
    display: flex;
    align-items: center; 
    padding: 10px; 
  }
  .logout:hover{
    cursor: pointer;
  }
  .expiration-time{
    height: 100%;
    display: flex;
    align-items: center;  
  }
  .btn{
    border: 1px solid white;
    margin-left: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 1px 10px;
    border-radius: 10px;
  }
  .btn:hover{
    cursor: pointer;
  }
}
.mt-5{margin-top:5px;}
.mb-5{margin-bottom:5px;}
.mr-5{margin-right:5px;}
.mr-10{margin-right:10px;}
.right-line{
  height:35px;
  line-height:35px;
  text-align:right;
  display: flex;
  align-items:center;
  justify-content:flex-end;
  .styf-title{
    display: inline-block;
    max-width:131px;
    overflow: hidden;
    height:35px;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }
  .styf-id{
    display: inline-block;
    width:75px;
    overflow: hidden;
    height:35px;
    text-align:center;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }
  .styf-mini-btn{
    display: inline-block;
    color:#fff;
    padding:3px; 
    border:1px solid #EEE;
    border-radius:6px;
    height:auto;
    line-height:18px;
  }
}
</style>
