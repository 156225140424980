/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import QS from 'qs';
import {
  message
} from 'ant-design-vue';
import store from '../store/index'
import router from '@/router'

//根据环境判断域名 正式域名：https://stgl.51baocan.cn 测试域名：https://business.51baocan.com
// let baseUrl = "/api"
let baseUrl = process.env.VUE_APP_URL;
export {baseUrl as baseUrl} //导出给api.js使用

axios.defaults.baseURL = baseUrl



// 请求超时时间
axios.defaults.timeout = 120000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = 'Bearer' + ' ' + localStorage.getItem('token');
    
    token && (config.headers['Authorization'] = token);
    const inst = JSON.parse(localStorage.getItem('inst'));
    if(inst){
      let exp_time = new Date(inst.version.exp_time + ' 23:59:59').getTime(),
          now_day = new Date().getTime(),
          now_version = inst.version.version;//当前食堂是什么版本(0免费版 1商业版 2定制版 3商业使用版 4老会员)
      // if(now_version == 1){
      //   if(now_day > exp_time){
      //     //1.判断是否商业版；2.判断当前请求时间大于过期时间   满足条件则退出登陆
      //     message.error('您当前登陆版本与系统不符合，需要进行重新登陆');
      //     localStorage.removeItem('token'); // 清除token             
      //     localStorage.removeItem('isAdmin'); //清除管理员信息
      //     store.commit('REMOVE_COUNT', null);
      //     // 跳转登录页面
      //     setTimeout(() => {
      //       router.replace({
      //         path: '/login',
      //       });
      //     }, 2000);
      //     return
      //   } 
      // }
    }
    //如果使用免密登录,则添加免密登录标识,每次请求都携带该标识
    const easyLogin = localStorage.getItem('Login-Type');
    const adminid   = localStorage.getItem('adminid');
    easyLogin && (config.headers['Login-Type'] = easyLogin) && (config.headers['adminid'] = adminid);

    return config;
    // config.headers['Authorization'] = 'Bearer' + ' ' + localStorage.getItem('token') || ''
  },
  error => {
    return Promise.error(error);
  })

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况    
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          message.error('登录过期，请重新登录');
          localStorage.removeItem('token'); // 清除token             
          localStorage.removeItem('isAdmin'); //清除管理员信息
          store.commit('REMOVE_COUNT', null);
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.replace({
              path: '/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000);
          break;
        // 404请求不存在                
        case 404:
          message.error('网络请求不存在');
          // 跳转登录404页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.replace({
              path: '/404',
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000);
          break;
        case 403:
          message.error('您没有访问的权限');
          break;

        // 其他错误，直接抛出错误提示                
        default:
          // message.error(error.response.data.msg);
          console.log('http.js中封装的请求状态码的未封装的错误数据：')
          console.log(error)
      }
      return Promise.reject(error.response);
    }
  }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(params))
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
/** 
 * responseType:'blob'的post方法，用于处理后端返回的文件流格式下载成excle表格 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function postBlob(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(params), {
      responseType: 'blob'
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
/** 
 * 不对参数进行序列化的post方法，使用与上传文件的接口
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function postWithOutQS(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
//用于get请求的下载模板，解决文件下载后乱码的问题
export function getBlob(url, params) {
  return new Promise((resolve) => {
    axios({
      method: 'get',
      url: url,
      params: params,
      responseType: 'blob'
    }).then(res => {
      resolve(res);
    }).catch(err => {
      resolve(err);
    })
  })
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function patch(url, params) {
  return new Promise((resolve, reject) => {
    axios.patch(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
        message.error('putError')
      })
  });
}