<template>
  <div :class="version == 1 || version == 3 && isAdmin ? 'container' : 'container_img'">
    <div v-if="version == 1 || version == 3 && isAdmin" class="header">
      <div class="title">当前视图模式</div>
      <a-select
        :value="viewType"
        class="viewType"
        @change="changeType"
        :options="viewOptions"
        :disabled="timeSelect"
      >
      </a-select>
      <!-- 限制2020年4月1号 之前的数据不能查询-->
      <a-date-picker
        v-if="viewType == 1"
        class="datePicker"
        :disabled-date="disabledDate"
        @change="onChangeDay"
        :value="dateMoment"
        :allowClear="false"
        :disabled="timeSelect"
      />
      <!-- 限制必须查询7天 -->
      <a-range-picker
        v-if="viewType == 2"
        class="rangePicker"
        :disabled-date="disabledDate"
        @change="onChangeRange"
        :value="dateRangeMoment"
        :allowClear="false"
        :disabled="timeSelect"
      />
      <a-month-picker
        v-if="viewType == 3"
        class="monthPicker"
        :disabled-date="disabledDate"
        @change="onChangeMonth"
        :value="monthMoment"
        :allowClear="false"
        :disabled="timeSelect"
      />
      <a-icon
        type="question-circle"
        class="question-mealCase"
        @click="showTip = true"
      />
      <!-- <div class="download" @click="pageToPfdFn">
        <a-icon type="download" />下载
      </div> -->
    </div>

    <div v-if="version == 1 || version == 3 && isAdmin" class="mainBox" ref="mainBox">
      <div class="left">
        <!-- 报餐营收 -->
        <div class="border w100p p_relative bcysBox">
          <!-- 饼状图 -->
          <div class="w100p">
            <My-echart
              v-if="viewType == 1 || viewType == 3"
              class="w100p"
              :id="'bcysDay'"
              :echartOption="bcysDayOption"
            />
          </div>
          <!-- 柱状图 -->
          <div class="w100p">
            <My-echart
              v-if="viewType == 2"
              class="w100p"
              :id="'bcysSection'"
              :echartOption="bcysSectionOption"
            />
          </div>
          <div class="loading" v-if="bcysLoading">
            <a-icon type="loading" />加载中...
          </div>
        </div>

        <!-- 扫码营收 -->
        <div class="border smysBox">
          <!-- 饼状图 -->
          <div class="w100p">
            <My-echart
              v-if="viewType == 1 || viewType == 3"
              :id="'smysDay'"
              :echartOption="smysDayOption"
            />
          </div>
          <!-- 柱状图 -->
          <div class="w100p">
            <My-echart
              v-if="viewType == 2"
              :id="'smysSection'"
              :echartOption="smysSectionOption"
            />
          </div>
          <div class="loading" v-if="smysLoading">
            <a-icon type="loading" />加载中...
          </div>
        </div>

        <!-- 小卖部营收 -->
        <div class="border w100p p_relative xmbysBox">
          <!-- 饼状图 -->
          <div class="w100p">
            <My-echart
              v-if="viewType == 1 || viewType == 3"
              :id="'smbsy'"
              :echartOption="smbsyOption"
            />
          </div>
          <!-- 柱状图 -->
          <div class="w100p">
            <My-echart
              v-if="viewType == 2"
              :id="'smbsySection'"
              :echartOption="smbsySectionOption"
            />
          </div>
          <div class="loading" v-if="smbysLoading">
            <a-icon type="loading" />加载中...
          </div>
        </div>
      </div>

      <div class="center">
        <div class="mealCaseBox">
          <h2 class="title" style="">用餐情况</h2>

          <a-row class="text-center">
            <a-col :span="6">
              <h2 class="item-title">报餐份数</h2>
              <h2 class="item-data">{{ useDinnerCase.apply_dinners }}</h2>
            </a-col>
            <a-col :span="6">
              <h2 class="item-title">用餐份数</h2>
              <h2 class="item-data">{{ useDinnerCase.used_dinner }}</h2>
            </a-col>
            <a-col :span="6">
              <h2 class="item-title">退餐率</h2>
              <h2 class="item-data">{{ useDinnerCase.haved_dinner }}</h2>
            </a-col>
            <a-col :span="6">
              <h2 class="item-title">人均餐费</h2>
              <h2 class="item-data">{{ useDinnerCase.per_cost }}</h2>
            </a-col>
          </a-row>
          <a-row class="text-center">
            <a-col :span="6">
              <h2 class="item-title">已退餐</h2>
              <h2 class="item-data">{{ useDinnerCase.returned_dinner }}</h2>
            </a-col>
            <!-- <a-col :span="6">
              <h2 class="item-title">报餐人数</h2>
              <h2 class="item-data">{{ useDinnerCase.meal_users }}</h2>
            </a-col>
            <a-col :span="6">
              <h2 class="item-title">报餐率</h2>
              <h2 class="item-data">{{ useDinnerCase.meal_rate }}</h2>
            </a-col> -->
            <a-col :span="6">
              <h2 class="item-title">评价数量</h2>
              <h2 class="item-data">{{ useDinnerCase.praise }}</h2>
            </a-col>
          </a-row>
        </div>

        <!-- 系统人数以及职务部门 -->
        <div class="border system_data">
          <div class="system_left">
            <h2 class="min_width_110" style="font-size: 20px">
              系统人数
              <span class="item_title">{{ sysUsedPerson.total }}</span>
            </h2>
            <h2
              class="min_width_110"
              v-for="(item, index) in sysUsedPerson.data"
              :key="index"
            >
              {{
                item.name == "剩余可用人数"
                  ? "未使用"
                  : item.name == "已用人数"
                  ? "已使用"
                  : item.name == "白名单人数"
                  ? "白名单"
                  : item.name == "待审核人数"
                  ? "待审核"
                  : ""
              }}
              <span class="item_data">{{ item.value }}</span>
            </h2>
          </div>
          <div v-if="sysUsedPerson.total != '无限制'" class="system_center">
            <My-echart
              class="w100p"
              :id="'systemPerson'"
              :echartOption="systemPersonOption"
            />
            <div
              class="loading"
              v-if="sysUsedPerson.total != '无限制' && systemLoading"
            >
              <a-icon type="loading" />加载中...
            </div>
          </div>
          <div
            :class="
              sysUsedPerson.total != '无限制'
                ? 'system_right_37'
                : 'system_right_74'
            "
          >
            <div class="post_box">
              <My-echart :id="'post'" :echartOption="postOption" />
              <div class="loading" v-if="postLoading">
                <a-icon type="loading" />加载中...
              </div>
            </div>
            <div class="office_box">
              <My-echart :id="'office'" :echartOption="officeOption" />
              <div class="loading" v-if="officeLoading">
                <a-icon type="loading" />加载中...
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <!-- 好评分布 -->
        <div class="border w100p p_relative hpfbBox">
          <My-echart :id="'goodPraise'" :echartOption="goodPraiseOption" />
          <div class="loading" v-if="goodPraiseLoading">
            <a-icon type="loading" />加载中...
          </div>
        </div>

        <!-- 扫码情况 -->
        <div class="border w100p margin_5_0 p_relative smqkBox">
          <My-echart :id="'scanCase'" :echartOption="scanCaseOption" />
          <div class="loading" v-if="scanCaseLoading">
            <a-icon type="loading" />加载中...
          </div>
        </div>

        <!-- 小卖部情况 -->
        <div class="border w100p p_relative xmbqkBox">
          <My-echart :id="'shopCase'" :echartOption="shopCaseOption" />
          <div class="loading" v-if="shopCaseLoading">
            <a-icon type="loading" />加载中...
          </div>
        </div>
      </div>
    </div>

    <!-- 面板字段详情展示 -->
    <a-modal v-model="showTip" title="数据面板各字段详情" :width="1200">
      <div class="tips_body">
        <div>
          <div>
            <p class="theme_font_color">报餐营收：</p>
            <p class="pl_20">所查询天/星期/月的报餐营收数据</p>
          </div>
          <div>
            <p class="theme_font_color">扫码营收：</p>
            <p class="pl_20">所查询天/星期/月的扫码营收数据</p>
          </div>
          <div>
            <p class="theme_font_color">小卖部营收:</p>
            <p class="pl_20">所查询天/星期/月的小卖部营收数据</p>
          </div>
        </div>
        <div>
          <div>
            <p class="theme_font_color">用餐情况:</p>
            <p class="pl_20">报餐份数：包含已退餐</p>
            <p class="pl_20">用餐份数：所有已核销订单</p>
            <p class="pl_20">退餐率：已退餐÷报餐份数</p>
            <p class="pl_20">人均餐费：报餐营收总金额÷用餐份数</p>
            <p class="pl_20">已退餐：已取消的订单</p>
            <p class="pl_20">评价数量：对已结束的订单的评价数量</p>
          </div>
          <div>
            <p class="theme_font_color">系统使用情况:</p>
            <p class="pl_20">系统人数：当前系统版本可用人数上限</p>
            <p v-if="sysUsedPerson.total != '无限制'" class="pl_20">
              未使用：当前系统版本剩余可用人数
            </p>
            <p class="pl_20">已使用：当前系统版本已使用人数（包含白名单）</p>
            <p class="pl_20">白名单：当前系统版本白名单人数</p>
            <p class="pl_20">待审核：当前系统版本待审核人数</p>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p class="theme_font_color">好评分布Top5：</p>
            <p class="pl_20">所查询天/星期/月的好评分布前五数据</p>
          </div>
          <div>
            <p class="theme_font_color">扫码情况Top5：</p>
            <p class="pl_20">
              所查询天/星期/月的扫码情况Top5前五数据（总量为前五的总和）
            </p>
          </div>
          <div>
            <p class="theme_font_color">小卖部情况：</p>
            <p class="pl_20">所查询天/星期/月的小卖部情况数据</p>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="showTip = false" type="primary">知道了</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {
  getInstInfo,
  getMealReport,
  getScanCode,
  getPostOffice,
  getGoodPraise,
  getScanCase,
  getUseDinnerCase,
  getSysInfo,
  getShopIncome,
  getShopCase,
} from "@/request/api";
import util from "@/utils/util";
import MyEchart from "@/components/common/MyEchart.vue";
import moment from "moment";
export default {
  components: {
    MyEchart,
  },
  data() {
    return {
      //当前版本
      version: JSON.parse(localStorage.getItem("inst")).version.version,
      isAdmin: localStorage.getItem("isAdmin"),
      //当前视图
      viewOptions: [
        {
          value: 1,
          label: "天",
        },
        {
          value: 2,
          label: "星期",
        },
        {
          value: 3,
          label: "月",
        },
      ],
      //当前视图
      timeSelect: false,
      viewType: 1,
      // 查询参数
      searchParam: "",
      start_date: moment().subtract(1, "day").format("YYYY-MM-DD"),
      end_date: "",
      dateMoment: moment().subtract(1, "day"),
      dateRangeMoment: [
        moment().subtract(7, "day"),
        moment().subtract(1, "day"),
      ],
      monthMoment: moment().format("YYYY-MM"),
      //用餐情况
      useDinnerCase: {
        apply_dinners: 0, //报餐份数
        used_dinner: 0, //用餐份数
        haved_dinner: 0, //退餐率
        per_cost: 0, //人均餐费
        returned_dinner: 0, //已退餐
        // meal_users: 0, //报餐人数
        // meal_rate: 0, //报餐率
        praise: 0, //好评数量
      },

      //系统人数使用情况
      sysUsedPerson: {
        total: 0,
        data: [],
      },

      bcysLoading: false,
      // 报餐营收-饼状图配置
      bcysDayOption: {
        title: {
          text: ["报餐营收     {a|￥0}"],
          textStyle: {
            rich: {
              a: {
                color: "#ff833d",
                fontSize: 20,
                // fontWeight: 'bold'
              },
            },
          },
          left: "10",
          top: "10",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          right: "right",
          top: "70",
          textStyle: {
            fontSize: 8,
          },
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            height: "250",
            top: "0",
            right: "100",
            labelLine: {
              length: 5,
              length2: 5,
            },
            label: {
              fontSize: 8,
            },
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },

      //报餐营收-柱状图
      bcysSectionOption: {
        title: {
          text: ["报餐营收     {a|￥0}"],
          textStyle: {
            rich: {
              a: {
                color: "#ff833d",
                fontSize: 20,
                // fontWeight: 'bold'
              },
            },
          },
          left: "10",
          top: "10",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: [],
          top: "50",
          type: "scroll",
        },
        grid: {
          left: "3%",
          right: "4%",
          // top: '39',
          bottom: "0",
          containLabel: true,
          height: "150",
        },
        xAxis: [
          {
            type: "category",
            data: [],
            // axisLabel: {
            //   fontSize: 6
            // }
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [],
      },

      smysLoading: false,
      //扫码营收-饼状图
      smysDayOption: {
        title: {
          text: ["扫码营收     {a|￥0}"],
          textStyle: {
            rich: {
              a: {
                color: "#ff833d",
                fontSize: 20,
                // fontWeight: 'bold'
              },
            },
          },
          left: "10",
          top: "10",
        },
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "扫码营收",
            type: "pie",
            radius: ["30%", "50%"],
            height: "100",
            top: "80",
            // left: '30',
            center: "center",
            labelLine: {
              length: 5,
              length2: 5,
            },
            label: {
              formatter: "{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ",
              backgroundColor: "#F6F8FC",
              borderColor: "#8C8D8E",
              borderWidth: 1,
              borderRadius: 4,
              // width: 80,
              // height: 30,
              fontSize: 8,
              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 10,
                  align: "center",
                  fontSize: 8,
                },
                hr: {
                  borderColor: "#8C8D8E",
                  width: "100%",
                  borderWidth: 1,
                  height: 0,
                  // fontSize: 10,
                },
                b: {
                  color: "#4C5058",
                  fontSize: 8,
                  fontWeight: "bold",
                  lineHeight: 10,
                  // width: 10
                },
                per: {
                  color: "#fff",
                  backgroundColor: "#4C5058",
                  padding: [3, 4],
                  borderRadius: 4,
                  fontSize: 8,
                },
              },
            },
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 20,
              borderColor: "#fff",
              borderWidth: 2,
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: '40',
              //   fontWeight: 'bold'
              // }
            },
            data: [],
          },
        ],
      },

      //扫码营收-柱状图
      smysSectionOption: {
        title: {
          text: ["扫码营收     {a|￥0}"],
          textStyle: {
            rich: {
              a: {
                color: "#ff833d",
                fontSize: 20,
                // fontWeight: 'bold'
              },
            },
          },
          left: "10",
          top: "10",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: [],
          top: "50",
          type: "scroll",
        },
        grid: {
          left: "3%",
          right: "4%",
          // top: '39',
          bottom: "0",
          containLabel: true,
          height: "100",
        },
        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [],
      },

      smbysLoading: false,
      //小卖部营收饼状图
      smbsyOption: {
        title: {
          text: ["小卖部营收     {a|￥0}"],
          textStyle: {
            rich: {
              a: {
                color: "#ff833d",
                fontSize: 20,
                // fontWeight: 'bold'
              },
            },
          },
          left: "10",
          top: "10",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          right: "right",
          top: "35",
          left: "10",
          // orient: 'vertical',
          textStyle: {
            fontSize: 8,
          },
          type: "scroll",
        },
        series: [
          {
            type: "pie",
            // radius: ['20%', '100%'],
            radius: [10, 50],
            // center: ['75%', '50%'],
            roseType: "area",
            height: "80",
            top: "80",
            // right: '80',
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              fontSize: "8",
              formatter: function (params) {
                // console.log(params)//这里输出params所有参数 需要什么下面自己拼
                return (
                  params.name + "   " + (params.percent - 0).toFixed(0) + "%"
                );
              },
            },
            labelLine: {
              length: 5,
              length2: 5,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "10",
                fontWeight: "bold",
              },
            },
            data: [],
          },
        ],
      },

      //小卖部营收柱状图
      smbsySectionOption: {
        title: {
          text: ["小卖部营收     {a|￥0}"],
          textStyle: {
            rich: {
              a: {
                color: "#ff833d",
                fontSize: 20,
                // fontWeight: 'bold'
              },
            },
          },
          left: "10",
          top: "10",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: [],
          top: "50",
          type: "scroll",
        },
        grid: {
          left: "3%",
          right: "4%",
          // top: '39',
          bottom: "0",
          containLabel: true,
          height: "100",
        },
        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [],
      },

      systemLoading: false,
      //系统人数使用情况
      systemPersonOption: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: true,
          bottom: "bottom",
          orient: "horizontal",
          textStyle: {
            fontSize: 8,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            top: 40,
            height: 150,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              fontSize: 8,
            },
            labelLine: {
              length: 5,
              length2: 5,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "10",
                fontWeight: "bold",
              },
            },
            data: [],
          },
        ],
      },

      officeLoading: false,
      //部门柱状图
      officeOption: {
        tooltip: {
          show: true,
        },
        title: {
          text: "部门Top5     总数量 {a|0}",
          left: "20",
          top: "10",
          textStyle: {
            rich: {
              a: {
                color: "#ff833d",
                fontSize: 20,
              },
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "40",
          containLabel: true,
          height: "110",
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            interval: 0,
            formatter: function (name) {
              return name.length > 4 ? name.slice(0, 4) + "..." : name;
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 12,
          },
        ],
      },

      postLoading: false,
      //职务柱状图
      postOption: {
        tooltip: {
          show: true,
        },
        title: {
          text: "职务Top5     总数量 {a|0}",
          left: "20",
          top: "10",
          textStyle: {
            rich: {
              a: {
                color: "#ff833d",
                fontSize: 20,
              },
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "40",
          containLabel: true,
          height: "110",
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            formatter: function (name) {
              return name.length > 4 ? name.slice(0, 4) + "..." : name;
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 12,
          },
        ],
      },

      goodPraiseLoading: false,
      // 好评分布柱状图
      goodPraiseOption: {
        tooltip: {
          show: true,
        },
        title: {
          text: "好评分布Top5",
          left: "10",
          top: "10",
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "39",
          containLabel: true,
          height: "160",
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            data: [],
            type: "bar",
          },
        ],
      },

      scanCaseLoading: false,
      //扫码情况柱状图
      scanCaseOption: {
        tooltip: {
          show: true,
        },
        title: {
          text: "扫码情况Top5",
          left: "10",
          top: "10",
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "39",
          containLabel: true,
          height: "150",
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [], //最多显示5个类别(前5)，总量要放数组最后
          axisLabel: {
            formatter: function (name) {
              return name.length > 4 ? name.slice(0, 4) + "..." : name;
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
          },
        ],
      },

      shopCaseLoading: false,
      //小卖部情况
      shopCaseOption: {
        tooltip: {
          show: true,
        },
        title: {
          text: "小卖部情况",
          left: "10",
          top: "10",
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "39",
          containLabel: true,
          height: "150",
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [], //最多显示5个类别(前5)，总量要放数组最后
          // axisLabel: {
          //   formatter: function (name) {
          //     return (name.length > 4 ? (name.slice(0, 4) + "...") : name);
          //   },
          // }
        },
        series: [
          {
            data: [],
            type: "bar",
          },
        ],
      },

      bcysSourceData: {},
      showTip: false,
    };
  },

  computed: {},
  watch: {},
  methods: {
    moment,

    disabledDate(current) {
      if (!current) {
        return false;
      } else {
        return current < moment("2021-04-01");
      }
    },

    //切换当前视图模式
    changeType(v) {
      this.viewType = v;
      if (this.viewType == 1) {
        //天
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = "";
      }
      if (this.viewType == 2) {
        //星期
        this.start_date = moment().subtract("days", 7).format("YYYY-MM-DD");
        this.end_date = moment().subtract("days", 1).format("YYYY-MM-DD");
      }
      if (this.viewType == 3) {
        //月
        this.start_date = moment().format("YYYY-MM");
        this.end_date = "";
      }
      this.getAllData();
      this.timeSelect = true;
      setTimeout(() => {
        this.timeSelect = false;
      }, 5000);
    },

    //选择日期-天
    onChangeDay(date, dateStr) {
      this.dateMoment = date;
      this.start_date = dateStr;
      this.end_date = "";
      this.getAllData();
      this.timeSelect = true;
      setTimeout(() => {
        this.timeSelect = false;
      }, 5000);
    },

    //选择日期范围  限制只能选7天
    onChangeRange(date, dateStr) {
      let timeDiff = date[1].diff(date[0], "day");
      if (timeDiff != 6) {
        this.$message.info("请选择7天");
        return;
      }
      this.dateRangeMoment = date;
      this.start_date = dateStr[0];
      this.end_date = dateStr[1];
      this.getAllData();
      this.timeSelect = true;
      setTimeout(() => {
        this.timeSelect = false;
      }, 5000);
    },

    //选择月份
    onChangeMonth(date, dateStr) {
      this.monthMoment = date;
      this.start_date = dateStr;
      this.end_date = "";
      this.getAllData();
      this.timeSelect = true;
      setTimeout(() => {
        this.timeSelect = false;
      }, 5000);
    },

    //获取报餐营收数据
    async getMealReportFn() {
      this.bcysLoading = true;
      try {
        let param = {
          type: this.viewType,
          start_date: this.start_date,
          end_date: this.viewType == 2 ? this.end_date : "",
        };
        let { data } = await getMealReport(param);
        this.bcysSourceData = data;
        let isEmpty =
          data.length != 0 ? data.data.every((item) => item.value == 0) : true;
        if (isEmpty == false) {
          //有数据
          if (this.viewType == 2) {
            //配置柱状图
            this.bcysSectionOption.title.text[0] = `报餐营收     {a|￥${data.total.toFixed(
              2
            )}}`;

            let result = data.data;
            for (let i in result) {
              for (let j in result[i].data) {
                result[i].data[j] = result[i].data[j].toFixed(2) * 1;
              }
            }
            
            let legendData = [];
            result.map((item) => {
              legendData.push(item.name);
            });
            legendData.push("总量");
            this.bcysSectionOption.legend.data = legendData;
            this.bcysSectionOption.xAxis[0].data = data.time;
            let series = [];
            result.map((item) => {
              series.push({
                name: item.name,
                type: "bar",
                stack: "营收",
                emphasis: {
                  focus: "series",
                },
                label: {
                  show: false,
                },
                data: item.data,
              });
            });
            series.push({
              name: "总量",
              type: "line",
              stack: "",
              emphasis: {
                focus: "series",
              },
              label: {
                show: false,
              },
              data: this.countTotalFn(result),
            });
            this.bcysSectionOption.series = data.total == 0 ? [] : series;
          } else {
            //配置饼状图
            let fixedArr = data.data.map((item) => {
              return {
                ...item,
                value: item.value.toFixed(2) * 1,
              };
            });
            this.bcysDayOption.title.text[0] = `报餐营收     {a|￥${data.total.toFixed(
              2
            )}}`;
            this.bcysDayOption.series[0].data = data.total == 0 ? [] : fixedArr;
          }
        } else {
          //无数据
          if (this.viewType == 2) {
            //配置柱状图
            this.bcysSectionOption.title.text[0] = `报餐营收     {a|￥0}`;
            this.bcysSectionOption.legend.data = [];
            this.bcysSectionOption.xAxis[0].data = [];
            this.bcysSectionOption.series = [];
          } else {
            //配置饼状图
            this.bcysDayOption.title.text[0] = `报餐营收     {a|￥0}`;
            this.bcysDayOption.series[0].data = [];
          }
        }
        this.bcysLoading = false;
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取扫码营收数据
    async getScanCodeFn() {
      this.smysLoading = true;
      try {
        let param = {
          type: this.viewType,
          start_date: this.start_date,
          end_date: this.viewType == 2 ? this.end_date : "",
        };
        let { data } = await getScanCode(param);

        if (data.length != 0) {
          //有数据时
          if (this.viewType == 2) {
            //配置柱状图
            for (let i = 0; i < 7; i++) {
              for (let j in data.data) {
                data.data[j].data[i] =
                  Math.abs(data.data[j].data[i]).toFixed(2) * 1;
              }
            }
            
            this.smysSectionOption.title.text[0] = `扫码营收     {a|￥${Math.abs(
              data.total
            ).toFixed(2)}}`;
            let legendData = [];
            data.data.map((item) => {
              legendData.push(item.name);
            });
            legendData.push("总量");
            //扫码营收
            this.smysSectionOption.legend.data = legendData;
            this.smysSectionOption.xAxis[0].data = data.time;
            let series = [];
            data.data.map((item) => {
              series.push({
                name: item.name,
                type: "bar",
                stack: "营收",
                emphasis: {
                  focus: "series",
                },
                label: {
                  show: false,
                },
                data: item.data,
              });
            });
            series.push({
              name: "总量",
              type: "line",
              stack: "",
              emphasis: {
                focus: "series",
              },
              label: {
                show: false,
              },
              data: this.countTotalFn(data.data),
            });
            this.smysSectionOption.series = data.total == 0 ? [] : series; //扫码营收
          } else {
            //饼状图
            let absData = data.data.map((item) => {
              return {
                name: item.name,
                value: Math.abs(item.value).toFixed(2),
              };
            });
            this.smysDayOption.title.text[0] = `扫码营收     {a|￥${Math.abs(
              data.total
            ).toFixed(2)}}`;
            this.smysDayOption.series[0].data = data.total == 0 ? [] : absData;
          }
        } else {
          //无数据时
          if (this.viewType == 2) {
            //配置柱状图
            this.smysSectionOption.title.text[0] = `扫码营收     {a|￥0}`;
            this.smysSectionOption.legend.data = [];
            this.smysSectionOption.xAxis[0].data = [];
            this.smysSectionOption.series = []; //扫码营收
          } else {
            //配置饼状图
            this.smysDayOption.title.text[0] = `扫码营收     {a|￥0}`;
            this.smysDayOption.series[0].data = [];
          }
        }
        this.smysLoading = false;
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取小卖部营收数据
    async getShopIncomeFn() {
      this.smbysLoading = true;
      try {
        let param = {
          type: this.viewType,
          start_date: this.start_date,
          end_date: this.viewType == 2 ? this.end_date : "",
        };
        let { data } = await getShopIncome(param);

        if (data.length != 0) {
          //有数据时
          if (this.viewType == 2) {
            //配置柱状图
            this.smbsySectionOption.title.text[0] = `小卖部营收Top5 {a|￥${data.total.toFixed(
              2
            )}}`;
            //每项都四舍五入
            let fixedArr = data.data.map((item) => {
              return {
                ...item,
                data: item.data.map((subitem) => subitem.toFixed(2) * 1),
              };
            });
            if (fixedArr.length > 5) {
              //算出每一项的7天总和
              fixedArr.map((item) => {
                let total = 0;
                item.data.map((subitem) => {
                  total += subitem;
                });
                item.total = total;
              });
              //比较总和，取前四个，其余的累加成其他字段
              let top_4 = fixedArr
                  .sort((a, b) => b.total - a.total)
                  .slice(0, 4),
                others = fixedArr.sort((a, b) => b.total - a.total).slice(5);
              top_4.push({
                name: "其他",
                data: this.countTotalFn(others),
              });
              let legendData = [];
              top_4.map((item) => {
                legendData.push(item.name);
              });
              legendData.push("总量");
              this.smbsySectionOption.legend.data = legendData;
              this.smbsySectionOption.xAxis[0].data = data.time;
              let series = [];
              top_4.map((item) => {
                series.push({
                  name: item.name,
                  type: "bar",
                  stack: "营收",
                  emphasis: {
                    focus: "series",
                  },
                  label: {
                    show: false,
                  },
                  data: item.data,
                });
              });
              series.push({
                name: "总量",
                type: "line",
                stack: "",
                emphasis: {
                  focus: "series",
                },
                label: {
                  show: false,
                },
                data: this.countTotalFn(top_4),
              });
              this.smbsySectionOption.series = data.total == 0 ? [] : series; //小卖部营收
            } else {
              let legendData = [];
              fixedArr.map((item) => {
                legendData.push(item.name);
              });
              legendData.push("总量");
              this.smbsySectionOption.legend.data = legendData;
              this.smbsySectionOption.xAxis[0].data = data.time;
              let series = [];
              fixedArr.map((item) => {
                series.push({
                  name: item.name,
                  type: "bar",
                  stack: "营收",
                  emphasis: {
                    focus: "series",
                  },
                  label: {
                    show: false,
                  },
                  data: item.data,
                });
              });
              series.push({
                name: "总量",
                type: "line",
                stack: "",
                emphasis: {
                  focus: "series",
                },
                label: {
                  show: false,
                },
                data: this.countTotalFn(fixedArr),
              });
              this.smbsySectionOption.series = data.total == 0 ? [] : series; //小卖部营收
            }
          } else {
            //配置饼状图
            let fixedArr = data.data.map((item) => {
              return {
                ...item,
                value: item.value.toFixed(2) * 1,
              };
            });
            if (fixedArr.length > 5) {
              let top_4 = fixedArr
                  .sort((a, b) => b.value - a.value)
                  .slice(0, 4),
                others = fixedArr.sort((a, b) => b.value - a.value).slice(5),
                othersTotal = 0;
              others.map((item) => (othersTotal += item.value));
              top_4.push({
                name: "其他",
                value: othersTotal.toFixed(2) * 1,
              });
              this.smbsyOption.title.text[0] = `小卖部营收Top5 {a|￥${data.total.toFixed(
                2
              )}}`;
              this.smbsyOption.series[0].data = data.total == 0 ? [] : top_4;
            } else {
              this.smbsyOption.title.text[0] = `小卖部营收Top5 {a|￥${data.total.toFixed(
                2
              )}}`;
              this.smbsyOption.series[0].data = data.total == 0 ? [] : fixedArr;
            }
          }
        } else {
          //无数据时
          if (this.viewType == 2) {
            //配置柱状图
            this.smbsySectionOption.title.text[0] = `小卖部营收Top5 {a|￥0}`;
            this.smbsySectionOption.legend.data = [];
            this.smbsySectionOption.xAxis[0].data = [];
            this.smbsySectionOption.series = []; //小卖部营收
          } else {
            //配置饼状图
            this.smbsyOption.title.text[0] = `小卖部营收Top5 {a|￥0}`;
            this.smbsyOption.series[0].data = [];
          }
        }
        this.smbysLoading = false;
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取用餐情况
    async getUseDinnerCaseFn() {
      try {
        let param = {
          type: this.viewType,
          start_date: this.start_date,
          end_date: this.viewType == 2 ? this.end_date : "",
        };
        let { data } = await getUseDinnerCase(param);
        
        if (data.length != 0) {
          //有数据
          this.useDinnerCase = {
            apply_dinners:
              data.usedDinnerCase.apply_dinner +
              data.usedDinnerCase.returned_dinner,
            used_dinner: data.usedDinnerCase.used_dinner,
            haved_dinner: util.GetPercent(
              data.usedDinnerCase.returned_dinner,
              data.usedDinnerCase.apply_dinner +
                data.usedDinnerCase.returned_dinner
            ),
            per_cost: data.usedDinnerCase.used_dinner == 0 ? 0 : (
              this.bcysSourceData.total / data.usedDinnerCase.used_dinner
            ).toFixed(2),
            returned_dinner: data.usedDinnerCase.returned_dinner,
            // meal_users: data.usedDinnerCase.meal_users,
            // meal_rate: util.GetPercent(
            //   data.usedDinnerCase.meal_users,
            //   this.sysUsedPerson.data[1].value
            // ),
            praise: data.usedDinnerCase.praise,
          };
        } else {
          //无数据
          this.useDinnerCase = {
            apply_dinners: 0, //报餐份数
            used_dinner: 0, //用餐份数
            haved_dinner: 0, //退餐率
            per_cost: 0, //人均餐费
            returned_dinner: 0, //已退餐
            // meal_users: 0, //报餐人数
            // meal_rate: 0, //报餐率
            praise: 0, //好评数量
          };
        }
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取系统使用情况
    async getSysInfoFn() {
      this.systemLoading = true;
      try {
        let { data } = await getSysInfo();
        this.systemLoading = false;
        this.sysUsedPerson = data.sysUsedPerson;
        this.systemPersonOption.series[0].data = data.sysUsedPerson.data;
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取职务/部门柱状图 1部门 0职务
    async getPostOfficeFn(is_office) {
      this.postLoading = true;
      this.officeLoading = true;
      try {
        let param = {
          is_office,
        };
        let { data } = await getPostOffice(param);
        if(data.length == 0) {
          this.officeLoading = false;
          this.postLoading = false;
          return
        }
        if (is_office == 1) {
          //部门
          let offices = data.offices,
            dataLength = offices.data.length;
          this.officeOption.title.text = `部门Top5     总数量 {a|${offices.total}}`;
          if (dataLength > 1) {
            let newArr = [];
            for (let i = 0; i < dataLength; i++) {
              let obj = {
                name: offices.names[i],
                value: offices.data[i],
              };
              newArr.push(obj);
            }
            let sortArr = newArr.sort((a, b) => b.value - a.value);
            let oNames = [],
              oData = [];
            sortArr.map((item) => {
              oNames.push(item.name);
              oData.push(item.value);
            });
            offices.names = oNames.reverse();
            offices.data = oData.reverse();
          }
          this.officeOption.yAxis.data = offices.names;
          this.officeOption.series[0].data =
            offices.total == 0 ? [] : offices.data;
          this.officeLoading = false;
        } else {
          //职务
          let posts = data.posts,
            dataLength = posts.data.length;
          this.postOption.title.text = `职务Top5     总数量 {a|${posts.total}}`;
          if (dataLength > 1) {
            let newArr = [];
            for (let i = 0; i < dataLength; i++) {
              let obj = {
                name: posts.names[i],
                value: posts.data[i],
              };
              newArr.push(obj);
            }
            let sortArr = newArr.sort((a, b) => b.value - a.value);
            let oNames = [],
              oData = [];
            sortArr.map((item) => {
              oNames.push(item.name);
              oData.push(item.value);
            });
            posts.names = oNames.reverse();
            posts.data = oData.reverse();
          }
          this.postOption.yAxis.data = posts.names;
          this.postOption.series[0].data = posts.total == 0 ? [] : posts.data;
          this.postLoading = false;
        }
      } catch (err) {
        console.log(err)
        return
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取好评分布情况
    async getGoodPraiseFn() {
      this.goodPraiseLoading = true;
      try {
        let param = {
          type: this.viewType,
          start_date: this.start_date,
          end_date: this.viewType == 2 ? this.end_date : "",
        };
        let { data } = await getGoodPraise(param);
        let isEmpty = data.goodPraise.data.every((item) => item == 0);
        if (isEmpty == false) {
          //有数据
          let dataLength = data.goodPraise.data.length;
          if (dataLength > 1) {
            let newArr = [];
            for (let i = 0; i < dataLength; i++) {
              let obj = {
                name: data.goodPraise.name[i],
                value: data.goodPraise.data[i],
              };
              newArr.push(obj);
            }
            let sortArr = newArr.sort((a, b) => b.value - a.value);
            let oNames = [],
              oData = [];
            sortArr.map((item) => {
              oNames.push(item.name);
              oData.push(item.value);
            });
            data.goodPraise.name = oNames.reverse();
            data.goodPraise.data = oData.reverse();
          }
          this.goodPraiseOption.yAxis.data = data.goodPraise.name;
          this.goodPraiseOption.series[0].data = data.goodPraise.data;
        } else {
          //没数据
          this.goodPraiseOption.yAxis.data = [];
          this.goodPraiseOption.series[0].data = [];
        }
        this.goodPraiseLoading = false;
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取扫码情况
    async getScanCaseFn() {
      this.scanCaseLoading = true;
      try {
        let param = {
          type: this.viewType,
          start_date: this.start_date,
          end_date: this.viewType == 2 ? this.end_date : "",
        };
        let { data } = await getScanCase(param);
        if (data.length != 0) {
          //有数据

          let dataLength = data.data.length;
          if (dataLength > 1) {
            let newArr = [];
            for (let i = 0; i < dataLength; i++) {
              let obj = {
                name: data.name[i],
                value: data.data[i],
              };
              newArr.push(obj);
            }
            let sortArr = newArr.sort((a, b) => b.value - a.value);
            let oNames = [],
              oData = [];
            sortArr.map((item) => {
              oNames.push(item.name);
              oData.push(item.value);
            });
            data.name = oNames.reverse();
            data.data = oData.reverse();
          }

          let newName = JSON.parse(JSON.stringify(data.name)),
            total = 0;
          data.data.map((item) => {
            total += item;
          });
          let newData = JSON.parse(JSON.stringify(data.data));
          newName.push("总量");
          newData.push(total);
          this.scanCaseOption.yAxis.data = newName;
          this.scanCaseOption.series[0].data = newData;
        } else {
          //没数据
          this.scanCaseOption.yAxis.data = [];
          this.scanCaseOption.series[0].data = [];
        }
        this.scanCaseLoading = false;
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取小卖部情况
    async getShopCaseFn() {
      this.shopCaseLoading = true;
      try {
        let param = {
          type: this.viewType,
          start_date: this.start_date,
          end_date: this.viewType == 2 ? this.end_date : "",
        };
        let { data } = await getShopCase(param);
        let isEmpty = data.shopCase.data.every((item) => item == 0);
        if (isEmpty == false) {
          //有数据
          let newName = JSON.parse(JSON.stringify(data.shopCase.name)),
            total = 0;
          data.shopCase.data.map((item) => {
            total += item;
          });
          let newData = JSON.parse(JSON.stringify(data.shopCase.data));
          newName.push("总量");
          newData.push(total);
          this.shopCaseOption.yAxis.data = newName;
          this.shopCaseOption.series[0].data = newData;
        } else {
          //无数据
          this.shopCaseOption.yAxis.data = [];
          this.shopCaseOption.series[0].data = [];
        }
        this.shopCaseLoading = false;
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    //获取所有报表数据
    async getAllData() {
      await this.getMealReportFn();
      this.getScanCodeFn();
      this.getShopIncomeFn();
      this.getUseDinnerCaseFn();
      this.getSysInfoFn();
      this.getPostOfficeFn(1);
      this.getPostOfficeFn(0);
      this.getGoodPraiseFn();
      this.getScanCaseFn();
      this.getShopCaseFn();
    },

    //计算柱状图所需的总量数组 arr:需要计算的源数组
    countTotalFn(arr) {
      let result = [];
      for (let i in arr[0].data) {
        let sum = 0;
        for (let j in arr) {
          sum += arr[j].data[i];
        }
        result.push(sum.toFixed(2) * 1);
      }
      return result;
    },

    //生成pdf
    // pageToPfdFn() {
    //   let dom = document.querySelector("#pdfBox");
    //   util.Page2pdf(dom, "测试");
    // },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    if (this.version == 1 || this.version == 3 && this.isAdmin) {
      this.getAllData();
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
    window.removeEventListener("resize", this.setBoxsWHFn);
  }, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.container {
  height: 100%;
  .header {
    height: 53px;
    display: flex;
    align-items: center;
    .question-mealCase {
      color: rgb(255, 131, 61);
      font-size: 20px;
    }
    .download {
      cursor: pointer;
    }
    .title {
      margin-right: 10px;
    }
    .viewType {
      width: 70px;
      margin-right: 10px;
    }
    .datePicker {
      margin-right: 10px;
    }
    .rangePicker {
      margin-right: 10px;
    }
    .monthPicker {
      margin-right: 10px;
    }
  }
  .mainBox {
    background-color: white;
    padding: 24px;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    min-height: 705px;
    min-width: 1350px;
    .left {
      width: 27%;
      min-width: 350px;
      display: flex;
      flex-wrap: wrap;
      .smysBox {
        width: 100%;
        margin: 5px 0;
        position: relative;
      }
      .bcysBox,
      .xmbysBox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    .center {
      width: 50%;
      min-width: 651px;
      display: flex;
      flex-wrap: wrap;
      .mealCaseBox {
        height: 49%;
        width: 100%;
        .title {
          padding: 10px;
          font-weight: bold;
        }
        .item-title {
          padding: 20px 10px 0 10px;
        }
        .item-data {
          color: rgb(255, 131, 61);
          font-size: 30px;
        }
      }
      .system_data {
        height: 50%;
        width: 100%;
        margin-top: 5px;
        display: flex;
        .system_left {
          width: 25%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          .min_width_110 {
            min-width: 110px;
          }
          .item_title {
            padding-left: 10px;
            color: #ff833d;
          }
          .item_data {
            padding-left: 10px;
            color: #ff833d;
          }
        }
        .system_center {
          width: 37%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .system_right_37 {
          width: 37%;
          height: 100%;
          .post_box {
            width: 100%;
            height: 50%;
            position: relative;
            display: flex;
            align-items: center;
          }
          .office_box {
            width: 100%;
            height: 50%;
            position: relative;
            display: flex;
            align-items: center;
          }
        }
        .system_right_74 {
          width: 74%;
          height: 100%;
          .post_box {
            width: 100%;
            height: 50%;
            position: relative;
            display: flex;
            align-items: center;
          }
          .office_box {
            width: 100%;
            height: 50%;
            position: relative;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .right {
      width: 22%;
      min-width: 286px;
      display: flex;
      flex-wrap: wrap;
      .hpfbBox,
      .smqkBox,
      .xmbqkBox {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}
.border {
  border: 1px solid rgb(255, 131, 61);
}
.w100p {
  width: 100%;
}
.text-center {
  text-align: center;
}
.margin_5_0 {
  margin: 5px 0;
}
.container_img {
  background-image: url("../assets/dashboardPic.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
}
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p_relative {
  position: relative;
}
.tips_body {
  display: flex;
  justify-content: space-around;
}
.tips_body > div {
  width: 30%;
}
.theme_font_color {
  color: rgb(255, 131, 61);
}
.pl_20 {
  padding-left: 20px;
}
</style>