<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <a-tabs default-active-key="1" @change="changeTabs">
        <a-tab-pane key="1" tab="用户信息">
          <div class="control_box">
            <Search-bar :inputs="inputs" :selects="selects" :timeRange="timeRange" @search="search" @reset="search" />
            <div class="control_box_bottom">
              <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0"
                style="margin-right: 10px">导出</a-button>
              <a-button type="primary" icon="save" @click="showBatchAvatar" style="margin-right: 10px">批量头像导入</a-button>
              <a-button type="primary" icon="save" @click="showBatch">批量更新</a-button>
            </div>
          </div>
          <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
            :pagination="pagination" @change="handleTableChange">
            <span slot="state" slot-scope="record">{{
              record.status == 1 ? "可用" : "不可用"
            }}</span>
            <span slot="headPic" slot-scope="record">
              <a-avatar :src="record.head_img" />
            </span>
            <span slot="action" slot-scope="record">
              <a-button v-if="record.data_from == 'users'" type="link" @click="reChargeFn(record)" >编辑</a-button>
              <a-divider v-if="record.data_from == 'users'" type="vertical" />
              <a-button v-if="record.data_from == 'users'" type="link" @click="deleteFn(record)" >删除</a-button>
              <a-divider v-if="record.status == 1 && record.data_from != 'users'" type="vertical" />
              <a-button v-if="record.status == 1 && record.data_from != 'users'" @click="passFn(record)">审核通过</a-button>
              <a-divider v-if="record.status == 1 && record.data_from != 'users'" type="vertical" />
              <a-button v-if="record.status == 1 && record.data_from != 'users'" type="danger"
                @click="refuseFn(record)">拒绝加入</a-button>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="申请信息">
          <div class="control_box">
            <Search-bar :inputs="inputs2" :selects="selects2" :timeRange="timeRange2" @search="search2"
              @reset="search2" />
              <div class="control_box_bottom">
                <a-button type="primary" icon="save" @click="applyPassed">一键审核通过</a-button>
              </div>
              
          </div>
          <a-table :columns="column2" :data-source="applyData" :loading="loading" :rowKey="(applyData) => applyData.id"
            :pagination="applyPagination" @change="handleTableChange2">
            <span slot="state" slot-scope="record">{{
                record.status == 0
                ?"已撤销"
                :record.status == 1
                ? "待处理"
                : record.status == 2
                ? "已通过"
                :record.status == 4
                ?"已离开": "未通过"
            }}</span>
            <span slot="headPic" slot-scope="record">
              <a-avatar :src="record.head_img" />
            </span>
            <span slot="action" slot-scope="record">
              <a-button v-if="record.status == 1" @click="passFn(record)">审核通过</a-button>
              <a-divider type="vertical" />
              <a-button v-if="record.status == 1" type="danger" @click="refuseFn(record)">拒绝加入</a-button>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>

      <!-- 内容区域 -->
      <!-- <div class="show_box">
        
      </div>-->
    </div>

    <!-- 批量导入 -->
    <a-modal v-model="batchVisible" title="批量更新" okText="确定" cancelText="取消" @ok="handleBatch">
      <a-form-model ref="batchForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="文件(请选择.xls格式)">
          <a-upload accept=".xls" @change="changeFile" :before-upload="beforeUpload" :file-list="fileList">
            <a-button type="primary">
              <a-icon type="upload" />导入Excel
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="下载">
          <a-button type="primary" @click="handleDonloadTemplate">模板下载</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 批量头像导入 -->
    <a-modal v-model="batchZipVisible" title="批量头像导入" okText="确定" cancelText="取消" @ok="handleBatchZip">
      <a-form-model ref="batchZipForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="文件(请选择.zip格式)">
          <a-upload accept=".zip" @change="changeZipFile" :before-upload="beforeUploadZip" :file-list="zipList">
            <a-button type="primary">
              <a-icon type="upload" />上传压缩包
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="下载">
          <a-button type="primary" @click="handleDonloadZipTemplate">模板下载</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--编辑用户 -->
    <a-modal v-model="userUpdateVisible" title="编辑用户" @ok="handleUpdate" :width="600" okText="提交">
      <a-form-model :model="updateParam" ref="menuForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">

        <a-form-model-item label="昵称">
          <a-input v-model="updateParam.nickname" placeholder="昵称" disabled />
        </a-form-model-item>
        <a-form-model-item label="单位">
          <a-input v-model="updateParam.institution.title" placeholder="单位" disabled />
        </a-form-model-item>
        <a-form-model-item label="名称">
          <a-input v-model="updateParam.realname" placeholder="请输入名称" />
        </a-form-model-item>
        <a-form-model-item label="手机号码">
          <a-input v-model="updateParam.mobile" placeholder="请输入手机号码" />
        </a-form-model-item>
        <a-form-model-item label="工号">
          <a-input v-model="updateParam.work_id" placeholder="请输入工号" />
        </a-form-model-item>
        <a-form-model-item label="职工卡号">
          <a-input v-model="updateParam.car_number" placeholder="请输入职工卡号" />
        </a-form-model-item>
        <a-form-model-item label="科室/部门">
          <a-select v-model="updateParam.office_id">
            <a-select-option v-for="item in officeData" :key="item.id" :value="item.id">{{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="职务">
          <a-select v-model="updateParam.post_id">
            <a-select-option v-for="item in postData" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="instId === 1660" label="可退餐次数">
          <a-input v-model="updateParam.initial_times" placeholder="请可退餐次数" />
        </a-form-model-item>
        <a-form-model-item label="是否禁用">
          <a-select v-model="updateParam.is_valid">
            <a-select-option :value="1">可用</a-select-option>
            <a-select-option :value="0">不可用</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="version == 2" label="闸机管理员">
          <a-select v-model="updateParam.is_face">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="用户头像">
          <a-upload list-type="picture-card" @change="changeUserPic" :customRequest="customRequest"
            :file-list="picFileList" :remove="removePic" @preview="handlePreview">
            <div v-if="picFileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 预览图 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <div style="display:none;">已使用{{num}}/{{total}},当前显示第{{start}}-{{end}}条</div>
  </div>

</template>

<script>
//引入接口api
import {
  huserIndex,
  officesWithout,
  postsWithout,
  huserDownloadTemplate,
  huserUpdateBatch,
  huserDownloadZip,
  huserUploadUserPhotos,
  huserUpdateUserPhotos,
  huserDelete,
  huserDetail,
  huserUpdate,
  huserUpdatePic,
  happlyIndex,
  happlyUpdate,
  happlyIndexAll,
  getInstVersion,
  happlyPassed,
  userLog
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐人员管理",
        },
        {
          key: 1,
          title: "人员信息管理",
        },
      ],

      //传给searchBar公共搜索组件的数据
      timeRange: {
        show: true,
        placeholderStart: "加入时间",
        placeholderEnd: "加入时间",
      },
      inputs: [
        {
          placeholder: "昵称",
          model: "nickname",
          width: 100,
        },
        {
          placeholder: "姓名",
          model: "realname",
          width: 100,
        },
        {
          placeholder: "手机",
          model: "mobile",
          width: 120,
        },
      ],
      selects: [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
        {
          model: "is_valid",
          placeholder: "状态",
          width: 100,
          options: [
            {
              key: "",
              value: "状态",
            },
            {
              key: 0,
              value: "不可用",
            },
            {
              key: 1,
              value: "可用",
            },
          ],
        },
      ],

      //请求参数
      queryParam: {
        page: 1,
        created_start: "",
        created_end: "",
        office_id: "",
        post_id: "",
        is_valid: "",
        nickname: "",
        mobile: "",
        realname: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [], //不分页的数据
      loading: false, //table加载状态
      num:0,
      total:0,
      start:0,
      end:0,
      // 表格的列配置
      columns: [
        {
          title: "头像",
          key: "head_img",
          scopedSlots: { customRender: "headPic" },
        },
        {
          title: "工号",
          key: "work_id",
          dataIndex: "work_id",
        },
        {
          title: "昵称",
          key: "nickname",
          dataIndex: "nickname",
        },
        {
          title: "部门",
          key: "office",
          dataIndex: "office",
        },
        {
          title: "职务",
          key: "post",
          dataIndex: "post",
        },
        {
          title: "姓名",
          key: "realname",
          dataIndex: "realname",
        },
        {
          title: "手机",
          key: "mobile",
          dataIndex: "mobile",
        },
        {
          title: "状态",
          key: "status",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //分页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
		showTotal: total => `共有 ${total} 条数据`
      },

      //批量更新
      batchVisible: false,
      fileList: [],

      //批量头像导入
      batchZipVisible: false,
      zipList: [],

      officeData: [],
      postData: [],
      userUpdateVisible: false,
      updateParam: {
        work_id: "",
        nickname: "", //仅显示
        institution: {
          title: "", //所在单位，仅显示
        },
        realname: "",
        mobile: "",
        car_number: "",
        post_id: "",
        office_id: "",
        user_head: "",
        is_valid: "",
        is_face: "",
        initial_times:''
      },
      picFileList: [],
      previewVisible: false,
      previewImage: "",
      updateId: "",

      timeRange2: {
        show: true,
        placeholderStart: "创建时间",
        placeholderEnd: "创建时间",
      },
      inputs2: [
        {
          placeholder: "昵称",
          model: "nickname",
          width: 100,
        },
        {
          placeholder: "姓名",
          model: "realname",
          width: 100,
        },
        {
          placeholder: "手机",
          model: "mobile",
          width: 120,
        },
      ],
      selects2: [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
        {
          model: "status",
          placeholder: "状态",
          width: 100,
          options: [
            {
              key: "",
              value: "状态",
            },
            {
              key: 1,
              value: "待处理",
            },
            {
              key: 2,
              value: "已通过",
            },
            {
              key: 3,
              value: "未通过",
            },
          ],
        },
      ],
      column2: [
        {
          title: "头像",
          key: "head_img",
          scopedSlots: { customRender: "headPic" },
        },
        {
          title: "姓名",
          key: "realname",
          dataIndex: "realname",
        },
        {
          title: "部门",
          key: "office",
          dataIndex: "office",
        },
        {
          title: "职务",
          key: "post",
          dataIndex: "post",
        },
        {
          title: "状态",
          key: "status",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "手机",
          key: "mobile",
          dataIndex: "mobile",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      applyData: [],
      applyPagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },
      queryParam2: {
        page: 1,
        created_start: "",
        created_end: "",
        office_id: "",
        post_id: "",
        nickname: "",
        mobile: "",
        realname: "",
        status: "",
      },
      version: "",
      instId: JSON.parse(localStorage.getItem('inst')).id
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        created_start: val.timeRange.startTime,
        created_end: val.timeRange.endTime,
        nickname: val.input.nickname ? val.input.nickname : "",
        mobile: val.input.mobile ? val.input.mobile : "",
        realname: val.input.realname ? val.input.realname : "",
        // status:val.select.status === "" ? "" : val.select.status,
        is_valid: val.select.is_valid === "" ? "" : val.select.is_valid,
        office_id: val.select.office_id ? val.select.office_id : "",
        post_id: val.select.post_id ? val.select.post_id : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      huserIndex(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;

        this.num = resultData.total;//当前使用数
        //显示开始跟结束条数
        //this.start =  (resultData.current_page*1 - 1)* resultData.per_page  + 1;
        this.start =  resultData.from;
        this.end = (resultData.data.length * 1 - 1) + this.start;

        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = resultData.data;
        this.loading = false;
      });

      //请求不分页的数据
      this.queryParam.no_paging = 1;
      huserIndex(this.queryParam).then((res) => {
        this.dataForExport = res.data;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //导出
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },

    //数据写入excel
    export2Excel () {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = ["id","姓名", "工号", "手机", "科室/部门", "职务"]; // 导出的表头名信息
        const filterVal = ["id","realname", "work_id", "mobile", "office", "post"]; // 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "会员列表"); // 导出的表格名称，根据需要自己命名
        this.userLogFn()
      });
    },

    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //打开批量导入模态框
    showBatch () {
      this.fileList = [];
      this.batchVisible = true;
    },

    //模板下载
    handleDonloadTemplate () {
      huserDownloadTemplate().then((res) => {
        util.acceptFileStream(res.data, "批量更新用户模板.xls");
      });
    },

    beforeUpload (file) {
      this.fileList = [...this.fileList, file];
      return false;
    },

    //上传文件
    changeFile (info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.fileList = fileList;
    },

    //批量导入
    handleBatch () {
      if (this.fileList.length == 0) {
        this.$message.info("请先上传文件");
        return;
      }
      const formData = new FormData();
      formData.append("user_file", this.fileList[0].originFileObj);
      huserUpdateBatch(formData)
        .then((res) => {
          this.batchVisible = false;
          let data = res.data;
          this.$notification.open({
            message: `成功导入条数${data.success_count}`,
            description: data.error_msg,
            duration: 0,
          });
        })
        .catch((err) => {
          this.batchVisible = false;
          this.$notification.open({
            message: "导入失败",
            description: "请检查文件内容的格式",
            duration: 0,
          });
        });
    },

    //打开批量头像导入模态框
    showBatchAvatar () {
      this.zipList = [];
      this.batchZipVisible = true;
    },

    //批量头像导入模板下载
    handleDonloadZipTemplate () {
      huserDownloadZip().then((res) => {
        let link = document.createElement("a");
        link.href = res.data.url;
        link.download = "批量头像导入模板.zip";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    beforeUploadZip (file) {
      this.zipList = [...this.zipList, file];
      return false;
    },

    //上传zip文件
    changeZipFile (info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.zipList = fileList;
    },

    //批量头像导入
    handleBatchZip () {
      if (this.zipList.length == 0) {
        this.$message.info("请先上传文件");
        return;
      }
      const formData = new FormData();
      formData.append("zip", this.zipList[0].originFileObj);
      huserUploadUserPhotos(formData)
        .then((res) => {
          var _this = this;
          _this.batchZipVisible = false;
          if (res.data.data.length == 0) {
            _this.$notification.open({
              message: "上传失败",
              description: "无匹配的手机号，请检查文件手机号码",
              duration: 0,
            });
            return;
          }
          let updateArr = [];
          for (let key in res.data.data) {
            updateArr.push(res.data.data[key]);
          }
          _this.$confirm({
            title: "是否执行上传修改？",
            content: res.data.msg,
            okText: "确定",
            cancelText: "取消",
            onOk () {
              huserUpdateUserPhotos({ data: updateArr }).then((res) => {
                let info = res.data.msg.join(",");
                _this.$notification.open({
                  message: "导入成功",
                  description: info.length == 0 ? "全部导入" : info,
                  duration: 0,
                });
              });
            },
          });
        })
        .catch((err) => {
          this.batchZipVisible = false;
          this.$notification.open({
            message: "导入失败",
            description: "请检查上传的文件格式",
            duration: 0,
          });
        });
    },

    //获取部门列表
    getOffices () {
      officesWithout({ no_paging: 1 }).then((res) => {
        this.officeData = res.data;
        for (let i = 0; i < res.data.length; i++) {
          this.selects[0].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
          this.selects2[0].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取职务列表
    getPosts () {
      postsWithout({ no_paging: 1 }).then((res) => {
        this.postData = res.data;
        for (let i = 0; i < res.data.length; i++) {
          this.selects[1].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
          this.selects2[1].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    deleteFn (record) {
      let id = record.id,
        name = record.realname;
      this.$confirm({
        content: `是否删除${name}这个用户`,
        onOk: () => {
          huserDelete(id)
            .then((res) => { })
            .catch((err) => {
              if (err.status == 204) {
                this.$message.success("删除成功");
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              } else {
                this.$message.error(err.data.msg);
              }
            });
        },
      });
    },

    //上传头像
    changeUserPic (info) {
      let picFileList = [...info.fileList];
      picFileList = picFileList.slice(-1);
      // if(picFileList.length > 0){
      //   if(picFileList[0].size > (1024*300)){
      //     this.$message.info('请上传300kb以下的图片')
      //     return
      //   }
      // }
      this.picFileList = picFileList;
      info.file.status = "done";
    },

    //上传组件自定义上传方式
    customRequest (data) {
      const formData = new FormData();
      formData.append("user_head", data.file);
      huserUpdatePic(formData)
        .then((res) => { })
        .catch((err) => {
          if (err.status == 201) {
            this.updateParam.user_head = err.data.path;
          }
        });
    },

    //移除图片文件
    removePic (file) {
      this.updateParam.user_head = "";
      return true;
    },

    //图片预览
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    reChargeFn (record) {
      let id = record.id;
      this.updateId = record.id;
      huserDetail(id).then((res) => {
        let detail = res.data;
        this.updateParam = {
          work_id: detail.work_id,
          nickname: detail.nickname, //仅显示
          institution: {
            title: detail.institution.title, //所在单位，仅显示
          },
          realname: detail.realname,
          mobile: detail.mobile,
          car_number: detail.car_number,
          post_id: detail.post_id,
          office_id: detail.office_id,
          user_head: detail.user_head,
          is_valid: detail.is_valid,
          is_face: this.version == 2 ? detail.is_face : "",
          initial_times: detail.initial_times
        };
        this.picFileList[0] = {
          uid: "1",
          name: "image.png",
          status: "done",
          url: detail.user_head,
        };
        this.userUpdateVisible = true;
      });
    },

    handleUpdate: util.DebounceBy(function () {
      huserUpdate(this.updateId, this.updateParam)
        .then((res) => {
          this.$message.success("修改成功");
          this.userUpdateVisible = false;
          // this.queryParam.page = 1;
          // this.pagination.current = 1;
          this.getTableData();
        })
        .catch((err) => {
          if (err.status == 400) {
            this.$message.error("修改失败");
          }
          if (err.status == 422) {
            this.$message.error("验证错误");
          }
        });
    }, 3000),
    getFirstTable () {
      this.loading = true;
      happlyIndexAll({ page: 1 }).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.num = resultData.total;//当前使用数
        //显示开始跟结束条数
        //this.start =  (resultData.current_page*1 - 1)* resultData.per_page  + 1;
        this.start =  resultData.from;
        this.end = (resultData.data.length * 1 - 1) + this.start;
        this.pagination = pagination;
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = resultData.data;
        this.loading = false;
      });

      //请求不分页的数据
      this.queryParam.no_paging = 1;
      huserIndex(this.queryParam).then((res) => {
        this.dataForExport = res.data;
      });
    },
    search2 (val) {
      this.queryParam2 = {
        page: 1,
        created_start: val.timeRange.startTime,
        created_end: val.timeRange.endTime,
        nickname: val.input.nickname ? val.input.nickname : "",
        mobile: val.input.mobile ? val.input.mobile : "",
        realname: val.input.realname ? val.input.realname : "",
        status: val.select.status === "" ? "" : val.select.status,
        office_id: val.select.office_id ? val.select.office_id : "",
        post_id: val.select.post_id ? val.select.post_id : "",
      };
      this.applyPagination.current = 1;
      this.getApplyData();
    },
    getApplyData () {
      this.loading = true;
      happlyIndex(this.queryParam2).then((res) => {
        this.loading = true;
        let resultData = res.data;
        const pagination = { ...this.applyPagination };
        pagination.total = resultData.total;
        this.applyPagination = pagination;
        if (resultData.data.length == 0) {
          this.loading = false;
          this.applyData = [];
          return;
        }
        this.applyData = resultData.data;
        this.loading = false;
      });
    },
    handleTableChange2 (pagination) {
      this.applyPagination.current = pagination.current;
      this.applyPagination.pageSize = pagination.pageSize;
      this.queryParam2.page = pagination.current;
      this.getApplyData();
    },
    passFn (re) {
      this.$confirm({
        content: `是否通过${re.realname}的加入申请？`,
        onOk: () => {
          happlyUpdate(re.id, { status: 2 })
            .then((res) => {
              this.$message.success("操作成功");
              this.getApplyData();
              this.getFirstTable();
            })
            .catch((err) => {
              this.$message.error(err.data.msg);
            });
        },
      });
    },
    refuseFn (re) {
      this.$confirm({
        content: `是否拒绝${re.realname}的加入申请？`,
        onOk: () => {
          happlyUpdate(re.id, { status: 3 })
            .then((res) => {
              this.$message.success("操作成功");
              this.getApplyData();
              this.getFirstTable();
            })
            .catch((err) => {
              this.$message.error(err.data.msg);
            });
        },
      });
    },
    changeTabs (val) {
      if (val == 1) {
        this.getTableData();
      } else {
        this.getApplyData();
      }
    },
    getInstVersionFn () {
      getInstVersion().then((res) => {
        this.version = res.data.version;
        if(res.data.num == 0){
          this.total = '无限制';
        } else {
          this.total = res.data.num;
        }
      });
    },

    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            office:this.queryParam.office_id ? this.officeData.find(item => item.id == this.queryParam.office_id).title : '',
            post:this.queryParam.post_id ? this.postData.find(item => item.id == this.queryParam.post_id).title : '',
            is_valid:this.queryParam.is_valid === '' || this.queryParam.is_valid === undefined ? '' : this.selects[2].options.find(item => item.key === this.queryParam.is_valid).value,
            created_start:this.queryParam.created_start,
            created_end:this.queryParam.created_end,
            nickname:this.queryParam.nickname,
            realname:this.queryParam.realname,
            mobile:this.queryParam.mobile,
            total:this.dataForExport.length
          };
      userLog(moduleId,param).then(res => {}).catch(err => {
        console.log('userLog接口请求错误：')
        console.log(err)
      })
    },

    //一键审核通过
    applyPassed() {
      this.$confirm({
        content: `操作成功后,所有待审核加入申请都会通过,确认继续?`,
        onOk: () => {
          happlyPassed().then((res) => {
            console.log(res);
            this.$message.success("操作成功,系统处理中");
            // this.getApplyData();
            setTimeout(() => {
              location.href = '/PersonInfoManage';
            }, 3000);
            
          });      
        },
      });
    },

    
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // this.getTableData();
    this.getFirstTable();
    this.getOffices();
    this.getPosts();
    this.getInstVersionFn();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>